import { FC, useEffect } from 'react';
import 'firebaseui/dist/firebaseui.css';
import { useAuth } from '../../hooks/useAuth';
import { useBatchId } from '../../hooks/useBatchId';
import {
	APPLICANT_HOME,
	FUNCTIONARY_HOME,
	SELECT_MODULE,
} from '../../shared/Routes';
import { UserRole } from '../../shared/VolunteerApplicationServiceTypes';

const SignInPage: FC = () => {
	const { navigateWithBatchId } = useBatchId();
	const { initiated, isLoggedIn, role } = useAuth();

	useEffect(() => {
		if (initiated) {
			if (isLoggedIn && isLoggedIn()) {
				switch (role) {
					case UserRole.APPLICANT:
						navigateWithBatchId(APPLICANT_HOME);
						return;
					case UserRole.ADMIN:
						navigateWithBatchId(SELECT_MODULE);
						return;
					case UserRole.BATCH_ADMIN:
						navigateWithBatchId(FUNCTIONARY_HOME);
						return;
					case UserRole.FUNCTIONARY:
						navigateWithBatchId(FUNCTIONARY_HOME);
						return;
					case UserRole.APPROVER:
						navigateWithBatchId(SELECT_MODULE);
						return;
					case UserRole.CARE_TEAM:
						navigateWithBatchId(FUNCTIONARY_HOME);
						return;
					case UserRole.ZC:
						navigateWithBatchId(SELECT_MODULE);
						return;
				}
			}
		} else {
			const oneAuthElement = document.querySelector(
				'hfn-oneauth'
			) as HTMLElement & { triggerAuth?: () => void };

			if (oneAuthElement?.triggerAuth) {
				oneAuthElement.triggerAuth();
			}
		}
	}, [isLoggedIn, navigateWithBatchId, initiated, role]);

	return <></>;
};

export default SignInPage;
