import { CategoryStep } from './CategoryStep';
import { useNewApplication } from './useNewApplication';
import useCategoryFormHandling from './useCategoryFormHandling';
import { SupportUIConfig } from '../../../shared/CategoryStepUIConfig';

const UiConfig = SupportUIConfig;

export const SupportStep = () => {
	const { supportCategoryAnswers, setSupportCategoryAnswers } =
		useNewApplication();

	const { form, updateFieldValue } = useCategoryFormHandling(
		supportCategoryAnswers,
		setSupportCategoryAnswers
	);

	return (
		<CategoryStep
			uiConfig={UiConfig}
			form={form}
			updateFieldValue={updateFieldValue}
		/>
	);
};
