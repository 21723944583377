import { useEffect, useState } from 'react';
import { Button, Form, Input, Radio, Select, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { MultipleChoiceQuestionUIConfig } from '../../../shared/MultipleChoiceQuestionUIConfig';
import {
	AnswerOptionsField,
	VALID_STRING_INPUT_REGEX,
} from '../../../shared/Constants';
import { RuleObject } from 'antd/es/form';
import { CheckCircleFilled } from '@ant-design/icons';

const { Text } = Typography;

export interface MultipleChoiceQuestionAnswer {
	selectedOption: number | undefined;
	comments?: string;
}

export interface MultipleChoiceQuestionProps {
	uiConfig: MultipleChoiceQuestionUIConfig;
	value?: MultipleChoiceQuestionAnswer;
	updateFieldValue?: (questionId: number, value: number) => void;
}

export const MultipleChoiceQuestion = ({
	uiConfig: {
		questionNo,
		questionId,
		questionText,
		answerOptionsField,
		answerOptions,
		required,
		displayAnswerOptionsVertically = false,
		hideComments = false,
		commentsText,
		commentsRequired = false,
		disabled = false,
	},
	value,
	updateFieldValue,
}: MultipleChoiceQuestionProps) => {
	const { t, i18n } = useTranslation();
	const [isMobile, setIsMobile] = useState(false);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 640);
		};

		handleResize();
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	const radios =
		answerOptions &&
		answerOptions.map((ao) => (
			<Radio value={ao.optionId} key={ao.optionId}>
				{t(ao.optionText)}
			</Radio>
		));

	const selectOptions =
		answerOptions &&
		answerOptions.map((ao) => ({
			value: ao.optionId,
			label: t(ao.optionText),
		}));

	const buttonOptions = (
		<div
			className={`flex flex-wrap gap-4 w-full h-fit ${
				displayAnswerOptionsVertically || isMobile ? 'flex-col' : 'inline-flex'
			}`}
		>
			{answerOptions &&
				answerOptions.map((ao) => (
					<Button
						key={ao.optionId}
						type="default"
						icon={
							value?.selectedOption === ao.optionId ? (
								<span className="text-primary-blue text-[22px]">
									<CheckCircleFilled />
								</span>
							) : null
						}
						className={`flex-1 min-h-[2.25rem] border-2 rounded-lg font-sans break-words whitespace-normal py-2 ${
							disabled
								? 'border-gray-300'
								: 'border-gray-300 hover:border-blue-500'
						} ${
							value?.selectedOption === ao.optionId
								? 'font-bold border-primary-blue hover:!border-primary-blue !text-primary-blue'
								: 'text-black font-semibold border-gray-200 hover:!border-primary-blue hover:!text-primary-blue'
						} ${i18n.language === 'hi' ? 'tracking-widest' : ''}`}
						onClick={() =>
							updateFieldValue && updateFieldValue(questionId, ao.optionId)
						}
						disabled={disabled}
					>
						{t(ao.optionText)}
					</Button>
				))}
		</div>
	);

	const checkForEmptySpaces = (_: RuleObject, inputValue: string) => {
		if (inputValue && inputValue.trim() === '') {
			return Promise.reject(new Error('Empty spaces are not allowed.'));
		}
		return Promise.resolve();
	};

	return (
		<div className="flex flex-col w-full">
			<div className="flex flex-row items-start">
				<Text className="mr-[8px] min-w-[24px] font-sans font-bold">{`${questionNo})`}</Text>
				<Text
					className={`mb-4 font-sans font-bold ${
						i18n.language === 'hi' ? 'tracking-widest' : ''
					}`}
				>
					{t(questionText)}
				</Text>
			</div>
			{answerOptions && (
				<Form.Item
					name={[questionId, 'selectedOption']}
					rules={[
						{
							required,
							message: <span>{t('answerIsRequired')}</span>,
						},
					]}
					className="mb-2"
				>
					{answerOptionsField === AnswerOptionsField.RADIO && (
						<Radio.Group
							className={`flex ${
								displayAnswerOptionsVertically ? 'flex-col' : 'inline-flex'
							}`}
							disabled={disabled}
							onChange={(e) =>
								updateFieldValue &&
								updateFieldValue(questionId, e.target.value as number)
							}
						>
							{radios}
						</Radio.Group>
					)}
					{answerOptionsField === AnswerOptionsField.SELECT && (
						<Select options={selectOptions} disabled={disabled} />
					)}
					{answerOptionsField === AnswerOptionsField.BUTTONS && (
						<div className="flex gap-2">{buttonOptions}</div>
					)}
				</Form.Item>
			)}
			{!hideComments && (
				<>
					<Text className="font-sans font-bold">
						{commentsText ? t(commentsText) : t('comments')}
						{commentsRequired && (
							<span className="text-red-500 font-bold"> *</span>
						)}
					</Text>
					<Form.Item
						className="font-sans font-bold"
						name={[questionId, 'comments']}
						rules={[
							{
								required: commentsRequired ? true : false,
								message: <span>{t('required')}</span>,
							},
							{
								pattern: VALID_STRING_INPUT_REGEX,
								validator: checkForEmptySpaces,
								message: <span>{t('onlyAllowedSpecialCharacters')}</span>,
							},
						]}
					>
						<Input.TextArea
							className="font-sans font-bold"
							showCount
							rows={4}
							disabled={disabled}
							placeholder={
								commentsRequired ? `${t('pleaseEnterYourComments')}` : ''
							}
						/>
					</Form.Item>
				</>
			)}
		</div>
	);
};
