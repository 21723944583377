import { Form, FormInstance, Input, DatePicker, Collapse } from 'antd';
import dayjs, { extend } from 'dayjs';
import dayjsUTC from 'dayjs/plugin/utc';
import { useTranslation } from 'react-i18next';
import {
	NO_WHITESPACE_REGEX,
	VALID_ADDRESS_REGEX,
	VALID_CITY_REGEX,
	VALID_PINCODE_REGEX,
	VALID_STRING_INPUT_REGEX,
} from '../shared/Constants';
import { ApplicantInfo } from '../shared/VolunteerApplicationServiceTypes';
import { MeProfile } from '../shared/MySrcm';
import moment from 'moment';
import BlueChevron from '../assets/images/blue-chevron-small.png';
import Icon from './ui-components/Icon';
import { StepHeader } from './StepHeader';
import { UpdateHFNProfileStatement } from './UpdateHFNProfileStatement';
import { useEffect, useState } from 'react';
import { ApplicantAdditionalDetailsType } from './AdditionalDetailsType';

extend(dayjsUTC);
const { Panel } = Collapse;

interface ApplicantAdditionalDetailsFormProps {
	form: FormInstance;
	applicant?: ApplicantInfo;
	mySrcmProfile?: MeProfile | null;
}

export const ApplicantAdditionalDetailsForm: React.FC<
	ApplicantAdditionalDetailsFormProps
> = ({
	form,
	applicant,
	mySrcmProfile,
}: ApplicantAdditionalDetailsFormProps) => {
	const { t } = useTranslation();
	const [initialValues, setInitialValues] =
		useState<ApplicantAdditionalDetailsType>({
			center: mySrcmProfile?.srcm_group?.name,
			doj: moment(mySrcmProfile?.date_of_joining, 'YYYY-MM-DD'),
			dateOfBirth: moment(mySrcmProfile?.date_of_birth, 'YYYY-MM-DD'),
			addressLine1: mySrcmProfile?.street,
			addressLine2: mySrcmProfile?.street2,
			addressLine3: mySrcmProfile?.street3,
			city: mySrcmProfile?.city,
			pincode: mySrcmProfile?.postal_code,
			profession: mySrcmProfile?.profession,
		});

	useEffect(() => {
		setInitialValues({
			center: mySrcmProfile?.srcm_group?.name,
			doj: moment(mySrcmProfile?.date_of_joining, 'YYYY-MM-DD'),
			dateOfBirth: moment(mySrcmProfile?.date_of_birth, 'YYYY-MM-DD'),
			addressLine1: mySrcmProfile?.street,
			addressLine2: mySrcmProfile?.street2,
			addressLine3: mySrcmProfile?.street3,
			city: mySrcmProfile?.city,
			pincode: mySrcmProfile?.postal_code,
			profession: mySrcmProfile?.profession,
		});
	}, [
		mySrcmProfile?.city,
		mySrcmProfile?.date_of_birth,
		mySrcmProfile?.date_of_joining,
		mySrcmProfile?.postal_code,
		mySrcmProfile?.profession,
		mySrcmProfile?.srcm_group,
		mySrcmProfile?.street,
		mySrcmProfile?.street2,
		mySrcmProfile?.street3,
	]);

	return (
		<div className="flex flex-col">
			<Form
				name="basic"
				layout="vertical"
				size="middle"
				labelCol={{ span: 16 }}
				style={{ maxWidth: 600 }}
				initialValues={initialValues}
				autoComplete="off"
				form={form}
			>
				<>
					<StepHeader
						heading={t('additionalDetails')}
						description={
							applicant
								? t('collectFollowingDetailsOfCandidate')
								: t('provideTheFollowingDetailsToHelp')
						}
					/>
					<Collapse
						defaultActiveKey={['1', '2', '3']}
						ghost
						expandIconPosition="end"
						expandIcon={({ isActive }) => (
							<span
								className={`transition-transform ${
									isActive ? 'rotate-0' : 'rotate-90'
								}`}
							>
								<Icon src={BlueChevron} width={18} height={18} />
							</span>
						)}
					>
						<Panel
							header={
								<span className="font-sans font-bold text-primary-blue">
									{t('candidateDetails')}
								</span>
							}
							key="1"
							style={{ paddingLeft: 0 }}
						>
							<div style={{ paddingLeft: 0 }}>
								<Form.Item
									label={
										<span className="font-sans font-normal text-xs text-secondary-black">
											{t('center')}
										</span>
										// applicant
										// 	? t('selectCenterWhereCandidateIsResidingAt')
										// 	: t('selectApplicationSubmissionCenter')
									}
									labelCol={{ className: '!pb-0' }}
									name={'center'}
									rules={[
										{
											required: true,
											message: <>{t('required')}</>,
										},
									]}
									className="mb-4"
								>
									<Input
										className="h-11 text-sm font-normal font-sans text-light-gray rounded-lg"
										value={mySrcmProfile?.srcm_group?.name}
										disabled
									/>
								</Form.Item>
								<Form.Item
									label={
										<span className="font-sans font-normal text-xs text-secondary-black">
											{t('monthOfFirstSitting')}
										</span>
									}
									labelCol={{ className: '!pb-0' }}
									name="doj"
									hasFeedback
									rules={[
										{
											required: true,
											message: <span>{t('required')}</span>,
										},
									]}
									className="mb-4"
								>
									<DatePicker
										className="h-11 text-sm font-normal font-sans text-light-gray rounded-lg w-full"
										format={'YYYY - MMM'}
										picker="month"
										disabled
									/>
								</Form.Item>
								<Form.Item
									label={
										<span className="font-sans font-normal text-xs text-secondary-black">
											{t('dateOfBirth')}
										</span>
									}
									labelCol={{ className: '!pb-0' }}
									name="dateOfBirth"
									hasFeedback
									rules={[
										{
											required: true,
											message: <span>{t('required')}</span>,
										},
									]}
									className="mb-4"
								>
									<DatePicker
										defaultPickerValue={dayjs().subtract(15, 'years')} // Use dayjs instead of moment
										disabledDate={
											(current) =>
												dayjs().subtract(15, 'years').isBefore(current) // Use dayjs for date comparison
										}
										className="h-11 text-sm font-normal font-sans text-light-gray rounded-lg w-full"
										disabled
									/>
								</Form.Item>
							</div>
						</Panel>
						<Panel
							header={
								<span className="font-sans font-bold text-primary-blue">
									{t('educationAndJobDetails')}
								</span>
							}
							key="2"
						>
							<Form.Item
								label={
									<span className="font-sans font-normal text-xs text-secondary-black">
										{t('educationQualification')}
									</span>
								}
								labelCol={{ className: '!pb-0' }}
								name="educationQualification"
								rules={[
									{
										required: true,
										message: <span>{t('required')}</span>,
									},
									{
										pattern: VALID_STRING_INPUT_REGEX,
										message: <span>{t('onlyAllowedSpecialCharacters')}</span>,
									},
								]}
								className="mb-4 font-sans"
							>
								<Input
									className="h-11 text-sm font-normal font-sans text-light-gray rounded-lg"
									maxLength={100}
								/>
							</Form.Item>
							<Form.Item
								label={
									<span className="font-sans font-normal text-xs text-secondary-black">
										{t('profession')}
									</span>
								}
								labelCol={{ className: '!pb-0' }}
								name="profession"
								rules={[
									{
										required: true,
										message: <span>{t('required')}</span>,
									},
									{
										pattern: VALID_STRING_INPUT_REGEX,
										message: <span>{t('onlyAllowedSpecialCharacters')}</span>,
									},
								]}
								className="mb-4 font-sans"
							>
								<Input
									className="h-11 text-sm font-normal font-sans text-light-gray rounded-lg"
									maxLength={100}
								/>
							</Form.Item>
							<Form.Item
								label={
									<span className="font-sans font-normal text-xs text-secondary-black">
										{t('professionDetails')}
									</span>
								}
								labelCol={{ className: '!pb-0' }}
								name="professionDetails"
								rules={[
									{
										required: true,
										message: <span>{t('required')}</span>,
									},
									{
										pattern: NO_WHITESPACE_REGEX,
										message: <span>{t('spacesNotAllowed')}</span>,
									},
								]}
								className="mb-4 font-sans"
							>
								<Input.TextArea
									className="text-sm font-normal font-sans text-light-gray rounded-lg"
									showCount
									rows={4}
								/>
							</Form.Item>
						</Panel>
						<Panel
							header={
								<span className="font-sans font-bold text-primary-blue">
									{t('addressDetails')}
								</span>
							}
							key="3"
						>
							<Form.Item
								label={
									<span className="font-sans font-normal text-xs text-secondary-black">
										{t('addressLine1')}
									</span>
								}
								labelCol={{ className: '!pb-0' }}
								name="addressLine1"
								rules={[
									{
										required: true,
										message: <span>{t('required')}</span>,
									},
									{
										pattern: VALID_ADDRESS_REGEX,
										message: (
											<span>{t('onlyAllowedSpecialCharactersForAddress')}</span>
										),
									},
								]}
								className="mb-4"
							>
								<Input
									className="h-11 text-sm font-normal font-sans text-light-gray rounded-lg"
									maxLength={100}
									disabled
								/>
							</Form.Item>
							<Form.Item
								label={
									<span className="font-sans font-normal text-xs text-secondary-black">
										{t('addressLine2')}
									</span>
								}
								labelCol={{ className: '!pb-0' }}
								name="addressLine2"
								rules={[
									{
										pattern: VALID_ADDRESS_REGEX,
										message: (
											<span>{t('onlyAllowedSpecialCharactersForAddress')}</span>
										),
									},
								]}
								className="mb-4"
							>
								<Input
									className="h-11 text-sm font-normal font-sans text-light-gray rounded-lg"
									maxLength={100}
									disabled
								/>
							</Form.Item>
							<Form.Item
								label={
									<span className="font-sans font-normal text-xs text-secondary-black">
										{t('addressLine3')}
									</span>
								}
								labelCol={{ className: '!pb-0' }}
								name="addressLine3"
								rules={[
									{
										pattern: VALID_ADDRESS_REGEX,
										message: (
											<span>{t('onlyAllowedSpecialCharactersForAddress')}</span>
										),
									},
								]}
								className="mb-4"
							>
								<Input
									className="h-11 text-sm font-normal font-sans text-light-gray rounded-lg"
									maxLength={100}
									disabled
								/>
							</Form.Item>
							<Form.Item
								label={
									<span className="font-sans font-normal text-xs text-secondary-black">
										{t('city')}
									</span>
								}
								labelCol={{ className: '!pb-0' }}
								name="city"
								rules={[
									{
										required: true,
										message: <span>{t('required')}</span>,
									},
									{
										pattern: VALID_CITY_REGEX,
										message: <span>{t('validCity')}</span>,
									},
								]}
								className="mb-4"
							>
								<Input
									className="h-11 text-sm font-normal font-sans text-light-gray rounded-lg"
									disabled
								/>
							</Form.Item>
							<Form.Item
								label={
									<span className="font-sans font-normal text-xs text-secondary-black">
										{t('pincode')}
									</span>
								}
								labelCol={{ className: '!pb-0' }}
								name="pincode"
								rules={[
									{
										required: true,
										message: <span>{t('required')}</span>,
									},
									{
										pattern: VALID_PINCODE_REGEX,
										message: <span>{t('validPincode')}</span>,
									},
								]}
								className="mb-4"
							>
								<Input
									className="h-11 text-sm font-normal font-sans text-light-gray rounded-lg"
									maxLength={7}
									disabled
								/>
							</Form.Item>
						</Panel>
					</Collapse>
				</>
			</Form>
			<UpdateHFNProfileStatement />
		</div>
	);
};
