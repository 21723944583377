import cloneDeep from 'lodash/cloneDeep';
import { CategoryStep } from './CategoryStep';
import { useNewApplication } from './useNewApplication';
import useCategoryFormHandling from './useCategoryFormHandling';
import { BasicEligibilityUIConfig } from '../../../shared/CategoryStepUIConfig';
import { useAuth } from '../../../hooks/useAuth';

const UiConfig = BasicEligibilityUIConfig;

export const BasicEligibilityStep = () => {
	const { mySrcmProfile } = useAuth();

	const clonedUiConfig = cloneDeep(UiConfig);

	if (mySrcmProfile?.gender !== 'F' && clonedUiConfig.questions.length === 12) {
		clonedUiConfig.questions.pop();
	}

	const {
		basicEligibilityCategoryAnswers,
		setBasicEligibilityCategoryAnswers,
	} = useNewApplication();

	const { form, updateFieldValue } = useCategoryFormHandling(
		basicEligibilityCategoryAnswers,
		setBasicEligibilityCategoryAnswers
	);

	return (
		<CategoryStep
			uiConfig={clonedUiConfig}
			form={form}
			updateFieldValue={updateFieldValue}
		/>
	);
};
