import React from 'react';

interface IconProps {
	src: string;
	width?: number | string;
	height?: number | string;
	alt?: string;
	className?: string;
}

const Icon: React.FC<IconProps> = ({
	src,
	width = 'w-6',
	height = 'h-6',
	alt = 'icon',
	className = '',
}) => {
	return (
		<img
			src={src}
			alt={alt}
			className={`${width} ${height} inline-block ${className}`}
		/>
	);
};

export default Icon;
