import { Button } from 'antd';
import LanguageDropdown from './LanguageDropdown';
import { PoweroffOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

interface AppHeaderProps {
	logout?: () => void | Promise<void>;
}

export const AppHeader = ({ logout }: AppHeaderProps) => {
	const { t } = useTranslation();

	const handleLogoutClick = useCallback(() => {
		if (logout) {
			void logout();
		}
	}, [logout]);

	return (
		<div className="flex justify-between items-center p-[8px]">
			<div>
				<LanguageDropdown className="w-24" />
			</div>
			<div>
				<Button
					type="link"
					shape="round"
					icon={<PoweroffOutlined />}
					onClick={handleLogoutClick}
					size="small"
				>
					{t('logout')}
				</Button>
			</div>
		</div>
	);
};
