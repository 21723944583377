import React, { useCallback, useState } from 'react';
import { Progress, Select } from 'antd';
import { ReactComponent as DropDownIcon } from '../../assets/images/chevron-down.svg';
const { Option } = Select;

interface Step {
	title: string;
	description?: string | null;
	progress?: number;
}

interface CircularStepsWithDropdownProps {
	steps?: Array<Step>; // Define the type for the `steps` prop
	activeStep: number; // Current active step, managed externally
	onNext: () => void; // Handler for the "Next" button
	onPrevious: () => void; // Handler for the "Previous" button
	onStepChange: (step: number) => void; // Handler for dropdown step change
	disableDropdown?: boolean; // New prop to disable dropdown
}

const CircularStepsWithDropdown: React.FC<CircularStepsWithDropdownProps> = ({
	steps,
	activeStep,
	onStepChange,
	disableDropdown = false,
}) => {
	const [dropdownOpen, setDropdownOpen] = useState(false); // Controls dropdown visibility

	const handleDropdownOpenChange = useCallback(
		(open: boolean) => {
			if (!disableDropdown) {
				setDropdownOpen(open); // Only open dropdown if not disabled
			}
		},
		[disableDropdown]
	);

	const handleStepSelect = useCallback(
		(step: number) => {
			if (!disableDropdown) {
				onStepChange(step); // Notify the parent component about the step change
				setDropdownOpen(false); // Close the dropdown
			}
		},
		[disableDropdown, onStepChange]
	);

	const calculateDynamicStrokeColor = useCallback(() => {
		if (!steps || activeStep >= steps.length) {
			return {
				'0%': '#d9d9d9',
				'100%': '#d9d9d9',
			}; // Default colors if steps or activeStep is invalid
		}

		const stepCount = steps.length;
		const startPercent = (activeStep / stepCount) * 100; // Start percentage
		const endPercent = ((activeStep + 1) / stepCount) * 100; // End percentage

		return {
			[`${startPercent.toFixed(1)}%`]: '#003399',
			[`${endPercent.toFixed(1)}%`]: '#003399',
		};
	}, [steps, activeStep]);

	return (
		<div className="w-full">
			<Select
				value={activeStep}
				onChange={onStepChange}
				className="w-full h-auto"
				open={dropdownOpen}
				suffixIcon={null}
				onDropdownVisibleChange={handleDropdownOpenChange}
				disabled={disableDropdown} // Disable the dropdown entirely
				dropdownRender={() => (
					<div className="p-2 bg-white h-auto max-h-[50vh] overflow-auto">
						{steps &&
							steps.map((step, index) => (
								<div
									key={index}
									className="flex items-center gap-5 mb-3 cursor-pointer justify-between rounded-md transition-colors hover:bg-gray-100 px-2 py-3"
									onClick={() => handleStepSelect(index)}
									style={{ borderBottom: '1px solid #B7B9BB', borderRadius: 0 }} // Directly add borderBotto
								>
									<Progress
										type="circle"
										percent={step.progress ?? 0}
										size={40}
										strokeWidth={20}
										format={() => (
											<p className="font-sans font-bold text-xs">{`${
												index + 1
											}`}</p>
										)}
										strokeColor="#003399"
										trailColor="#d9d9d9"
										status="normal"
									/>
									<div className="flex-1 truncate">
										<strong
											className={`${
												index === activeStep
													? 'text-primary-blue'
													: 'text-gray-500'
											}`}
										>
											{step.title}
										</strong>
									</div>

									<DropDownIcon className="w-5 h-5 transform -rotate-90" />
								</div>
							))}
					</div>
				)}
			>
				<Option value={activeStep}>
					<div className="flex items-center gap-5 h-auto bg-blue-100 p-2 rounded-none">
						<Progress
							type="circle"
							percent={(steps && steps[activeStep].progress) ?? 0}
							size={50}
							strokeWidth={20}
							format={() => null}
							strokeColor={calculateDynamicStrokeColor()}
							trailColor="#d9d9d9"
						/>
						<div className="flex-1 truncate">
							<strong className="block">
								{activeStep + 1} / {steps && steps.length}{' '}
								{steps && steps[activeStep].title}
							</strong>
							<p className="text-xs text-gray-700 w-[90%] leading-[25px] break-normal whitespace-normal">
								{steps && steps[activeStep].description}
							</p>
						</div>
						<div className="flex items-center">
							<DropDownIcon style={{ width: 20, height: 20 }} />
							{/* Left-aligned arrow */}
						</div>
					</div>
				</Option>
			</Select>
		</div>
	);
};

export default CircularStepsWithDropdown;
