import { withRedirectToLogin } from '../../hocs/withRedirectToLogin';
import { Layout } from 'antd';

import { FC, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { MeProfile } from '../../shared/MySrcm';
import { UserRole } from '../../shared/VolunteerApplicationServiceTypes';
import { useBatchId } from '../../hooks/useBatchId';
import { FUNCTIONARY_HOME, ROOT } from '../../shared/Routes';
import { ApplicantAppHeader } from '../ApplicantAppHeader';
import { useAuth } from '../../hooks/useAuth';

const { Content } = Layout;

interface LayoutProps {
	logout?: () => Promise<void>;
	firebaseId?: string | null;
	srcmId?: string;
	mySrcmProfile?: MeProfile | null;
	loading?: boolean;
	role?: UserRole;
}

export const ApplicantLayout: FC<LayoutProps> = ({
	firebaseId,
	srcmId,
	mySrcmProfile,
	loading,
	role,
	logout,
}: LayoutProps) => {
	const { pathname } = useLocation();
	const { navigateWithBatchId } = useBatchId();
	const { isLoggedIn } = useAuth();

	useEffect(() => {
		if (!pathname.includes('oneauth') && !(isLoggedIn && isLoggedIn())) {
			navigateWithBatchId(ROOT);
		}
	}, [isLoggedIn, navigateWithBatchId, pathname]);

	if (
		role &&
		[
			UserRole.FUNCTIONARY,
			UserRole.APPROVER,
			UserRole.CARE_TEAM,
			UserRole.ZC,
			UserRole.BATCH_ADMIN,
			UserRole.ADMIN,
		].includes(role)
	) {
		navigateWithBatchId(FUNCTIONARY_HOME);
	}

	const isApplicantRole = role === UserRole.APPLICANT;

	return (
		<>
			<ApplicantAppHeader logout={logout} mySrcmProfile={mySrcmProfile} />
			<div className="flex justify-center bg-bground-blue">
				<div className="border p-[8px] w-full">
					<Layout className="mainlayout rounded-md p-[8px] bg-bground-blue">
						<Content className="bg-bground-blue">
							{!loading && isApplicantRole && (
								<Outlet context={{ srcmId, firebaseId, role }} />
							)}
						</Content>
					</Layout>
				</div>
			</div>
		</>
	);
};

export default withRedirectToLogin<object>(ApplicantLayout);
