import { useCallback, useEffect, useState } from 'react';
import { Avatar, Input, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../hooks/useAuth';
import { MeProfile } from '../../../shared/MySrcm';
import { useWizard } from '../../../components/wizard/useWizard';
import { useNewApplication } from './useNewApplication';
import { UpdateHFNProfileStatement } from '../../../components/UpdateHFNProfileStatement';
import { checkImage } from '../../../shared/Utils';
import DefaultAvatar from '../../../assets/images/defaultAvatar.svg';
import PhotoNotUploaded from '../../../assets/images/photo-not-uploaded.svg';
import {
	VALID_ABHYASI_ID_REGEX,
	VALID_TEMP_ABHYASI_ID_REGEX,
} from '../../../shared/Constants';
import Icon from '../../../components/ui-components/Icon';
import RefreshIcon from '../../../assets/images/refresh-icon.svg';
import { myHFNProfileURL } from '../../../service/EnvService';
import { ConfirmPopUp } from '../../../components/ui-components/ConfirmPopUp';

const { Text, Link } = Typography;

interface HeartfulnessProfileStepProps {
	mySrcmProfile?: MeProfile | null;
}

const checkIfIdCardIsPermanent = (ref: string | undefined) => {
	if (!ref) return false;

	if (VALID_TEMP_ABHYASI_ID_REGEX.test(ref)) {
		return false;
	} else if (VALID_ABHYASI_ID_REGEX.test(ref)) {
		return true;
	} else {
		return false;
	}
};

const ProfileDetailLabelAndValue = ({
	labelText,
	hint,
	value,
	errorMessage,
}: {
	labelText: string;
	hint?: string | null;
	value?: string | number | null;
	errorMessage: string;
}) => {
	return (
		<div className="flex flex-col">
			<div className="flex gap-2 items-center">
				<Text
					className={`mb-1 flex-2 text-sm font-normal font-sans text-secondary-black ${
						hint ? 'flex items-center gap-2' : ''
					}`}
				>
					{labelText}
				</Text>
				<Text type="secondary" className=" flex-1 mb-1 text-xs">
					{hint}
				</Text>
			</div>
			<Input
				className="h-11 text-sm font-normal font-sans text-light-gray rounded-lg pr-8"
				value={value || ''}
				disabled
			/>
			{value === undefined || value === null || value == '' ? (
				<Text type="danger" className="text-sm font-normal font-sans">
					{errorMessage}
				</Text>
			) : null}
		</div>
	);
};

const Header = () => {
	const { t } = useTranslation();

	return (
		<div className="flex flex-col my-2">
			{/* <Title className="mt-2 font-sans" level={4}>
				{t('heartfulnessProfile')}
			</Title> */}
			<Text className="my-4 text-sm font-normal font-sans">
				{t('detailsFetchedFromHeartfulnessProfile')}
			</Text>
		</div>
	);
};

export const HeartfulnessProfileStep = ({
	mySrcmProfile,
}: HeartfulnessProfileStepProps) => {
	const { t } = useTranslation();
	const profileImage = mySrcmProfile?.photo_url || '';
	const [validImage, setValidImage] = useState(profileImage);

	useEffect(() => {
		const fetchImage = async () => {
			const checkImageValue = await checkImage(profileImage);
			if (profileImage && checkImageValue) {
				setValidImage(profileImage);
			} else {
				setValidImage(DefaultAvatar);
			}
		};

		void fetchImage();
	}, [profileImage]);

	const ProfileFields = () => {
		return (
			<>
				<div className="grid grid-cols-2 md:grid-cols-2 gap-4">
					<ProfileDetailLabelAndValue
						labelText={t('firstName')}
						value={mySrcmProfile?.first_name}
						errorMessage={t('required')}
					/>
					<ProfileDetailLabelAndValue
						labelText={t('lastName')}
						value={mySrcmProfile?.last_name}
						errorMessage={t('required')}
					/>
					<ProfileDetailLabelAndValue
						labelText={t('gender')}
						value={mySrcmProfile?.gender}
						errorMessage={t('required')}
					/>
					<ProfileDetailLabelAndValue
						labelText={t('srcmOrIdCard')}
						value={mySrcmProfile?.ref}
						errorMessage={t('required')}
					/>
				</div>
				<div className="grid grid-cols-1 gap-4 mt-4">
					<ProfileDetailLabelAndValue
						labelText={t('email')}
						hint={t('applicationStatusWillBeSentHere')}
						value={mySrcmProfile?.email}
						errorMessage={t('required')}
					/>
					<ProfileDetailLabelAndValue
						labelText={t('mobile')}
						hint={t('teamWillContactHere')}
						value={mySrcmProfile?.mobile}
						errorMessage={t('required')}
					/>
				</div>
			</>
		);
	};

	return (
		<div className="flex flex-col h-full min-h-0 max-w-[600px]">
			<Header />
			{mySrcmProfile?.photo_url ? (
				<div className="flex justify-center items-center mt-4 mb-8">
					<Avatar
						src={validImage}
						size={200}
						className="border border-gray-300"
					/>
				</div>
			) : (
				<div className="flex flex-col gap-4 justify-center items-center mt-4 mb-8">
					<Avatar
						src={PhotoNotUploaded}
						size={150}
						className="border border-gray-300"
					/>
					<Text
						type="danger"
						className="text-sm text-center font-normal font-sans max-w-[250px]"
					>
						{t('photo')} {t('required')} - {t('yourFaceShouldBeClearInPhoto')}
					</Text>
				</div>
			)}
			<ProfileFields />
			<UpdateHFNProfileStatement />
		</div>
	);
};

export const HeartfulnessProfileStepContainer = () => {
	const { t } = useTranslation();
	const { mySrcmProfile, logout } = useAuth();
	const { setNextButtonDisabled, setCancelButtonDisabled } = useWizard();
	const { setMySrcmProfile } = useNewApplication();
	const [isPermanentId, setIsPermanentId] = useState(
		checkIfIdCardIsPermanent(mySrcmProfile?.ref)
	);

	useEffect(() => {
		const someOfMandatoryFieldsAreNotProvided = [
			mySrcmProfile?.photo_url,
			mySrcmProfile?.ref,
			mySrcmProfile?.first_name,
			mySrcmProfile?.last_name,
			mySrcmProfile?.gender,
			mySrcmProfile?.email,
			mySrcmProfile?.mobile,
		].some((value) => value === null || value === undefined || !value);
		setNextButtonDisabled(
			someOfMandatoryFieldsAreNotProvided || !isPermanentId
		);
		setMySrcmProfile(mySrcmProfile ? mySrcmProfile : {});
		setCancelButtonDisabled(true);
	}, [
		mySrcmProfile,
		mySrcmProfile?.email,
		mySrcmProfile?.first_name,
		mySrcmProfile?.gender,
		mySrcmProfile?.last_name,
		mySrcmProfile?.mobile,
		mySrcmProfile?.photo_url,
		mySrcmProfile?.ref,
		setCancelButtonDisabled,
		setMySrcmProfile,
		setNextButtonDisabled,
		isPermanentId,
	]);

	const handleUpdateAndRefresh = useCallback(() => {
		setIsPermanentId(true);
		window.location.reload();
	}, []);

	const handleLogout = useCallback(() => {
		setIsPermanentId(true);
		if (logout) {
			void logout();
		}
	}, [logout]);

	return (
		<>
			<HeartfulnessProfileStep mySrcmProfile={mySrcmProfile} />
			<ConfirmPopUp
				visible={!isPermanentId}
				title={'regularHFNId'}
				okText={'refresh'}
				onYes={() => void handleUpdateAndRefresh()}
				cancelText={'logout'}
				onClose={() => void handleLogout()}
				isDanger
				content={
					<div className="flex flex-col font-sans font-normal text-sm">
						<div className="flex flex-col items-center">
							<div className="w-24 h-20 my-4">
								<Icon src={RefreshIcon} className="w-full h-full" />
							</div>
							<div className="text-center my-2">
								{t('pleaseGetAPermanentIdCard')}{' '}
								<Link
									href={myHFNProfileURL}
									target="_blank"
									className="px-1 color-black text-sm font-normal font-sans text-primary-blue"
									underline
								>
									{t('regularHFNId')}
								</Link>
							</div>
						</div>
					</div>
				}
			/>
			{/* <InfoPopUp
				visible={!isPermanentId}
				title={t('regularHFNId') || 'Regular Heartfulness ID'}
				onYes={() => void handleUpdateAndRefresh()}
				content={
					<div className="flex flex-col font-sans font-normal text-sm">
						<div className="flex flex-col items-center">
							<div className="w-24 h-20 my-4">
								<Icon src={RefreshIcon} className="w-full h-full" />
							</div>
							<div className="text-center my-2">
								{t('pleaseGetAPermanentIdCard')}{' '}
								<Link
									href={myHFNProfileURL}
									target="_blank"
									className="px-1 color-black text-sm font-normal font-sans text-primary-blue"
									underline
								>
									{t('regularHFNId')}
								</Link>
							</div>
						</div>
					</div>
				}
				okText={'Refresh'}
			/> */}
		</>
	);
};
