import { useState, useCallback } from 'react';
import { Avatar, Button, Drawer } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import LanguageDropdown from './LanguageDropdown';
import LogoutImage from '../assets/images/logout-red-icon.svg';
import { useTranslation } from 'react-i18next';

interface AppHeaderProps {
	logout?: () => void | Promise<void>;
	username?: string;
	profileImage?: string;
}

export const ApplicantMobileHeader = ({
	logout,
	username,
	profileImage,
}: AppHeaderProps) => {
	const { t } = useTranslation();
	const [drawerVisible, setDrawerVisible] = useState(false);
	const handleDrawerOpen = useCallback(() => {
		setDrawerVisible(true);
	}, []);

	const handleDrawerClose = useCallback(() => {
		setDrawerVisible(false);
	}, []);
	const handleLogoutClick = useCallback(() => {
		if (logout) {
			void logout();
		}
	}, [logout]);

	const logoutText = t('logout');
	const LogoutIcon = () => (
		<>
			<img src={LogoutImage} alt="Logout Icon" />
		</>
	);

	return (
		<>
			<div className="md:hidden">
				<Button
					type="text"
					icon={<MenuOutlined />}
					onClick={handleDrawerOpen}
				/>
			</div>

			<Drawer
				title="Menu"
				placement="right"
				onClose={handleDrawerClose}
				open={drawerVisible}
				closable={true}
				width="75vw"
			>
				<div className="flex flex-col items-start mt-4 space-y-6">
					{/* ✅ Avatar & Username in One Line */}
					<div className="flex items-center space-x-3">
						<Avatar
							src={profileImage}
							size={40}
							className="border border-gray-300 shadow-sm"
						/>
						<span className="font-sans font-medium text-gray-700 text-base">
							{username}
						</span>
					</div>

					{/* ✅ Left-Aligned Menu Options */}
					<div className="flex flex-col items-start space-y-4">
						<LanguageDropdown className="w-24" />

						{/* Logout Button */}
						<Button
							type="link"
							shape="round"
							onClick={handleLogoutClick}
							size="large"
							className="text-red  hover:!text-red font-sans font-medium flex px-0 py-0"
							style={{
								justifyContent: 'flex-start',
								padding: 0,
							}}
						>
							<LogoutIcon />
							<span>{logoutText}</span>
						</Button>
					</div>
				</div>
			</Drawer>
		</>
	);
};
