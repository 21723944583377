import { useEffect, useState } from 'react';
import { Form, FormInstance, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import {
	ApplicantInfo,
	Preceptor,
} from '../shared/VolunteerApplicationServiceTypes';
import { StepHeader } from './StepHeader';
import { UpdateHFNProfileStatement } from './UpdateHFNProfileStatement';
import { PreceptorContactDetailsType } from './PreceptorContactDetailsType';

interface PreceptorContactDetailsFormProps {
	form: FormInstance;
	applicant?: ApplicantInfo;
	preceptorDetails?: Preceptor;
}

export const PreceptorContactDetailsForm: React.FC<
	PreceptorContactDetailsFormProps
> = ({
	form,
	applicant,
	preceptorDetails,
}: PreceptorContactDetailsFormProps) => {
	const { t } = useTranslation();
	const [initialValues, setInitialValues] =
		useState<PreceptorContactDetailsType>({});

	useEffect(() => {
		const updatedValues = {
			preceptorName: `${preceptorDetails?.firstName || ''} ${
				preceptorDetails?.middleName || ''
			} ${preceptorDetails?.lastName || ''}`.trim(),
			preceptorMobile: preceptorDetails?.mobile,
		};

		setInitialValues(updatedValues);
		form.setFieldsValue(updatedValues);
		form.validateFields(['preceptorMobile']).catch(() => {});
	}, [
		form,
		preceptorDetails?.firstName,
		preceptorDetails?.middleName,
		preceptorDetails?.lastName,
		preceptorDetails?.mobile,
	]);

	return (
		<div className="flex flex-col mt-8">
			<Form
				name="basic"
				layout="vertical"
				size="middle"
				labelCol={{ span: 16 }}
				style={{ maxWidth: 600 }}
				initialValues={initialValues}
				autoComplete="off"
				form={form}
			>
				<>
					<StepHeader
						heading={t('preceptorContact')}
						description={
							applicant
								? t('provideCandidatePreceptorContact')
								: t('provideYourPreceptorContact')
						}
					/>
					<Form.Item
						label={
							<span className="font-sans font-normal text-xs text-secondary-black">
								{t('preceptorName')}
							</span>
						}
						labelCol={{ className: '!pb-0' }}
						name="preceptorName"
						rules={[
							{
								required: true,
								message: <span>{t('required')}</span>,
							},
						]}
						className="mb-4"
					>
						<Input
							className="h-11 text-sm font-normal font-sans text-light-gray rounded-lg"
							maxLength={255}
							disabled
						/>
					</Form.Item>
					<Form.Item
						label={
							<span className="font-sans font-normal text-xs text-secondary-black">
								{t('preceptorMobileNo')}
							</span>
						}
						labelCol={{ className: '!pb-0' }}
						name="preceptorMobile"
						rules={[
							{
								required: true,
								message: <span>{t('required')}</span>,
							},
						]}
						className="mb-4"
					>
						<Input
							className="h-11 text-sm font-normal font-sans text-light-gray rounded-lg"
							disabled
						/>
					</Form.Item>
				</>
			</Form>
			<UpdateHFNProfileStatement />
		</div>
	);
};
