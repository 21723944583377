import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLoadingIndicator } from './useLoadingIndicator';
import { useToastMessage } from './useToastMessage';
import get from 'lodash/get';
import { AxiosError } from 'axios';

export const useCallApi = () => {
	const { t } = useTranslation();
	const { setLoading } = useLoadingIndicator();
	const { showToastMessage } = useToastMessage();

	const callApi = useCallback(
		async <Resp,>(api: () => Promise<Resp>, genericErrorMessage: string) => {
			try {
				setLoading(true);
				const response = await api();
				return response;
			} catch (e) {
				const errorMessage: string | undefined = get(
					e,
					'response.data.message'
				);

				if (e instanceof AxiosError) {
					// eslint-disable-next-line
					showToastMessage('error', e?.response?.data?.message || e.message);
				} else if (errorMessage) {
					showToastMessage('error', errorMessage);
				} else if (e instanceof Error) {
					showToastMessage('error', e.message);
				} else {
					showToastMessage('error', t(genericErrorMessage));
				}
				throw e;
			} finally {
				setLoading(false);
			}
		},
		[setLoading, showToastMessage, t]
	);

	return { callApi };
};
