import { Typography, Descriptions } from 'antd';
import { useTranslation } from 'react-i18next';
import { MultipleChoiceQuestionUIConfig } from '../../shared/MultipleChoiceQuestionUIConfig';
import { Answer } from '../../shared/VolunteerApplicationServiceTypes';

const { Text } = Typography;

export interface ApplicantAnswerToQuestionProps {
	uiConfig: MultipleChoiceQuestionUIConfig;
	value?: Answer;
}

export const ApplicantAnswerToQuestion = ({
	uiConfig: { questionNo, questionText, answerOptions },
	value,
}: ApplicantAnswerToQuestionProps) => {
	const { t } = useTranslation();

	let answerOption;

	if (value) {
		answerOption =
			answerOptions &&
			answerOptions.find((option) => option.optionId === value.answerOptionId);
	}

	const answerText = answerOption ? t(answerOption.optionText) : '';

	return (
		<div className="flex flex-row items-start">
			<Text className="mr-[8px] min-w-[24px]">{`${questionNo})`}</Text>
			<div className="flex flex-col w-full">
				<Text className="mb-[8px]">{t(questionText)}</Text>
				<Descriptions
					bordered
					column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
					size="small"
					className="my-[8px] min-w-[360px]"
				>
					<Descriptions.Item label={t('answer')}>
						{answerText}
					</Descriptions.Item>
					<Descriptions.Item label={t('comments')}>
						{value?.additionalComment}
					</Descriptions.Item>
				</Descriptions>
			</div>
		</div>
	);
};
