export const FIREBASE_ID_TOKEN = 'FIREBASE_ID_TOKEN';
export const FIREBASE_ID = 'FIREBASE_ID';

export const KEYCLOAK_ID_TOKEN = 'KEYCLOAK_ID_TOKEN';
export const BATCH_ID = 'BATCH_ID';
export const ME_PROFILE = 'ME_PROFILE';
export const LOGGED_IN_USER_ROLE = 'LOGGED_IN_USER_ROLE';
export const APP_INITIATED = 'APP_INITIATED';

export const DEFAULT_PAGE_SIZE = 20;
export const OTP_REGENERATION_INTERVAL_IN_SECONDS = 59;
export const OTP_EXPIRATION_INTERVAL_IN_SECONDS = 599;

export const VALID_ABHYASI_ID_REGEX =
	/^([a-zA-Z]{6}[0-9]{3}|[HABhab]{1}[0-9]{8})$/;
export const VALID_TEMP_ABHYASI_ID_REGEX = /^([HABhab]{1}[0-9]{8})$/;
export const VALID_STRING_INPUT_REGEX =
	/^(?! )[^\s](?!.* {2})[\p{Script=Devanagari}\p{Script=Bengali}\p{Script=Gurmukhi}\p{Script=Gujarati}\p{Script=Oriya}\p{Script=Tamil}\p{Script=Telugu}\p{Script=Kannada}\p{Script=Malayalam}\p{Script=Sinhala}\p{Script=Thai}\p{Script=Lao}\p{Script=Tibetan}\p{Script=Latin}\d,."?'\n\- ]*$/u;
export const VALID_ADDRESS_REGEX =
	/^[\p{Script=Devanagari}\p{Script=Bengali}\p{Script=Gurmukhi}\p{Script=Gujarati}\p{Script=Oriya}\p{Script=Tamil}\p{Script=Telugu}\p{Script=Kannada}\p{Script=Malayalam}\p{Script=Sinhala}\p{Script=Thai}\p{Script=Lao}\p{Script=Tibetan}\p{Script=Latin} \d,.:"?'\n\- ]+$/u;
export const VALID_CITY_REGEX =
	/^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$/;
export const VALID_PINCODE_REGEX = /^[1-9]{1}[0-9]{2}\s?[0-9]{3}$/;
export const VALID_BATCH_REGEX = /^[0-9]{4}-[0-9]{2}$/;
export const VALID_NAME_REGEX = /^[a-zA-Z. ]+$/;
export const VALID_EMAIL_REGEX = /^[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
export const VALID_BATCH_NAME_REGEX = /^(?!\s*$).+/;
export const VALID_PHONE_REGEX = /^([+]\d{2})?\d{10}$/;
export const NO_WHITESPACE_REGEX = /^(?!\s*$).+/;
export const MAX_PRECEPTORS_FOR_GROUP = 5;

export const ApplicationStatus = {
	NOT_YET_APPLIED: 'NOT_YET_APPLIED',
	USER_SUBMITTED_APPLICATION: 'USER_SUBMITTED_APPLICATION',
	FUNCTIONARY_ASSIGNED: 'FUNCTIONARY_ASSIGNED',
	FUNCTIONARY_SUBMITTED: 'FUNCTIONARY_SUBMITTED',
	WAITING_FOR_CARE_TEAM: 'WAITING_FOR_CARE_TEAM',
	APPROVED: 'APPROVED',
	REJECTED: 'REJECTED',
	SYS_ANOTHER_FUNCTIONARY_ASSIGNED: 'SYS_ANOTHER_FUNCTIONARY_ASSIGNED',
};

export const Gender = {
	NOT_PROVIDED: 'NOT_PROVIDED',
	MALE: 'MALE',
	FEMALE: 'FEMALE',
};

export const ReferenceCategory = {
	PRECEPTOR: 'PRECEPTOR',
	FAMILY_MEMBER: 'FAMILY_MEMBER',
	OTHER_REFERENCE: 'OTHER_REFERENCE',
};

export const NewApplicationNotAllowedReason = {
	SUBMITTED_APPLICATION_IN_THE_PAST: 'SUBMITTED_APPLICATION_IN_THE_PAST',
	BLACKLISTED: 'BLACKLISTED',
	UNDER_REVIEW: 'UNDER_REVIEW',
	BATCH_CLOSED: 'BATCH_CLOSED',
};

export const EvaluationCategoryID = {
	INSPIRATION_AND_WILLINGNESS: 1,
	PRACTICE: 2,
	CHARACTER_AND_HABITS: 3,
	AVAILABILITY_AFTER_PROGRAM: 4,
	SUPPORT: 5,
	UNDERSTANDING_OF_HEARTFULNESS: 6,
	BASIC_ELIGIBILITY: 7,
	DIETARY_HABITS_AND_HEALTH: 8,
	PRE_WORK: 9,
};

export const CardIndex = {
	HEARTFULNESS_PROFILE: 0,
	ADDITIONAL_DETAILS: 1,
	TRAINING_COURSE_DETAILS: 2,
	PRECEPTOR_CONTACT_DETAILS: 3,
	FAMILY_MEMBER_DETAILS: 4,
	OTHER_REFERENCE_DETAILS: 5,
	WELCOME_AND_SELF_CONSCIOUSNESS: 6,
	BASIC_ELIGIBILITY: 7,
	INSPIRATION_AND_WILLINGNESS: 8,
	PRACTICE: 9,
	CHARACTER_AND_HABITS: 10,
	AVAILABILITY_AFTER_PROGRAM: 11,
	SUPPORT: 12,
	UNDERSTANDING_OF_HEARTFULNESS: 13,
	DIETARY_HABITS_AND_HEALTH: 14,
	TERMS_AND_CONDITIONS: 15,
};

export const EssentailCardIndex = {
	BASIC_ELIGIBILITY: 0,
	INSPIRATION_AND_WILLINGNESS: 1,
	PRACTICE: 2,
	CHARACTER_AND_HABITS: 3,
	AVAILABILITY_AFTER_PROGRAM: 4,
	SUPPORT: 5,
	UNDERSTANDING_OF_HEARTFULNESS: 6,
	DIETARY_HABITS_AND_HEALTH: 7,
	TERMS_AND_CONDITIONS: 8,
};

export const ReviewAction = {
	APPROVE: 'APPROVE',
	APPROVE_IDK_CANDIDATE: 'APPROVE_IDK_CANDIDATE',
	NOT_GOING_FORWARD: 'NOT_GOING_FORWARD',
	REJECT: 'REJECT',
	ON_HOLD: 'ON_HOLD',
	NOT_APPLICABLE: 'NOT_APPLICABLE_REVIEW',
	PENDING: 'PENDING',
};

export const ReapplyCondition = {
	NOT_APPLICABLE_CONDITION: 'NOT_APPLICABLE_CONDITION',
	BLACK_LISTED: 'BLACK_LISTED',
	AVAILABLE_FROM_TOMORROW: 'AVAILABLE_FROM_TOMORROW',
	AFTER_3_MONTHS: 'AFTER_3_MONTHS',
	AFTER_6_MONTHS: 'AFTER_6_MONTHS',
	AFTER_12_MONTHS: 'AFTER_12_MONTHS',
};

export const PersonRole = {
	AN_ABHYASI: 'AN_ABHYASI',
	A_PRECEPTOR: 'A_PRECEPTOR',
	NOT_YET_AN_ABHYASI: 'NOT_YET_AN_ABHYASI',
};

export const Relationship = {
	SPOUSE: 'Spouse',
	PARENT: 'Parent',
	OTHER: 'Other',
};

export const PredefinedReasonCategory = {
	SKIP: 'SKIP',
	REJECT: 'REJECT',
	PROPOSER_REJECT: 'PROPOSER_REJECT',
	PRECEPTOR_REJECT: 'PRECEPTOR_REJECT',
	APPROVER_REJECT: 'APPROVER_REJECT',
	ZC_REJECT: 'ZC_REJECT',
	CARE_TEAM_REJECT: 'CARE_TEAM_REJECT',
};

export const MedicationQuestionId = {
	NEW_APPLICATION_ONE: 42,
	NEW_APPLICATION_TWO: 43,
	NEW_APPLICATION_THREE: 44,
	APPLICANT_INTERVIEW: 100,
	PRECEPTOR_INTERVIEW: 101,
	FAMILY_MEMBER_INTERVIEW: 102,
	BASIC_ELIGIBILITY_APPLICANT_INTERVIEW: 103,
};

export const AnswerOptionsField = {
	RADIO: 'RADIO',
	SELECT: 'SELECT',
	BUTTONS: 'BUTTONS',
};

export const FunctionaryType = {
	IS_PROPOSER: 'IS_PROPOSER',
	IS_APPROVER: 'IS_APPROVER',
	IS_ZC: 'IS_ZC',
	IS_CARE_TEAM: 'IS_CARE_TEAM',
};

export const RejectedReasonRectifyOption = {
	NOT_APPLICABLE_OPTION: 0,
	RECTIFIED: 1,
	NOT_RECTIFIED: 2,
};

export const PreWorkQuestionId = {
	HEARTFUL_COMMUNICATION: 600,
	SYSTEM_GENERATED: 601,
	CANDIDATE_VIEW_ON_COMPLETION: 603,
};

export const PreworkCompletionStatus = {
	NOT_STARTED: 'NOT_STARTED',
	INCOMPLETE: 'INCOMPLETE',
	PARTIALLY_COMPLETED: 'PARTIALLY_COMPLETED',
	COMPLETED: 'COMPLETED',
};

export const GroupTabs = {
	CREATE_GROUP: '1',
	GROUPS_LIST_VIEW: '2',
	VIEW_ALL_NEW_PRECEPTORS: '3',
	GRAPHICAL_VIEW: '4',
	BATCHES: '5',
};

export const TOTAL_NO_OF_NPS_CARDS = 5;
export const COUNTRY_CODE_INDIA = 358;

export const EXAM_LANGUAGES = [
	'Hindi',
	'Telugu',
	'Bengali',
	'English',
	'Malayalam',
	'Tamil',
	'Marathi',
	'Gujarati',
	'Kannada',
];

export const MARK_RANGES = [
	'0-5',
	'0-10',
	'0-15',
	'0-20',
	'0-25',
	'0-30',
	'0-35',
	'0-40',
	'0-45',
	'0-50',
	'0-55',
	'0-60',
	'0-65',
	'0-70',
	'0-75',
	'0-80',
	'0-85',
	'0-90',
	'0-95',
	'0-100',
];

export const DATE_DD_MMM_YYYY = 'DD MMM YYYY';
export const DATE_DO_MMM_YYYY = 'Do MMM, YYYY';
export const DATE_DO_MMMM_YYYY = 'Do MMMM YYYY';
export const TIME_H_MM_A = 'h:mm A';
export const DATE_DD_MM_YYYY = 'DD.MM.YYYY';

export const GraphTabs = {
	REGISTRATION_GRAPH: '1',
	RESULTS_GRAPH: '2',
};
export const PROJECT_CODE_FOR_MAINTENANCE = 'snp';
export const TABLE_VIEW_LABEL = 'Table View';
export const GRAPH_VIEW_LABEL = 'Graph View';
export const TABLE_VIEW_VALUE = 'TABLE_VIEW';
export const GRAPH_VIEW_VALUE = 'GRAPH_VIEW';
export const RESULTS_VIEW_OPTIONS = [
	{ label: TABLE_VIEW_LABEL, value: TABLE_VIEW_VALUE },
	{ label: GRAPH_VIEW_LABEL, value: GRAPH_VIEW_VALUE },
];
