import {
	createContext,
	useCallback,
	useContext,
	useMemo,
	useState,
	FC,
	ReactNode,
	useEffect,
} from 'react';
import { useBatchId } from './useBatchId';
import { ROOT } from '../shared/Routes';
import firebase from 'firebase/compat/app';
import { MeProfile } from '../shared/MySrcm';
import { UserRole } from '../shared/VolunteerApplicationServiceTypes';
import { useLoadingIndicator } from './useLoadingIndicator';
import { ApplicationsListState } from '../pages/functionary/ApplicationsList/ApplicationsList.store';
import {
	DashboardDetailState,
	DashboardListState,
	GroupsListState,
	MentorsListState,
	PreceptorBatchState,
	PreceptorsListState,
} from '../pages/nps/CreateGroupPage/PreceptorsList.store';
import { useTranslation } from 'react-i18next';
import { getItem } from '../service/LocalStorageService';
import { KEYCLOAK_ID_TOKEN } from '../shared/Constants';
import useUserSession from './useUserSession';
import { Button, Modal } from 'antd';
import Icon from '../components/ui-components/Icon';
import WarningIcon from '../assets/images/warning-icon.svg';
import { useToastMessage } from './useToastMessage';
import { getMeProfile } from '../service/MySrcmService';
import { getRole } from '../service/VolunteerApplicationService';

export interface AuthContextType {
	firebaseId: string | null;
	setFirebaseId: (id: string | null) => void;
	srcmId?: string;
	mySrcmProfile: MeProfile | null;
	setMySrcmProfile: (profile: MeProfile | null) => void;
	logout: (showExpired?: boolean) => Promise<void>;
	loading: boolean;
	onLogin: (loggedInUser: firebase.User) => Promise<void>;
	isLoggedIn: () => boolean;
	initiated: boolean;
	setInitiated: (value: boolean) => void;
	role: UserRole;
	setRole: (role: UserRole) => void;
}

interface Props {
	children: ReactNode;
}

let globalLogout: (() => Promise<void>) | null = null;

export const AuthContext = createContext<Partial<AuthContextType>>({});

export const AuthProvider: FC<Props> = ({ children }: Props) => {
	const {
		meProfile,
		setMeProfile,
		loggedInUserRole,
		setLoggedInUserRole,
		appInitiated,
		setAppInitiated,
		srcmFirebaseId,
		setSrcmFirebaseId,
		clearAppInitiated,
		clearKeycloakToken,
		clearLoggedInUserRole,
		clearMeProfile,
		clearSrcmFirebaseId,
	} = useUserSession();
	const [loading, setDeterminingLoginStatus] = useState<boolean>(false);
	const [initiated, setInitiated] = useState<boolean>(false);
	const [firebaseId, setFirebaseId] = useState<string | null>(null);
	const { navigateWithBatchId, batchId } = useBatchId();
	const [mySrcmProfile, setMySrcmProfile] = useState<MeProfile | null>(null);
	const [role, setRole] = useState<UserRole>(UserRole.NOT_SET);
	const { setLoading: showLoadingIndicator } = useLoadingIndicator();
	const { i18n, t } = useTranslation();
	const { showToastMessage } = useToastMessage();

	useEffect(() => {
		showLoadingIndicator(loading);
	}, [loading, showLoadingIndicator]);

	const setLoggedInUserData = useCallback(async () => {
		setDeterminingLoginStatus(true);

		try {
			const isPageRefresh = window.performance
				.getEntriesByType('navigation')
				.some(
					(entry) => (entry as PerformanceNavigationTiming).type === 'reload'
				);

			if (isPageRefresh) {
				const meProfileData = await getMeProfile();
				const profile = meProfileData.results?.[0];

				if (profile) {
					setMySrcmProfile(profile);
					setMeProfile(profile);

					if (profile.ref && profile.user_firebase_uid) {
						setSrcmFirebaseId(profile.user_firebase_uid);
						setFirebaseId(profile.user_firebase_uid);

						const roleResponse = await getRole({
							firebaseId: profile.user_firebase_uid,
							batchID: batchId,
							srcmId: profile.ref,
						});

						const userRole = roleResponse.role || UserRole.APPLICANT;
						setRole(userRole);
						setLoggedInUserRole(userRole);
					} else {
						setRole(UserRole.APPLICANT);
						setLoggedInUserRole(UserRole.APPLICANT);
					}
				}

				setInitiated(true);
				setAppInitiated(true);
			} else {
				setMySrcmProfile(meProfile);
				if (loggedInUserRole) {
					setRole(loggedInUserRole || UserRole.APPLICANT);
				}
				if (appInitiated) {
					setInitiated(appInitiated);
				}
				setFirebaseId(srcmFirebaseId);
			}
		} catch (e) {
			showToastMessage(
				'error',
				e instanceof Error ? e.message : 'Unknown error occurred'
			);
		}

		setDeterminingLoginStatus(false);
	}, [
		meProfile,
		appInitiated,
		loggedInUserRole,
		srcmFirebaseId,
		batchId,
		setAppInitiated,
		setLoggedInUserRole,
		setMeProfile,
		setSrcmFirebaseId,
		showToastMessage,
	]);

	useEffect(() => {
		void setLoggedInUserData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onLogin = useCallback(
		async () => setLoggedInUserData(),
		[setLoggedInUserData]
	);

	const isLoggedIn = useCallback(() => {
		const idToken = getItem<string | null>(KEYCLOAK_ID_TOKEN, null);
		return idToken !== null;
	}, []);

	/* eslint-disable */
	const keycloakLogout = useCallback(async () => {
		if (typeof window !== 'undefined') {
			const { userLogout } = await import('hfn-oneauth/main');
			const res = await userLogout(
				JSON.parse(process.env.REACT_APP_KEYCLOAK_CONFIG || '')
			);
			return res;
		}
	}, []);

	const ModalContent = useCallback(
		(content: string) => {
			return (
				<div className="flex flex-col font-sans font-normal text-sm">
					<div className="flex flex-col items-center">
						<div className="w-24 h-20 my-4">
							<Icon src={WarningIcon} className="w-full h-full" />
						</div>
						<div className="text-center my-2">{t(content)}</div>
					</div>
				</div>
			);
		},
		[t]
	);

	const ModalFooter = useCallback(() => {
		return (
			<div className="flex w-full gap-4 mt-4">
				<Button
					type="default"
					className="flex-1 h-9 px-4 py-2 font-sans font-bold text-sm bg-primary-blue text-white rounded-lg"
					onClick={() => {
						Modal.destroyAll();
						navigateWithBatchId(ROOT, { replace: true });
					}}
				>
					{t('ok')}
				</Button>
			</div>
		);
	}, [navigateWithBatchId, t]);

	const logout = useCallback(
		async (showExpired = false) => {
			try {
				const res = await keycloakLogout();
				if (res?.error) {
					return;
				}

				const resetState = () => {
					clearAppInitiated();
					clearKeycloakToken();
					clearLoggedInUserRole();
					clearMeProfile();
					clearSrcmFirebaseId();
					setInitiated(false);
					setMySrcmProfile(null);

					ApplicationsListState.filterBy = undefined;
					PreceptorsListState.filterBy = undefined;
					MentorsListState.filterBy = undefined;
					DashboardListState.filterBy = undefined;
					DashboardDetailState.filterBy = undefined;
					GroupsListState.filterBy = undefined;
					PreceptorBatchState.selectedBatch = undefined;
				};

				resetState();

				localStorage.removeItem('code_verifier');
				localStorage.removeItem('state');

				void i18n.changeLanguage('en');
				if (showExpired) {
					console.log('show modal');
					Modal.success({
						className: 'max-w-md',
						title: (
							<div className="text-center font-sans font-bold text-lg">
								{t('sessionExpired')}
							</div>
						),
						content: ModalContent('sessionExpiredPleaseLoginAgain'),
						footer: ModalFooter(),
						icon: null,
					});
				} else {
					navigateWithBatchId(ROOT, { replace: true });
				}
			} catch (error) {
				console.error('Error during logout:', error);
			}
		},
		[
			clearAppInitiated,
			clearKeycloakToken,
			clearLoggedInUserRole,
			clearMeProfile,
			clearSrcmFirebaseId,
			i18n,
			keycloakLogout,
			navigateWithBatchId,
			setMySrcmProfile,
			setInitiated,
		]
	);
	/* eslint-enable */

	useEffect(() => {
		globalLogout = logout;
	}, [logout]);

	const value = useMemo<AuthContextType>(
		() => ({
			logout,
			loading,
			onLogin,
			isLoggedIn,
			initiated,
			setInitiated,
			firebaseId,
			setFirebaseId,
			srcmId: mySrcmProfile?.ref,
			mySrcmProfile,
			setMySrcmProfile,
			role,
			setRole,
		}),
		[
			logout,
			loading,
			onLogin,
			isLoggedIn,
			initiated,
			setInitiated,
			firebaseId,
			setFirebaseId,
			mySrcmProfile,
			setMySrcmProfile,
			role,
			setRole,
		]
	);

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
	return useContext(AuthContext);
};

export const getGlobalLogout = () => globalLogout;
