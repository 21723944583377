import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { AuthProvider } from './hooks/useAuth';
import { LoadingIndicatorProvider } from './hooks/useLoadingIndicator';
import { ToastMessageProvider } from './hooks/useToastMessage';
import { ConfigProvider } from 'antd';
import './index.css';
import './i18n';

// Modularize Ant Design theme configuration
const themeConfig = {
	components: {
		Checkbox: { colorPrimary: '#003399', algorithm: true },
		Select: {
			fontFamily: 'Public Sans',
			colorPrimary: '#003399',
			algorithm: true,
		},
		Typography: { colorPrimary: '#003399', algorithm: true },
		Button: { colorPrimary: '#003399', algorithm: true },
		Radio: { colorPrimary: '#003399', algorithm: true },
		Input: {
			fontFamily: 'Public Sans',
			colorPrimary: '#003399',
			algorithm: true,
		},
		InputNumber: {
			fontFamily: 'Public Sans',
			colorPrimary: '#003399',
			algorithm: true,
		},
		Spin: {
			fontFamily: 'Public Sans',
			colorPrimary: '#003399',
			algorithm: true,
		},
		Steps: {
			fontFamily: 'Public Sans',
			colorPrimary: '#003399',
			algorithm: true,
		},
	},
};

// Create a custom AppProviders component
const AppProviders = ({ children }: { children: React.ReactNode }) => (
	<BrowserRouter>
		<ConfigProvider theme={themeConfig}>
			<LoadingIndicatorProvider>
				<ToastMessageProvider>
					<AuthProvider>{children}</AuthProvider>
				</ToastMessageProvider>
			</LoadingIndicatorProvider>
		</ConfigProvider>
	</BrowserRouter>
);

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
	<StrictMode>
		<AppProviders>
			<App />
		</AppProviders>
	</StrictMode>
);
