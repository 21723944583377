import { useCallback, useState } from 'react';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import Icon from './ui-components/Icon';
import RefreshIcon from '../assets/images/refresh-icon.svg';
import { InfoPopUp } from './ui-components/InfoPopUp';
import { myHFNProfileURL } from '../service/EnvService';

const { Text, Link } = Typography;

export const UpdateHFNProfileStatement = () => {
	const { t } = useTranslation();
	const [isModalVisible, setIsModalVisible] = useState(false);

	const handleLinkClick = useCallback(
		(e: React.MouseEvent<HTMLAnchorElement>) => {
			e.preventDefault();
			window.open(myHFNProfileURL, '_blank');
			setIsModalVisible(true);
		},
		[]
	);

	const handleRefresh = useCallback(() => {
		setIsModalVisible(false);
		window.location.reload();
	}, []);

	return (
		<div className="flex flex-col mt-10 px-2 max-w-[600px]">
			<Text className="text-sm font-normal font-sans">
				{t('toUpdateYourDetails')}{' '}
				<Link
					href={myHFNProfileURL}
					target="_blank"
					className="px-1 color-black text-sm font-normal font-sans text-primary-blue"
					underline
					onClick={handleLinkClick}
				>
					{t('heartfulnessProfilePage')}
				</Link>
				{t('comeBackHereToProceed')}
			</Text>
			<InfoPopUp
				visible={isModalVisible}
				title={t('pleaseRefresh') || 'Please Refresh'}
				onYes={() => void handleRefresh()}
				content={
					<div className="flex flex-col font-sans font-normal text-sm">
						<div className="flex flex-col items-center">
							<div className="w-24 h-20 my-4">
								<Icon src={RefreshIcon} className="w-full h-full" />
							</div>
							<div className="text-center my-2">
								{t('pleaseRefreshToReflectChanges')}
							</div>
						</div>
					</div>
				}
				okText={'Refresh'}
			/>
		</div>
	);
};
