import {
	Checkbox,
	Descriptions,
	Form,
	FormInstance,
	Input,
	Radio,
	Select,
	Typography,
	DatePicker,
} from 'antd';
import dayjs, { extend } from 'dayjs';
import dayjsUTC from 'dayjs/plugin/utc';
import { useTranslation } from 'react-i18next';
import CenterDropdown from './CentersDropdown';
import {
	PersonRole,
	VALID_ADDRESS_REGEX,
	VALID_CITY_REGEX,
	VALID_PINCODE_REGEX,
	VALID_STRING_INPUT_REGEX,
} from '../shared/Constants';
import { ApplicantInfo } from '../shared/VolunteerApplicationServiceTypes';

extend(dayjsUTC);
const { Text, Title } = Typography;

interface ApplicantAdditionalDetailsFormProps {
	form: FormInstance;
	applicant?: ApplicantInfo;
}

const SectionHeader = ({
	heading,
	description,
}: {
	heading: string;
	description: string;
}) => {
	return (
		<div className="flex flex-col mb-[8px]">
			<Title level={4}>{heading}</Title>
			<Text className="mb-[8px]" type="secondary">
				{description}
			</Text>
		</div>
	);
};

export const ApplicantAdditionalDetailsForm: React.FC<
	ApplicantAdditionalDetailsFormProps
> = ({ form, applicant }: ApplicantAdditionalDetailsFormProps) => {
	const { t } = useTranslation();

	const otherReferenceProvided = Form.useWatch<boolean | undefined>(
		'otherReferenceProvided',
		form
	);

	const familyMemberRelationship = Form.useWatch<string | undefined>(
		'familyMemberRelationship',
		form
	);

	return (
		<div className="flex flex-col">
			<Form
				name="basic"
				layout="vertical"
				size="middle"
				labelCol={{ span: 16 }}
				style={{ maxWidth: 600 }}
				initialValues={{ remember: true }}
				autoComplete="off"
				form={form}
			>
				<>
					{applicant && (
						<Descriptions
							bordered
							column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
							size="small"
							className="mb-[16px] min-w-[360px]"
						>
							<Descriptions.Item label={t('firstName')}>
								{applicant?.firstName}
							</Descriptions.Item>
							<Descriptions.Item label={t('middleName')}>
								{applicant?.middleName}
							</Descriptions.Item>
							<Descriptions.Item label={t('lastName')}>
								{applicant?.lastName}
							</Descriptions.Item>
							<Descriptions.Item label={t('idCardNo')}>
								{applicant?.srcmid}
							</Descriptions.Item>
							<Descriptions.Item label={t('gender')}>
								{applicant?.gender}
							</Descriptions.Item>
							<Descriptions.Item label={t('email')}>
								{applicant?.email}
							</Descriptions.Item>
							<Descriptions.Item label={t('mobile')}>
								{applicant?.mobile}
							</Descriptions.Item>
						</Descriptions>
					)}

					<SectionHeader
						heading={t('additionalDetails')}
						description={
							applicant
								? t('collectFollowingDetailsOfCandidate')
								: t('provideTheFollowingDetailsToHelp')
						}
					/>

					<Form.Item
						label={
							applicant
								? t('selectCenterWhereCandidateIsResidingAt')
								: t('selectApplicationSubmissionCenter')
						}
						name={'center'}
						rules={[
							{
								required: true,
								message: <>{t('required')}</>,
							},
						]}
					>
						<CenterDropdown />
					</Form.Item>
					<Form.Item
						label={t('monthOfFirstSitting')}
						name="doj"
						hasFeedback
						rules={[
							{
								required: true,
								message: <span>{t('required')}</span>,
							},
						]}
					>
						<DatePicker format={'YYYY - MMM'} picker="month" />
					</Form.Item>
					<Form.Item
						label={t('dateOfBirth')}
						name="dateOfBirth"
						hasFeedback
						rules={[
							{
								required: true,
								message: <span>{t('required')}</span>,
							},
						]}
					>
						<DatePicker
							defaultPickerValue={dayjs().subtract(15, 'years')} // Use dayjs instead of moment
							disabledDate={
								(current) => dayjs().subtract(15, 'years').isBefore(current) // Use dayjs for date comparison
							}
						/>
					</Form.Item>
					<Form.Item
						label={t('educationQualification')}
						name="educationQualification"
						rules={[
							{
								required: true,
								message: <span>{t('required')}</span>,
							},
							{
								pattern: VALID_STRING_INPUT_REGEX,
								message: <span>{t('onlyAllowedSpecialCharacters')}</span>,
							},
						]}
						hasFeedback
					>
						<Input maxLength={100} />
					</Form.Item>
					<Form.Item
						label={t('profession')}
						name="profession"
						hasFeedback
						rules={[
							{
								required: true,
								message: <span>{t('required')}</span>,
							},
							{
								pattern: VALID_STRING_INPUT_REGEX,
								message: <span>{t('onlyAllowedSpecialCharacters')}</span>,
							},
						]}
					>
						<Input maxLength={100} />
					</Form.Item>
					<Form.Item
						label={t('professionDetails')}
						name="professionDetails"
						hasFeedback
						rules={[
							{
								required: true,
								message: <span>{t('required')}</span>,
							},
							{
								pattern: VALID_STRING_INPUT_REGEX,
								message: <span>{t('onlyAllowedSpecialCharacters')}</span>,
							},
						]}
					>
						<Input.TextArea showCount rows={4} />
					</Form.Item>
					<Form.Item
						label={t('addressLine1')}
						name="addressLine1"
						rules={[
							{
								required: true,
								message: <span>{t('required')}</span>,
							},
							{
								pattern: VALID_ADDRESS_REGEX,
								message: (
									<span>{t('onlyAllowedSpecialCharactersForAddress')}</span>
								),
							},
						]}
						hasFeedback
					>
						<Input maxLength={100} />
					</Form.Item>
					<Form.Item
						label={t('addressLine2')}
						name="addressLine2"
						rules={[
							{
								pattern: VALID_ADDRESS_REGEX,
								message: (
									<span>{t('onlyAllowedSpecialCharactersForAddress')}</span>
								),
							},
						]}
						hasFeedback
					>
						<Input maxLength={100} />
					</Form.Item>
					<Form.Item
						label={t('addressLine3')}
						name="addressLine3"
						rules={[
							{
								pattern: VALID_ADDRESS_REGEX,
								message: (
									<span>{t('onlyAllowedSpecialCharactersForAddress')}</span>
								),
							},
						]}
						hasFeedback
					>
						<Input maxLength={100} />
					</Form.Item>
					<Form.Item
						label={t('city')}
						name="city"
						rules={[
							{
								required: true,
								message: <span>{t('required')}</span>,
							},
							{
								pattern: VALID_CITY_REGEX,
								message: <span>{t('validCity')}</span>,
							},
						]}
						hasFeedback
					>
						<Input />
					</Form.Item>
					<Form.Item
						label={t('pincode')}
						name="pincode"
						rules={[
							{
								required: true,
								message: <span>{t('required')}</span>,
							},
							{
								pattern: VALID_PINCODE_REGEX,
								message: <span>{t('validPincode')}</span>,
							},
						]}
						hasFeedback
					>
						<Input maxLength={7} />
					</Form.Item>
				</>
				<>
					<SectionHeader
						heading={t('trainingCourse')}
						description={
							applicant
								? t('trainingCourseSubHeading')
								: t('trainingCourseSubHeading')
						}
					/>
					<Form.Item
						label={t('availabilityForTakingTheCourse')}
						name="availabilityForTakingTheCourse"
						hasFeedback
						rules={[
							{
								required: true,
								message: <span>{t('required')}</span>,
							},
						]}
					>
						<Select
							options={[
								{
									value: t('availableNow'),
									label: t('availableNow'),
								},
								{
									value: t('availableAfterThreeMonths'),
									label: t('availableAfterThreeMonths'),
								},
								{
									value: t('toughToBeAvailable'),
									label: t('toughToBeAvailable'),
								},
							]}
						/>
					</Form.Item>
					<Form.Item
						label={t('preferredTrainingLanguage')}
						name="preferredTrainingLanguage"
						hasFeedback
						rules={[
							{
								required: true,
								message: <span>{t('required')}</span>,
							},
						]}
					>
						<Select
							options={[
								{ value: 'English', label: 'English' },
								{ value: 'Hindi', label: 'Hindi' },
								{ value: 'Tamil', label: 'Tamil' },
								{ value: 'Telugu', label: 'Telugu' },
								{ value: 'Kannada', label: 'Kannada' },
								{ value: 'Malayalam', label: 'Malayalam' },
								{ value: 'Marathi', label: 'Marathi' },
							]}
						/>
					</Form.Item>
				</>
				<>
					<SectionHeader
						heading={t('preceptorContact')}
						description={
							applicant
								? t('provideCandidatePreceptorContact')
								: t('provideYourPreceptorContact')
						}
					/>
					<Form.Item
						label={t('preceptorName')}
						name="preceptorName"
						hasFeedback
						rules={[
							{
								required: true,
								message: <span>{t('required')}</span>,
							},
							{
								pattern: VALID_STRING_INPUT_REGEX,
								message: <span>{t('invalidName')}</span>,
							},
						]}
					>
						<Input maxLength={255} />
					</Form.Item>
					<Form.Item
						label={t('preceptorMobileNo')}
						name="preceptorMobile"
						hasFeedback
						rules={[
							{
								required: true,
								message: <span>{t('required')}</span>,
							},
							{
								pattern: /^([+]\d{2})?\d{10}$/,
								message: <span>{t('invalidPhoneNo')}</span>,
							},
						]}
					>
						<Input />
					</Form.Item>
				</>
				<>
					<SectionHeader
						heading={t('familyMemberContact')}
						description={
							applicant
								? t('provideCandidateFamilyMemberContact')
								: t('provideFamilyMemberContact')
						}
					/>
					<Form.Item
						label={t('familyMemberName')}
						name="familyMemberName"
						hasFeedback
						rules={[
							{
								required: true,
								message: <span>{t('required')}</span>,
							},
							{
								pattern: VALID_STRING_INPUT_REGEX,
								message: <span>{t('invalidName')}</span>,
							},
						]}
					>
						<Input maxLength={255} />
					</Form.Item>
					<Form.Item
						label={t('familyMemberMobileNo')}
						name="familyMemberMobile"
						hasFeedback
						rules={[
							{
								required: true,
								message: <span>{t('required')}</span>,
							},
							{
								pattern: /^([+]\d{2})?\d{10}$/,
								message: <span>{t('invalidPhoneNo')}</span>,
							},
						]}
					>
						<Input />
					</Form.Item>
					<Form.Item
						label={t('relationship')}
						name="familyMemberRelationship"
						hasFeedback
						rules={[
							{
								required: true,
								message: <span>{t('required')}</span>,
							},
						]}
					>
						<Select
							options={[
								{ value: t('spouse'), label: t('spouse') },
								{ value: t('parent'), label: t('parent') },
								{ value: t('other'), label: t('other') },
							]}
						/>
					</Form.Item>
					{familyMemberRelationship === t('other') && (
						<Form.Item
							label={t('relationshipOtherThanSpouseOrParent')}
							name="familyMemberRelationshipOtherThanSpouseOrParent"
							hasFeedback
							rules={[
								{
									required: true,
									message: <span>{t('required')}</span>,
								},
							]}
						>
							<Input maxLength={255} />
						</Form.Item>
					)}
					<Form.Item
						label={t('isTheFamilyMember')}
						name="familyMemberRole"
						rules={[
							{
								required: true,
								message: <span>{t('answerIsRequired')}</span>,
							},
						]}
					>
						<Radio.Group>
							<Radio value={PersonRole.AN_ABHYASI}>{t('anAbhyasi')}</Radio>
							<Radio value={PersonRole.A_PRECEPTOR}>{t('aPreceptor')}</Radio>
							<Radio value={PersonRole.NOT_YET_AN_ABHYASI}>
								{t('notYetAnAbhyasi')}
							</Radio>
						</Radio.Group>
					</Form.Item>
				</>
				<>
					<SectionHeader
						heading={t('otherReference')}
						description={t('provideContactOfAnyOtherPerson')}
					/>

					<Form.Item name="otherReferenceProvided" valuePropName="checked">
						<Checkbox>
							{applicant
								? t('candidateWantToProvideContactOfAnotherPersonForReference')
								: t('iWantToProvideContactOfAnotherPersonForReference')}
						</Checkbox>
					</Form.Item>
					<div className={otherReferenceProvided ? 'block' : 'hidden'}>
						<Form.Item
							label={t('otherReferenceName')}
							name="otherReferenceName"
							hasFeedback
							rules={[
								{
									required: otherReferenceProvided,
									message: <span>{t('required')}</span>,
								},
								{
									pattern: VALID_STRING_INPUT_REGEX,
									message: <span>{t('invalidName')}</span>,
								},
							]}
						>
							<Input disabled={!otherReferenceProvided} maxLength={255} />
						</Form.Item>
						<Form.Item
							label={t('otherReferenceMobile')}
							name="otherReferenceMobile"
							hasFeedback
							rules={[
								{
									required: otherReferenceProvided,
									message: <span>{t('required')}</span>,
								},
								{
									pattern: /^([+]\d{2})?\d{10}$/,
									message: <span>{t('invalidPhoneNo')}</span>,
								},
							]}
						>
							<Input disabled={!otherReferenceProvided} />
						</Form.Item>
						<Form.Item
							label={t('isThePerson')}
							name="otherReferenceRole"
							rules={[
								{
									required: otherReferenceProvided,
									message: <span>{t('answerIsRequired')}</span>,
								},
							]}
						>
							<Radio.Group>
								<Radio value={PersonRole.AN_ABHYASI}>{t('anAbhyasi')}</Radio>
								<Radio value={PersonRole.A_PRECEPTOR}>{t('aPreceptor')}</Radio>
								<Radio value={PersonRole.NOT_YET_AN_ABHYASI}>
									{t('notYetAnAbhyasi')}
								</Radio>
							</Radio.Group>
						</Form.Item>
					</div>
				</>
			</Form>
		</div>
	);
};
