import React, { useState } from 'react';
import { Modal, Button, Input, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';

const { Text } = Typography;

interface ModalPopUpProps<T = void> {
	visible: boolean; // Controls modal visibility
	fieldType?: string;
	onClose: () => void; // Callback when the modal is closed
	onYes: (otp?: string) => Promise<T> | void; // Callback for Yes action
	title?: string | undefined; // Optional title for the modal
	content?: React.ReactNode; // Optional content for the modal
	width?: number; // Optional width for the modal
	icon: string;
	okText?: string;
	cancelText?: string;
	footerContent?: React.ReactNode;
	okButtonLoading?: boolean;
	inputError?: string;
}

export const InputPopUp: React.FC<ModalPopUpProps> = ({
	visible,
	onClose,
	onYes,
	title = '',
	content,
	icon,
	okText = 'ok',
	cancelText = 'cancel',
	fieldType,
	footerContent,
	okButtonLoading,
	inputError,
}) => {
	const { t } = useTranslation();
	const [otp, setOtp] = useState<string>(''); // State for OTP input
	const [errorMessage, setErrorMessage] = useState<string | undefined>();

	const handleYes = async () => {
		if (fieldType === 'OTP' && otp.trim() === '') {
			// Add validation if OTP is required
			setErrorMessage(t('required') || '');
			return;
		}
		await onYes(otp);
	};

	return (
		<Modal
			open={visible}
			onCancel={onClose}
			footer={null}
			centered
			className="max-w-sm"
			closable={false} // Disables the close icon
			confirmLoading={okButtonLoading}
			maskClosable={false}
		>
			{title && (
				<h3 className="text-center font-sans font-bold text-lg mt-0">
					{t(title)}
				</h3>
			)}
			<div className="flex justify-center">
				<img src={icon} className="mx-auto my-0" />
			</div>
			{content && <div className="text-center my-5">{content}</div>}
			{/* OTP Input if fieldType is 'OTP' */}
			{fieldType && fieldType === 'OTP' && (
				<div className="flex flex-col text-center mb-8 mt-4 w-full">
					<Input.OTP
						length={6} // Set the length of the OTP input
						onChange={(value) => setOtp(value)} // Update state on change
						value={otp} // Bind the value to the state
						className="otp-input space-x-2"
					/>
					{errorMessage || inputError ? (
						<Text type="danger" className="text-sm font-bold font-sans my-2">
							{errorMessage || inputError}
						</Text>
					) : null}
				</div>
			)}
			{footerContent && <div className="text-center my-5">{footerContent}</div>}
			<div className="flex justify-center mt-4 ant-modal-footer">
				<Button
					onClick={() => {
						Modal.destroyAll();
						onClose();
					}}
					className="flex-1 font-sans h-9 mr-2 text-primary-blue font-bold"
				>
					{t(cancelText)}
				</Button>
				<Button
					type="primary"
					className="flex-1 font-sans h-9 bg-primary-blue text-white hover:bg-primary-blue/80 font-bold"
					disabled={isEmpty(otp)}
					onClick={() => {
						void (async () => {
							await handleYes();
						})();
					}}
				>
					{t(okText)}
				</Button>
			</div>
		</Modal>
	);
};
