import { CategoryStep } from './CategoryStep';
import { useNewApplication } from './useNewApplication';
import useCategoryFormHandling from './useCategoryFormHandling';
import { AvailabilityAfterProgramUIConfig } from '../../../shared/CategoryStepUIConfig';

const UiConfig = AvailabilityAfterProgramUIConfig;

export const AvailabilityAfterProgramStep = () => {
	const {
		availabilityAfterProgramCategoryAnswers,
		setAvailabilityAfterProgramCategoryAnswers,
	} = useNewApplication();

	const { form, updateFieldValue } = useCategoryFormHandling(
		availabilityAfterProgramCategoryAnswers,
		setAvailabilityAfterProgramCategoryAnswers
	);

	return (
		<CategoryStep
			uiConfig={UiConfig}
			form={form}
			updateFieldValue={updateFieldValue}
		/>
	);
};
