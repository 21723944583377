import { useState, useCallback, useMemo } from 'react';
import { WizardContainer } from '../../../components/wizard/Wizard';
import { HeartfulnessProfileStepContainer } from './HeartfulnessProfileStep';
import { ApplicantAdditionalDetailsStep } from './ApplicantAdditionalDetailsStep';
import { InspirationAndWillingnessStep } from './InspirationAndWillingnessStep';
import { PracticeStep } from './PracticeStep';
import { CharacterAndHabitsStep } from './CharacterAndHabitsStep';
import { AvailabilityAfterProgramStep } from './AvailabilityAfterProgramStep';
import { SupportStep } from './SupportStep';
import { UnderstandingOfHeartfulnessStep } from './UnderstandingOfHeartfulnessStep';
import { TermsAndConditionStepContainer } from './TermsAndConditionStep';
import { WizardProvider } from '../../../components/wizard/useWizard';
import { NewApplicationProvider, useNewApplication } from './useNewApplication';
import { EmailVerificationModal } from './EmailVerificationModal';
import { AboutTheProcessContainer } from './AboutTheProcessStep';
import { BasicEligibilityStep } from './BasicEligibilityStep';
import { CategoryAnswerType } from './CategoryAnswerType';
import { Button, Modal, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { CardIndex, EssentailCardIndex } from '../../../shared/Constants';
import { DietaryHabitsAndHealthStep } from './DietaryHabitsAndHealthStep';
import { TrainingCourseDetailsStep } from './TrainingCourseDetailsStep';
import { PreceptorContactDetailsStep } from './PreceptorContactDetailsStep';
import { FamilyMemberDetailsStep } from './FamilyMemberDetailsStep';
import { OtherReferenceDetailsStep } from './OtherReferenceDetailsStep';
import Icon from '../../../components/ui-components/Icon';
import ContactInfo from '../../../assets/images/contact-info.svg';
import { useInfoPopup } from '../../../hooks/useInfoPopUp';
import { myHFNProfileURL } from '../../../service/EnvService';

const { Link } = Typography;

export const NewApplicationWizard = () => {
	const { t } = useTranslation();
	const {
		onSubmitClick,
		mySrcmProfile,
		isEmailVerified,
		basicEligibilityCategoryAnswers,
	} = useNewApplication();
	const [modalKey, setModalKey] = useState(0);
	const [openEmailVerificationModal, setOpenEmailVerificationModal] =
		useState(false);
	const [resolveFunction, setResolveFunction] = useState<
		((value: boolean) => void) | null
	>(null);
	const { infoPopUpAction } = useInfoPopup();
	const [currentWizard, setCurrentWizard] = useState<number>(0); // Type currentWizard as a number

	const wizards = useMemo(
		() => [
			{
				steps: [
					<HeartfulnessProfileStepContainer key={1} />,
					<ApplicantAdditionalDetailsStep key={2} />,
					<TrainingCourseDetailsStep key={3} />,
					<PreceptorContactDetailsStep key={4} />,
					<FamilyMemberDetailsStep key={5} />,
					<OtherReferenceDetailsStep key={6} />,
					<AboutTheProcessContainer key={7} />,
				],
				descriptions: [
					{
						title: t('heartfulnessProfile'),
						description: t('detailsWillBeFetchedFromHeartfulnessProfile') ?? '',
					},
					{
						title: t('additionalDetails'),
						description: t('provideTheFollowingDetailsToHelp') ?? '',
					},
					{
						title: t('trainingCourse'),
						description:
							t('thisProcessTakes3To4MonthsRequiring5HoursOfEffortWeekly') ??
							'',
					},
					{
						title: t('preceptorContact'),
						description: t('detailsWillBeFetchedFromHeartfulnessProfile') ?? '',
					},
					{ title: t('familyMemberContact'), description: '' },
					{
						title: t('otherReference'),
						description: t('provideContactOfAnyOtherPerson') ?? '',
					},
					{ title: t('aboutTheProcess') },
				],
			},
			{
				steps: [
					<BasicEligibilityStep key={8} />,
					<InspirationAndWillingnessStep key={9} />,
					<PracticeStep key={10} />,
					<CharacterAndHabitsStep key={11} />,
					<AvailabilityAfterProgramStep key={12} />,
					<SupportStep key={13} />,
					<UnderstandingOfHeartfulnessStep key={14} />,
					<DietaryHabitsAndHealthStep key={15} />,
					<TermsAndConditionStepContainer key={16} />,
				],
				descriptions: [
					{
						title: t('essentials'),
						description: t('thisIsMandatoryCriteria') ?? '',
					},
					{
						title: t('inspirationAndWillingness'),
						description: t('primaryQualificationForATrainer') ?? '',
					},
					{
						title: t('practice'),
						description: t('trainerNeedsToBeIntensePractitioner') ?? '',
					},
					{
						title: t('characterAndHabits'),
						description: t('expectedToBeRoleModel') ?? '',
					},
					{
						title: t('availabilityAfterProgram'),
						description: t('exploreIfWillingnessTranslatesToAction') ?? '',
					},
					{
						title: t('familySupport'),
						description: t('exploreIfConditionsAroundAreConducive') ?? '',
					},
					{
						title: t('understandingHeartfulness'),
						description: t('abilityToExplainHeartfulness') ?? '',
					},
					{
						title: t('dietaryHabitsAndHealth'),
						description: t('expectedToBeRoleModel') ?? '',
					},
					{ title: t('termsAndConditions') },
				],
			},
		],
		[t]
	);

	const [stepStates, setStepStates] = useState<number[]>(
		new Array(wizards.length).fill(0) // Initialize for all wizards
	);

	const closeEmailVerificationModal = useCallback(() => {
		setOpenEmailVerificationModal(false);
		if (resolveFunction) {
			resolveFunction(false);
		}
	}, [resolveFunction]);

	const hasAnswerOtherThanYes = useCallback(
		(categoryAnswers: CategoryAnswerType) => {
			return Object.values(categoryAnswers).some(
				(item: { selectedOption: number }) => item.selectedOption !== 1
			);
		},
		[]
	);

	const VerifyContactContent = useCallback(() => {
		return (
			<div className="flex flex-col font-sans font-normal text-sm">
				<div className="flex flex-col items-center">
					<div className="w-24 h-20 my-4">
						<Icon src={ContactInfo} className="w-full h-full" />
					</div>
					<div>{t('areYouSureToProceed')}</div>
					<div>
						{t('ourTeamWillReachYou')} <b>{mySrcmProfile?.mobile}</b>
					</div>
				</div>
				<div className="mt-4">
					{t('incorrectPhoneNumber')}{' '}
					<Link
						href={myHFNProfileURL}
						target="_blank"
						className="px-1 color-black text-sm font-normal font-sans text-primary-blue"
						underline
					>
						{t('editYourNumber')}
					</Link>
				</div>
			</div>
		);
	}, [mySrcmProfile?.mobile, t]);

	const VerifyContactFooter = useCallback(
		(resolve: (value: boolean | PromiseLike<boolean>) => void) => {
			return (
				<div className="flex w-full gap-4 mt-4">
					<Button
						type="default"
						className="flex-1 h-9 px-4 py-2 font-sans font-bold text-sm border border-primary-blue rounded-lg text-primary-blue"
						onClick={() => Modal.destroyAll()}
					>
						{t('deny')}
					</Button>
					<Button
						type="default"
						className="flex-1 h-9 px-4 py-2 font-sans font-bold text-sm bg-primary-blue text-white rounded-lg"
						onClick={() => {
							Modal.destroyAll();
							setModalKey((prevKey) => prevKey + 1);
							setOpenEmailVerificationModal(true);
							setResolveFunction(() => resolve);
						}}
					>
						{t('agree')}
					</Button>
				</div>
			);
		},
		[t]
	);

	const showModal = useCallback(
		(cardIndex: number): Promise<boolean> => {
			if (cardIndex === CardIndex.HEARTFULNESS_PROFILE && !isEmailVerified) {
				return new Promise((resolve) => {
					Modal.confirm({
						className: 'max-w-md',
						centered: true,
						okText: t('agree'),
						cancelText: t('deny'),
						icon: null,
						title: (
							<div className="text-center font-sans font-bold text-lg">
								{t('verifyContactInfo')}
							</div>
						),
						content: VerifyContactContent(),
						footer: VerifyContactFooter(resolve),
					});
				});
			} else if (
				cardIndex === EssentailCardIndex.BASIC_ELIGIBILITY &&
				currentWizard > 0
			) {
				const blockTheUserFromProceddingAhead = hasAnswerOtherThanYes(
					basicEligibilityCategoryAnswers
				);
				if (blockTheUserFromProceddingAhead) {
					return new Promise(() => {
						infoPopUpAction(
							'basicEligibilityCriteriaNotMet',
							'notMeetingBasicEligibilityCriteria',
							() => {}
						);
					});
				}
			}
			return Promise.resolve(true);
		},
		[
			infoPopUpAction,
			basicEligibilityCategoryAnswers,
			hasAnswerOtherThanYes,
			isEmailVerified,
			t,
			VerifyContactContent,
			VerifyContactFooter,
			currentWizard,
		]
	);

	const onSubmitWizard = useCallback(() => {
		setCurrentWizard((prevWizard) => {
			const newWizard = prevWizard + 1;
			window.scrollTo(0, 0); // Scroll to the top when moving forward
			setStepStates((prevStates) => {
				const updatedStates = [...prevStates];

				if (updatedStates[newWizard] === undefined) {
					updatedStates[newWizard] = 0;
				} else {
					updatedStates[newWizard] = 0; // Reset if already set
				}

				return updatedStates;
			});

			return newWizard;
		});
	}, []);

	const onPreviousWizard = useCallback(() => {
		setCurrentWizard((prevWizard) => {
			const newWizard = prevWizard - 1;
			window.scrollTo(0, 0);

			setStepStates((prevStates) => {
				const updatedStates = [...prevStates];
				updatedStates[newWizard] = wizards[newWizard].steps.length - 1; // Set to last step of Wizard 1
				return updatedStates;
			});

			return newWizard;
		});
	}, [wizards]);

	const steps = wizards[currentWizard].steps;
	const descriptions = wizards[currentWizard].descriptions;
	const stepOffset = 0; // Adjust offset based on the current wizard
	const totalStepsInPreviousWizard =
		currentWizard > 0 ? wizards[currentWizard - 1].steps.length : 0;
	return (
		<div className="flex flex-col h-full min-h-0 p-[8px]">
			<WizardContainer
				onSubmitClick={() => {
					if (currentWizard === wizards.length - 1) {
						onSubmitClick(); // Call the final submit function
					} else {
						onSubmitWizard(); // Move to the next wizard
					}
				}}
				onNextClick={showModal}
				steps={steps}
				stepDescription={descriptions}
				stepOffset={stepOffset}
				initialStepState={stepStates[currentWizard] || 0} // Pass the reset step state
				onStepStateChange={(newState: number) => {
					setStepStates((prev) => {
						const updated = [...prev];
						updated[currentWizard] = newState;
						return updated;
					});
				}}
				currentWizard={currentWizard}
				onPreviousClick={onPreviousWizard}
				totalStepsInPreviousWizard={totalStepsInPreviousWizard}
			/>
			<EmailVerificationModal
				key={modalKey}
				openModal={openEmailVerificationModal}
				onCancel={closeEmailVerificationModal}
				email={mySrcmProfile.email}
				mobile={mySrcmProfile?.mobile}
				onEmailVerificationComplete={resolveFunction}
			/>
		</div>
	);
};

export const NewApplicationWizardContainer = () => {
	return (
		<NewApplicationProvider>
			<WizardProvider>
				<NewApplicationWizard />
			</WizardProvider>
		</NewApplicationProvider>
	);
};
