import { Typography } from 'antd';

const { Text } = Typography;

export const StepHeader = ({
	heading,
	description,
}: {
	heading: string;
	description: string;
}) => {
	return (
		<div className="hidden md:flex flex-col mb-4">
			<Text className="font-sans font-bold text-lg text-primary-blue mb-1">
				{heading}
			</Text>
			<Text
				className="mb-[8px] font-sans font-normal text-sm text-secondary-gray"
				type="secondary"
			>
				{description}
			</Text>
		</div>
	);
};
