import { useCallback, useEffect, useState } from 'react';
import { useCallApi } from '../../../hooks/useCallApi';
import { getPreceptorByPartnerId } from '../../../service/VolunteerApplicationService';
import { useAuth } from '../../../hooks/useAuth';
import { Preceptor } from '../../../shared/VolunteerApplicationServiceTypes';

export const usePreceptorContactDetails = () => {
	const { mySrcmProfile } = useAuth();
	const { callApi } = useCallApi();
	const [preceptorDetails, setPreceptorDetails] = useState<Preceptor>();

	const fetchPreceptorByPartnerId = useCallback(async () => {
		const firebaseId = mySrcmProfile?.user_firebase_uid;
		const partnerId = mySrcmProfile?.resp_prefect?.id;
		if (firebaseId && partnerId) {
			const response = await callApi(
				() =>
					getPreceptorByPartnerId({
						firebaseId,
						id: partnerId,
					}),
				'errorWhileFetchingPreceptorContact'
			);
			setPreceptorDetails(response);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mySrcmProfile?.user_firebase_uid, mySrcmProfile?.resp_prefect?.id]);

	useEffect(() => {
		void fetchPreceptorByPartnerId();
	}, [fetchPreceptorByPartnerId]);

	return { preceptorDetails };
};
