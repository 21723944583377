import { useCallback, useEffect, useRef } from 'react';
import { getMeProfile } from '../../service/MySrcmService';
import { useAuth } from '../../hooks/useAuth';
import { getRole } from '../../service/VolunteerApplicationService';
import { useBatchId } from '../../hooks/useBatchId';
import { UserRole } from '../../shared/VolunteerApplicationServiceTypes';
import { ROOT } from '../../shared/Routes';
import useUserSession from '../../hooks/useUserSession';

interface LoginResponse {
	data?: {
		access_token?: string;
	};
}

export const OneAuth = () => {
	const { setMySrcmProfile, setFirebaseId, setRole, setInitiated } = useAuth();
	const {
		setKeycloakToken,
		setMeProfile,
		setSrcmFirebaseId,
		setLoggedInUserRole,
		setAppInitiated,
	} = useUserSession();
	const { batchId, navigateWithBatchId, queryParams } = useBatchId();
	const apiCallCount = useRef(0);

	const handleAuth = (status: boolean) => {
		const element = document.querySelector('hfn-oneauth') as HTMLElement & {
			handleProfileAuthentication?: (_status: boolean) => void;
		};
		if (element?.handleProfileAuthentication) {
			element.handleProfileAuthentication(status);
		}
	};

	const getProfile = useCallback(async () => {
		if (++apiCallCount.current >= 4) return handleAuth(false);

		try {
			const meProfile = await getMeProfile();
			const profile = meProfile?.results?.[0];

			if (!profile) {
				// TODO: Handle Unauthorized page here
				return;
			}

			setMeProfile(profile);
			setMySrcmProfile?.(profile);

			if (profile.user_firebase_uid) {
				setSrcmFirebaseId(profile.user_firebase_uid);
				setFirebaseId?.(profile.user_firebase_uid);
			}

			if (profile.ref && profile.user_firebase_uid) {
				const roleResponse = await getRole({
					firebaseId: profile.user_firebase_uid,
					batchID: batchId,
					srcmId: profile.ref,
				});
				const userRole = roleResponse.role || UserRole.APPLICANT;
				setLoggedInUserRole(userRole);
				setRole?.(userRole);
			} else {
				setRole?.(UserRole.APPLICANT);
			}

			setAppInitiated(true);
			setInitiated?.(true);
			handleAuth(true);
			['state', 'session_state', 'iss', 'code'].forEach((param) =>
				queryParams.delete(param)
			);
			navigateWithBatchId(ROOT);
		} catch {
			handleAuth(false);
		}
	}, [
		batchId,
		navigateWithBatchId,
		setMySrcmProfile,
		setFirebaseId,
		setRole,
		setInitiated,
		setMeProfile,
		setAppInitiated,
		setLoggedInUserRole,
		setSrcmFirebaseId,
		queryParams,
	]);

	useEffect(() => {
		const loadOneAuth = async () => {
			try {
				await import('hfn-oneauth/main');
				const element = document.querySelector('hfn-oneauth') as HTMLElement & {
					loginCallback?: (_res: LoginResponse) => Promise<boolean>;
				};

				if (!element) return;

				element.loginCallback = async (res: LoginResponse) => {
					const accessToken = res?.data?.access_token;
					if (!accessToken) {
						handleAuth(false);
						return false;
					}

					setKeycloakToken(accessToken);
					await getProfile();
					return true;
				};
			} catch {
				// Handle import error silently
			}
		};

		void loadOneAuth();
	}, [getProfile, setKeycloakToken]);

	return <></>;
};
