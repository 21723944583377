import {
	createContext,
	useContext,
	useMemo,
	FC,
	ReactNode,
	useState,
	useCallback,
} from 'react';
import { MeProfile } from '../../../shared/MySrcm';
import { ApplicantAdditionalDetailsType } from '../../../components/AdditionalDetailsType';
import { CategoryAnswerType } from './CategoryAnswerType';
import { TermsAndConditions } from './TermsAndConditionsType';
import { submitNewApplication } from '../../../service/VolunteerApplicationService';
import { useBatchId } from '../../../hooks/useBatchId';
import {
	transformAdditionalDetailsToGRPC,
	transformAnswerToGRPC,
	transformMySrcmProfileToGRPCUser,
} from '../../../shared/Utils';
import { Answer } from '../../../shared/VolunteerApplicationServiceTypes';
import { EvaluationCategoryID } from '../../../shared/Constants';
import { useCallApi } from '../../../hooks/useCallApi';
import { AboutTheProcessType } from './AboutTheProcessType';
import { TrainingCourseDetailsType } from '../../../components/TrainingCourseDetailsType';
import { PreceptorContactDetailsType } from '../../../components/PreceptorContactDetailsType';
import { FamilyMemberDetailsType } from '../../../components/FamilyMemberDetailsType';
import { OtherReferenceDetailsType } from '../../../components/OtherReferenceDetailsType';
import { useConfirmPopup } from '../../../hooks/useConfirmPopup';
import { useInfoPopup } from '../../../hooks/useInfoPopUp';
import ConfirmTick from '../../../assets/images/confirm-tick.svg';
import { useTranslation } from 'react-i18next';

export interface NewApplicationContextType {
	mySrcmProfile: MeProfile;
	setMySrcmProfile: (mySrcmProfile: MeProfile) => void;
	additionalApplicantDetails: ApplicantAdditionalDetailsType;
	setAdditionalApplicantDetails: (
		additionalDetails: ApplicantAdditionalDetailsType
	) => void;
	trainingCourseDetails: TrainingCourseDetailsType;
	setTrainingCourseDetails: (
		trainingDetails: TrainingCourseDetailsType
	) => void;
	preceptorContactDetails: PreceptorContactDetailsType;
	setPreceptorContactDetails: (
		preceptorDetails: PreceptorContactDetailsType
	) => void;
	familyMemberContactDetails: FamilyMemberDetailsType;
	setFamilyMemberContactDetails: (
		familyMemberDetails: FamilyMemberDetailsType
	) => void;
	otherReferenceContactDetails: OtherReferenceDetailsType;
	setOtherReferenceContactDetails: (
		otherReferenceDetails: OtherReferenceDetailsType
	) => void;
	basicEligibilityCategoryAnswers: CategoryAnswerType;
	setBasicEligibilityCategoryAnswers: (answers: CategoryAnswerType) => void;
	inspirationAndWillingnessCategoryAnswers: CategoryAnswerType;
	setInspirationAndWillingnessCategoryAnswers: (
		answers: CategoryAnswerType
	) => void;
	practiceCategoryAnswers: CategoryAnswerType;
	setPracticeCategoryAnswers: (answers: CategoryAnswerType) => void;
	characterAndHabitsCategoryAnswers: CategoryAnswerType;
	setCharacterAndHabitsCategoryAnswers: (answers: CategoryAnswerType) => void;
	availabilityAfterProgramCategoryAnswers: CategoryAnswerType;
	setAvailabilityAfterProgramCategoryAnswers: (
		answers: CategoryAnswerType
	) => void;
	supportCategoryAnswers: CategoryAnswerType;
	setSupportCategoryAnswers: (answers: CategoryAnswerType) => void;
	understandingOfHeartfulnessCategoryAnswers: CategoryAnswerType;
	setUnderstandingOfHeartfulnessCategoryAnswers: (
		answers: CategoryAnswerType
	) => void;
	dietaryHabitsAndHealthCategoryAnswers: CategoryAnswerType;
	setDietaryHabitsAndHealthCategoryAnswers: (
		answers: CategoryAnswerType
	) => void;
	termsAndConditions: TermsAndConditions;
	setTermsAndConditions: (tnc: TermsAndConditions) => void;
	welcomeAndSelfConsciousness: AboutTheProcessType;
	setAboutTheProcess: (wsc: AboutTheProcessType) => void;
	onSubmitClick: () => void;
	isEmailVerified: boolean;
	setIsEmailVerified: (isEmailVerified: boolean) => void;
}

interface Props {
	children: ReactNode;
}

const NewApplicationContext = createContext<NewApplicationContextType>({
	mySrcmProfile: {},
	setMySrcmProfile: () => {},
	additionalApplicantDetails: {},
	setAdditionalApplicantDetails: () => {},
	trainingCourseDetails: {},
	setTrainingCourseDetails: () => {},
	preceptorContactDetails: {},
	setPreceptorContactDetails: () => {},
	familyMemberContactDetails: {},
	setFamilyMemberContactDetails: () => {},
	otherReferenceContactDetails: {},
	setOtherReferenceContactDetails: () => {},
	basicEligibilityCategoryAnswers: {},
	setBasicEligibilityCategoryAnswers: () => {},
	inspirationAndWillingnessCategoryAnswers: {},
	setInspirationAndWillingnessCategoryAnswers: () => {},
	practiceCategoryAnswers: {},
	setPracticeCategoryAnswers: () => {},
	characterAndHabitsCategoryAnswers: {},
	setCharacterAndHabitsCategoryAnswers: () => {},
	availabilityAfterProgramCategoryAnswers: {},
	setAvailabilityAfterProgramCategoryAnswers: () => {},
	supportCategoryAnswers: {},
	setSupportCategoryAnswers: () => {},
	understandingOfHeartfulnessCategoryAnswers: {},
	setUnderstandingOfHeartfulnessCategoryAnswers: () => {},
	dietaryHabitsAndHealthCategoryAnswers: {},
	setDietaryHabitsAndHealthCategoryAnswers: () => {},
	termsAndConditions: {},
	setTermsAndConditions: () => {},
	welcomeAndSelfConsciousness: {},
	setAboutTheProcess: () => {},
	onSubmitClick: () => {},
	isEmailVerified: false,
	setIsEmailVerified: () => {},
});

export const NewApplicationProvider: FC<Props> = ({ children }: Props) => {
	const [mySrcmProfile, setMySrcmProfile] = useState<MeProfile>({});
	const [additionalApplicantDetails, setAdditionalApplicantDetails] =
		useState<ApplicantAdditionalDetailsType>({});
	const [trainingCourseDetails, setTrainingCourseDetails] =
		useState<TrainingCourseDetailsType>({});
	const [preceptorContactDetails, setPreceptorContactDetails] =
		useState<PreceptorContactDetailsType>({});
	const [familyMemberContactDetails, setFamilyMemberContactDetails] =
		useState<FamilyMemberDetailsType>({});
	const [otherReferenceContactDetails, setOtherReferenceContactDetails] =
		useState<OtherReferenceDetailsType>({});
	const [basicEligibilityCategoryAnswers, setBasicEligibilityCategoryAnswers] =
		useState<CategoryAnswerType>({});
	const [
		inspirationAndWillingnessCategoryAnswers,
		setInspirationAndWillingnessCategoryAnswers,
	] = useState<CategoryAnswerType>({});
	const [practiceCategoryAnswers, setPracticeCategoryAnswers] =
		useState<CategoryAnswerType>({});
	const [
		characterAndHabitsCategoryAnswers,
		setCharacterAndHabitsCategoryAnswers,
	] = useState<CategoryAnswerType>({});
	const [
		availabilityAfterProgramCategoryAnswers,
		setAvailabilityAfterProgramCategoryAnswers,
	] = useState<CategoryAnswerType>({});
	const [supportCategoryAnswers, setSupportCategoryAnswers] =
		useState<CategoryAnswerType>({});
	const [
		understandingOfHeartfulnessCategoryAnswers,
		setUnderstandingOfHeartfulnessCategoryAnswers,
	] = useState<CategoryAnswerType>({});
	const [
		dietaryHabitsAndHealthCategoryAnswers,
		setDietaryHabitsAndHealthCategoryAnswers,
	] = useState<CategoryAnswerType>({});
	const [termsAndConditions, setTermsAndConditions] =
		useState<TermsAndConditions>({});
	const [welcomeAndSelfConsciousness, setAboutTheProcess] =
		useState<AboutTheProcessType>({});
	const { t, i18n } = useTranslation();
	const { batchId } = useBatchId();
	const { confirmPopUpAction } = useConfirmPopup();
	const { infoPopUpAction } = useInfoPopup();
	const { callApi } = useCallApi();
	const [isEmailVerified, setIsEmailVerified] = useState(false);

	const onConfirmSubmit = useCallback(async () => {
		const answers: Array<Answer> = [];
		answers.push(
			...transformAnswerToGRPC(
				EvaluationCategoryID.BASIC_ELIGIBILITY,
				basicEligibilityCategoryAnswers
			)
		);
		answers.push(
			...transformAnswerToGRPC(
				EvaluationCategoryID.INSPIRATION_AND_WILLINGNESS,
				inspirationAndWillingnessCategoryAnswers
			)
		);
		answers.push(
			...transformAnswerToGRPC(
				EvaluationCategoryID.PRACTICE,
				practiceCategoryAnswers
			)
		);
		answers.push(
			...transformAnswerToGRPC(
				EvaluationCategoryID.CHARACTER_AND_HABITS,
				characterAndHabitsCategoryAnswers
			)
		);
		answers.push(
			...transformAnswerToGRPC(
				EvaluationCategoryID.AVAILABILITY_AFTER_PROGRAM,
				availabilityAfterProgramCategoryAnswers
			)
		);
		answers.push(
			...transformAnswerToGRPC(
				EvaluationCategoryID.SUPPORT,
				supportCategoryAnswers
			)
		);
		answers.push(
			...transformAnswerToGRPC(
				EvaluationCategoryID.UNDERSTANDING_OF_HEARTFULNESS,
				understandingOfHeartfulnessCategoryAnswers
			)
		);
		answers.push(
			...transformAnswerToGRPC(
				EvaluationCategoryID.DIETARY_HABITS_AND_HEALTH,
				dietaryHabitsAndHealthCategoryAnswers
			)
		);

		const applicantDetails: ApplicantAdditionalDetailsType = {
			...additionalApplicantDetails,
			center: mySrcmProfile.srcm_group,
		};

		const request = {
			userDetails: transformMySrcmProfileToGRPCUser(mySrcmProfile),
			additionalDetails: transformAdditionalDetailsToGRPC(
				applicantDetails,
				trainingCourseDetails,
				preceptorContactDetails,
				familyMemberContactDetails,
				otherReferenceContactDetails
			),
			userAnswers: {
				questionsAndAnswers: answers,
				language: i18n.language,
			},
			batchId,
		};

		try {
			await callApi(
				() => submitNewApplication(request),
				'errorOccurredWhileSubmittingApplication'
			);
			infoPopUpAction(
				'submittedSuccessfully',
				'submittedApplicationSuccessfully',
				() => {
					window.location.reload();
				},
				ConfirmTick
			);
		} catch (e) {
			// handled in callApi
		}
	}, [
		additionalApplicantDetails,
		trainingCourseDetails,
		preceptorContactDetails,
		familyMemberContactDetails,
		otherReferenceContactDetails,
		availabilityAfterProgramCategoryAnswers,
		basicEligibilityCategoryAnswers,
		batchId,
		callApi,
		characterAndHabitsCategoryAnswers,
		dietaryHabitsAndHealthCategoryAnswers,
		i18n.language,
		inspirationAndWillingnessCategoryAnswers,
		mySrcmProfile,
		practiceCategoryAnswers,
		supportCategoryAnswers,
		understandingOfHeartfulnessCategoryAnswers,
		infoPopUpAction,
	]);

	const onSubmitClick = useCallback(() => {
		const categoryData = [
			{ key: 'additionalApplicantDetails', data: additionalApplicantDetails },
			{ key: 'trainingCourseDetails', data: trainingCourseDetails },
			{ key: 'preceptorContactDetails', data: preceptorContactDetails },
			{ key: 'familyMemberContactDetails', data: familyMemberContactDetails },
			{
				key: 'otherReferenceContactDetails',
				data: otherReferenceContactDetails,
			},
			{
				key: 'availabilityAfterProgramCategoryAnswers',
				data: availabilityAfterProgramCategoryAnswers,
			},
			{
				key: 'basicEligibilityCategoryAnswers',
				data: basicEligibilityCategoryAnswers,
			},
			{
				key: 'inspirationAndWillingnessCategoryAnswers',
				data: inspirationAndWillingnessCategoryAnswers,
			},
			{ key: 'practiceCategoryAnswers', data: practiceCategoryAnswers },
			{
				key: 'characterAndHabitsCategoryAnswers',
				data: characterAndHabitsCategoryAnswers,
			},
			{ key: 'supportCategoryAnswers', data: supportCategoryAnswers },
			{
				key: 'understandingOfHeartfulnessCategoryAnswers',
				data: understandingOfHeartfulnessCategoryAnswers,
			},
			{
				key: 'dietaryHabitsAndHealthCategoryAnswers',
				data: dietaryHabitsAndHealthCategoryAnswers,
			},
		];

		const emptyCategories = categoryData
			.filter(({ key, data }) => {
				if (!data || Object.keys(data).length === 0) return true;
				if (key === 'otherReferenceContactDetails') {
					const otherReferenceData = data as OtherReferenceDetailsType;
					if (!otherReferenceData.otherReferenceProvided) return false;
				}
				return Object.values(data).every((value) => {
					if (value == null || value === '') return true;
					if (typeof value === 'object' && value !== null) {
						return Object.values(value as object).every(
							(innerValue) => innerValue == null || innerValue === ''
						);
					}
					return false;
				});
			})
			.map(({ key }) => t(key)); // Now use the key for translation

		if (emptyCategories.length === 0) {
			confirmPopUpAction(
				'confirmSubmit',
				'applicationOnceSubmittedCanNotBeChanged',
				onConfirmSubmit
			);
		} else {
			infoPopUpAction(
				'incompleteFormSubmission',
				`${t('pleaseAnswerAllRequiredQuestions')} ${emptyCategories.join(
					', '
				)}`,
				() => {}
			);
		}
	}, [
		confirmPopUpAction,
		infoPopUpAction,
		onConfirmSubmit,
		additionalApplicantDetails,
		trainingCourseDetails,
		preceptorContactDetails,
		familyMemberContactDetails,
		otherReferenceContactDetails,
		basicEligibilityCategoryAnswers,
		inspirationAndWillingnessCategoryAnswers,
		practiceCategoryAnswers,
		characterAndHabitsCategoryAnswers,
		availabilityAfterProgramCategoryAnswers,
		supportCategoryAnswers,
		understandingOfHeartfulnessCategoryAnswers,
		dietaryHabitsAndHealthCategoryAnswers,
		t,
	]);

	const value = useMemo<NewApplicationContextType>(() => {
		return {
			mySrcmProfile,
			setMySrcmProfile,
			additionalApplicantDetails,
			setAdditionalApplicantDetails,
			trainingCourseDetails,
			setTrainingCourseDetails,
			preceptorContactDetails,
			setPreceptorContactDetails,
			familyMemberContactDetails,
			setFamilyMemberContactDetails,
			otherReferenceContactDetails,
			setOtherReferenceContactDetails,
			basicEligibilityCategoryAnswers,
			setBasicEligibilityCategoryAnswers,
			inspirationAndWillingnessCategoryAnswers,
			setInspirationAndWillingnessCategoryAnswers,
			practiceCategoryAnswers,
			setPracticeCategoryAnswers,
			characterAndHabitsCategoryAnswers,
			setCharacterAndHabitsCategoryAnswers,
			availabilityAfterProgramCategoryAnswers,
			setAvailabilityAfterProgramCategoryAnswers,
			supportCategoryAnswers,
			setSupportCategoryAnswers,
			understandingOfHeartfulnessCategoryAnswers,
			setUnderstandingOfHeartfulnessCategoryAnswers,
			dietaryHabitsAndHealthCategoryAnswers,
			setDietaryHabitsAndHealthCategoryAnswers,
			termsAndConditions,
			setTermsAndConditions,
			welcomeAndSelfConsciousness,
			setAboutTheProcess,
			onSubmitClick,
			isEmailVerified,
			setIsEmailVerified,
		};
	}, [
		mySrcmProfile,
		additionalApplicantDetails,
		trainingCourseDetails,
		preceptorContactDetails,
		familyMemberContactDetails,
		otherReferenceContactDetails,
		basicEligibilityCategoryAnswers,
		inspirationAndWillingnessCategoryAnswers,
		practiceCategoryAnswers,
		characterAndHabitsCategoryAnswers,
		availabilityAfterProgramCategoryAnswers,
		supportCategoryAnswers,
		understandingOfHeartfulnessCategoryAnswers,
		dietaryHabitsAndHealthCategoryAnswers,
		termsAndConditions,
		welcomeAndSelfConsciousness,
		isEmailVerified,
		onSubmitClick,
	]);

	return (
		<NewApplicationContext.Provider value={value}>
			{children}
		</NewApplicationContext.Provider>
	);
};

export const useNewApplication = () => {
	return useContext(NewApplicationContext);
};
