import { useCallback, useEffect, useState } from 'react';
import {
	canSubmitNewApplication,
	getBatch,
} from '../../service/VolunteerApplicationService';
import { useAuth } from '../../hooks/useAuth';
import { useLoadingIndicator } from '../../hooks/useLoadingIndicator';
import get from 'lodash/get';
import { useToastMessage } from '../../hooks/useToastMessage';
import { useTranslation } from 'react-i18next';
import {
	ApplicationStatus,
	ME_PROFILE,
	NewApplicationNotAllowedReason,
} from '../../shared/Constants';
import { useBatchId } from '../../hooks/useBatchId';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import {
	Batch,
	FunctionaryPredefinedComment,
	PreworkCompletionStatusType,
} from '../../shared/VolunteerApplicationServiceTypes';
import { BatchInfoState } from '../../components/BatchInfo.store';
import { getCandidateModule } from '../../service/ExamModuleService';
import { GetCandidateModuleResponse } from '../../shared/ExamModuleTypes';
import { getItem } from '../../service/LocalStorageService';
import { MeProfile } from '../../shared/MySrcm';

export const useApplicantHome = () => {
	const { firebaseId, srcmId, mySrcmProfile, setMySrcmProfile } = useAuth();
	const { setLoading } = useLoadingIndicator();
	const { showToastMessage } = useToastMessage();
	const { t, i18n } = useTranslation();
	const { batchId } = useBatchId();
	const [showNewApplicationWizard, setShowNewApplicationWizard] =
		useState<boolean>(false);

	const [messageToShow, setMessageToShow] = useState<
		| 'BATCH_CLOSED'
		| 'PAST_APPLICATION_UNDER_REVIEW'
		| 'INVALID_BATCH'
		| 'BATCH_NOT_STARTED_YET'
		| 'ALREADY_A_PRECEPTOR'
		| {
				allowed?: boolean;
				reapplyDate?: string;
				pastApplicationRejectedReasons?: Array<FunctionaryPredefinedComment>;
				pastApplicationBatch?: Batch;
				currentBatch?: Batch;
				pastApplicationId?: number;
				preworkCompletionStatus?: PreworkCompletionStatusType;
				candidateModule?: GetCandidateModuleResponse;
		  }
	>();

	const processBatch = useCallback(async () => {
		if (isEmpty(firebaseId)) {
			return null;
		}

		setLoading(true);

		try {
			const meProfileFromLS = getItem<MeProfile | null>(ME_PROFILE, null);
			setMySrcmProfile?.(meProfileFromLS);

			const batch = await getBatch({ value: batchId });
			if (!BatchInfoState.batchInfo) {
				BatchInfoState.batchInfo = batch;
			}
			if (moment().isBefore(batch.startDate)) {
				setMessageToShow('BATCH_NOT_STARTED_YET');
				return null;
			} else {
				return batch;
			}
		} catch (e) {
			const errorCode: number | undefined = get(e, 'response.status');

			if (errorCode === 404) {
				window.location.replace('https://hfn.link/preceptor-application');
			} else {
				const errorMessage: string | undefined = get(
					e,
					'response.data.message'
				);

				if (errorMessage) {
					showToastMessage('error', errorMessage);
				}
				if (e instanceof Error) {
					showToastMessage('error', e.message);
				}
			}
		} finally {
			setLoading(false);
		}

		return null;
	}, [batchId, firebaseId, setLoading, showToastMessage, setMySrcmProfile]);

	const showNGFCardBasedOnReapplyDate = ({
		allowed,
		reapplyDate,
		pastApplicationRejectedReasons,
		pastApplicationBatch,
		batch,
	}: {
		allowed?: boolean;
		reapplyDate?: string;
		pastApplicationRejectedReasons?: Array<FunctionaryPredefinedComment>;
		pastApplicationBatch?: Batch;
		batch: Batch;
	}) => {
		if (
			moment().isSameOrAfter(batch.startDate) &&
			moment().isSameOrBefore(batch.endDate)
		) {
			setMessageToShow({
				allowed,
				reapplyDate,
				pastApplicationRejectedReasons,
				pastApplicationBatch,
				currentBatch: batch,
			});
		} else {
			setMessageToShow({
				pastApplicationRejectedReasons,
			});
		}
	};

	const processApplicantHome = useCallback(async () => {
		if (
			mySrcmProfile?.is_prefect &&
			mySrcmProfile?.prefect_status === 'active'
		) {
			setMessageToShow('ALREADY_A_PRECEPTOR');
			return;
		}

		const batch = await processBatch();

		if (firebaseId && batch) {
			setLoading(true);

			if (isEmpty(srcmId)) {
				setShowNewApplicationWizard(true);
			}

			try {
				const candidateModuleResponse = await getCandidateModule({
					srcmId: srcmId as string,
				});
				if (candidateModuleResponse.module === 'EXAM_MODULE') {
					setMessageToShow({
						candidateModule: candidateModuleResponse,
					});
					return;
				}

				const response = await canSubmitNewApplication({
					firebaseId,
					srcmId: srcmId as string,
					predefinedReasonLanguage: i18n.language,
				});

				const {
					allowed,
					notAllowedReason,
					pastApplicationStatus,
					pastApplicationBatch,
					pastApplicationRejectedReasonsRectified,
					pastApplicationId,
					preworkCompletionStatus,
				} = response;

				if (
					allowed &&
					moment().isAfter(batch.endDate) &&
					!pastApplicationStatus
				) {
					setMessageToShow('BATCH_CLOSED');
					return null;
				}

				if (
					(allowed &&
						moment().isBefore(batch.endDate) &&
						!pastApplicationStatus) ||
					(allowed &&
						pastApplicationRejectedReasonsRectified &&
						pastApplicationStatus === ApplicationStatus.REJECTED &&
						response.pastApplicationRejectedReasons &&
						response.pastApplicationRejectedReasons?.length > 0 &&
						batchId != pastApplicationBatch?.id)
				) {
					setShowNewApplicationWizard(true);
					return;
				}

				/***
				 * Candidate has applied
				 * If the result date is null or in future - Candidate should see Application in Review Screen
				 * If the result date is today or in past
				 * 		Approved - Approved Sceen
				 * 		Rejected -
				 * 			If the new batch is not opened - Candidate should see NGF Screen without reapply date
				 * 			If the new batch is opened - Candidate should see NGF Screen with reapply date
				 *
				 * Candidate has not applied
				 * 		Check if batch end date is in the past and check if the result date is not null and in the past and check if allowed
				 * 			We will show batch closed screen
				 * 		Check if allowed
				 * 			We will show the candidate new application
				 */

				if (NewApplicationNotAllowedReason.BLACKLISTED === notAllowedReason) {
					setMessageToShow({ reapplyDate: 'a Year' });
					return;
				}

				if (
					notAllowedReason ===
						NewApplicationNotAllowedReason.SUBMITTED_APPLICATION_IN_THE_PAST &&
					(pastApplicationBatch?.snpResultDateForCandidate ===
						'1970-01-01T00:00:00Z' ||
						moment().isSameOrAfter(
							pastApplicationBatch?.snpResultDateForCandidate
						))
				) {
					if (pastApplicationStatus === ApplicationStatus.APPROVED) {
						setMessageToShow({
							pastApplicationId,
							preworkCompletionStatus,
						});
						return;
					}

					if (pastApplicationStatus === ApplicationStatus.REJECTED) {
						showNGFCardBasedOnReapplyDate({
							reapplyDate: response.reapplyDate as string,
							pastApplicationRejectedReasons:
								response.pastApplicationRejectedReasons,
							batch,
						});
						return;
					}
				}

				if (
					allowed &&
					pastApplicationStatus === ApplicationStatus.REJECTED &&
					response.pastApplicationRejectedReasons &&
					response.pastApplicationRejectedReasons?.length > 0
				) {
					showNGFCardBasedOnReapplyDate({
						allowed: response.allowed,
						reapplyDate: response.reapplyDate as string,
						pastApplicationRejectedReasons:
							response.pastApplicationRejectedReasons,
						pastApplicationBatch: response.pastApplicationBatch,
						batch,
					});
					return;
				}

				if (
					(moment().isAfter(batch.endDate) &&
						(batch.snpResultDateForCandidate === '1970-01-01T00:00:00Z' ||
							moment().isAfter(batch.snpResultDateForCandidate)) &&
						allowed) ||
					(moment().isAfter(batch.endDate) &&
						(batch.snpResultDateForCandidate === '1970-01-01T00:00:00Z' ||
							moment().isAfter(batch.snpResultDateForCandidate)) &&
						allowed &&
						pastApplicationStatus === ApplicationStatus.REJECTED)
				) {
					setMessageToShow('BATCH_CLOSED');
					return null;
				}

				setMessageToShow('PAST_APPLICATION_UNDER_REVIEW');
			} catch (e) {
				const errorMessage: string | undefined = get(
					e,
					'response.data.message'
				);

				if (errorMessage) {
					showToastMessage('error', errorMessage);
				}

				if (e instanceof Error) {
					showToastMessage('error', e.message);
				}

				showToastMessage('error', t('errorOccurredWhileLoading'));
			} finally {
				setLoading(false);
			}
		}
	}, [
		batchId,
		firebaseId,
		mySrcmProfile?.is_prefect,
		mySrcmProfile?.prefect_status,
		processBatch,
		setLoading,
		showToastMessage,
		srcmId,
		t,
		i18n.language,
	]);

	useEffect(() => {
		void processApplicantHome();
	}, [processApplicantHome]);

	return { messageToShow, showNewApplicationWizard };
};
