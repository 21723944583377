import axios from 'axios';
import { KEYCLOAK_ID_TOKEN } from '../shared/Constants';
import { getItem, setItem } from './LocalStorageService';
import {
	mySRCMBaseURL,
	xClientId,
	backendServerURL,
	myStaticProfileUpdateURL,
} from './EnvService';
import { getGlobalLogout } from '../hooks/useAuth';
import { jwtDecode } from 'jwt-decode';

const handleUnauthorizedRequest = async () => {
	const logout = getGlobalLogout();
	if (typeof logout === 'function') {
		await logout();
	} else {
		// console.error('Logout function is not available.');
	}
};

/* eslint-disable */
export const refreshKeycloakToken = async () => {
	try {
		const keycloakToken = getItem<string | null>(KEYCLOAK_ID_TOKEN, null);
		if (!process.env.REACT_APP_KEYCLOAK_CONFIG) return false;

		const hfnAuthInfo = localStorage.getItem('hfnAuthInfo');
		if (!keycloakToken && !hfnAuthInfo) return false;

		if (!keycloakToken) return false;

		const decoded: { exp?: number } = jwtDecode(keycloakToken);
		if (!decoded?.exp || decoded.exp * 1000 >= Date.now()) return false;

		const { getRefreshToken } = await import('hfn-oneauth/main');
		const res = await getRefreshToken(
			JSON.parse(process.env.REACT_APP_KEYCLOAK_CONFIG)
		);

		if (res?.data?.access_token) {
			setItem(KEYCLOAK_ID_TOKEN, res.data.access_token);
			return res.data.access_token;
		}

		void handleUnauthorizedRequest();
	} catch (error) {
		//console.log(e);
		void handleUnauthorizedRequest();
	}

	return false;
};

export const mySRCMClient = axios.create({
	baseURL: `${mySRCMBaseURL}/api/v2`,
});

export const myStaticProfileUpdateClient = axios.create({
	baseURL: `${myStaticProfileUpdateURL}/srcmapi`,
});

mySRCMClient.interceptors.request.use(async (config) => {
	const refreshedToken = await refreshKeycloakToken();
	const idToken =
		refreshedToken || getItem<string | null>(KEYCLOAK_ID_TOKEN, null);

	if (!idToken) {
		void handleUnauthorizedRequest();
	} else {
		config.headers.Authorization = `Bearer ${idToken}`;
	}

	config.headers['x-client-id'] = xClientId;
	return config;
});

export const volunteerServiceClient = axios.create({
	baseURL: `${backendServerURL}/unifiedplatform.volunteer.application.VolunteerApplicationService`,
});

volunteerServiceClient.interceptors.request.use(async function (config) {
	const refreshedToken = await refreshKeycloakToken();
	const idToken =
		refreshedToken || getItem<string | null>(KEYCLOAK_ID_TOKEN, null);

	if (!idToken) {
		void handleUnauthorizedRequest();
	} else {
		config.headers.Authorization = `Bearer ${idToken}`;
	}

	return config;
});
/* eslint-enable */
