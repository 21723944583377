import React from 'react';
import { Modal, Button } from 'antd';
import { useTranslation } from 'react-i18next';

interface ModalPopUpProps<T = void> {
	visible?: boolean | false; // Controls modal visibility
	onClose: () => void; // Callback when the modal is closed
	onYes: () => Promise<T> | void; // Callback for Yes action
	title?: string; // Optional title for the mod	al
	content?: React.ReactNode; // Optional content for the modal
	width?: number; // Optional width for the modal
	icon?: string;
	okText?: string;
	cancelText?: string;
	okButtonLoading?: boolean;
	isDanger?: boolean;
}

export const ConfirmPopUp: React.FC<ModalPopUpProps<void>> = ({
	visible,
	onClose,
	onYes,
	title = '',
	content,
	icon,
	okText = 'yes',
	cancelText = 'cancel',
	okButtonLoading,
	isDanger,
}) => {
	const { t } = useTranslation();

	return (
		<Modal
			open={visible}
			onCancel={onClose}
			footer={null}
			centered
			className="max-w-md"
			closable={false} // Disables the close icon
			maskClosable={false}
		>
			{title && (
				<div className="text-center font-sans font-bold text-lg">
					{t(title)}
				</div>
			)}
			{icon && (
				<div className="flex justify-center">
					<img src={icon} className="mx-auto my-0 w-75" />
				</div>
			)}
			{content && <>{content}</>}
			<div className="flex justify-center gap-2 mt-4 ant-modal-footer">
				<Button
					className="flex-1 h-9 text-primary-blue font-sans font-bold"
					onClick={onClose}
					type={isDanger ? 'primary' : 'default'}
					danger={isDanger}
				>
					{t(cancelText)}
				</Button>
				<Button
					className="flex-1 h-9 font-sans bg-primary-blue text-white hover:bg-primary-blue/80 font-bold"
					type="primary"
					onClick={() => {
						void (async () => {
							await onYes();
						})();
					}}
					loading={okButtonLoading}
				>
					{t(okText)}
				</Button>
			</div>
		</Modal>
	);
};
