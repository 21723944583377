import { Form } from 'antd';
import { PreceptorContactDetailsType } from '../../../components/PreceptorContactDetailsType';
import { useNewApplication } from './useNewApplication';
import { useWizard } from '../../../components/wizard/useWizard';
import { useCallback, useEffect } from 'react';
import { PreceptorContactDetailsForm } from '../../../components/PreceptorContactDetailsForm';
import { usePreceptorContactDetails } from './usePreceptorContactDetails';

export const PreceptorContactDetailsStep = () => {
	const { preceptorDetails } = usePreceptorContactDetails();
	const { preceptorContactDetails, setPreceptorContactDetails } =
		useNewApplication();
	const [form] = Form.useForm<PreceptorContactDetailsType>();
	const { setNextButtonDisabled, setCancelButtonDisabled } = useWizard();
	const values = Form.useWatch<PreceptorContactDetailsType>([], form);

	const handleFormValuesChange = useCallback(async () => {
		try {
			const fieldValues = await form.validateFields({ validateOnly: true });
			setNextButtonDisabled(false);
			setPreceptorContactDetails(fieldValues);
		} catch (e) {
			const fields = e as { values: PreceptorContactDetailsType };
			setNextButtonDisabled(true);
			setPreceptorContactDetails(fields.values);
		}
		setCancelButtonDisabled(false);
	}, [
		form,
		setPreceptorContactDetails,
		setCancelButtonDisabled,
		setNextButtonDisabled,
	]);

	useEffect(() => {
		void handleFormValuesChange();
	}, [handleFormValuesChange, values]);

	useEffect(() => {
		form.setFieldsValue(preceptorContactDetails);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [form]);

	useEffect(() => {
		const someOfMandatoryFieldsAreNotProvided = [
			preceptorDetails?.firstName,
			preceptorDetails?.mobile,
		].some((value) => value === null || value === undefined || !value);
		setNextButtonDisabled(someOfMandatoryFieldsAreNotProvided);
		const mandatoryPreceptorDetails = {
			preceptorName: preceptorDetails?.firstName,
			preceptorMobile: preceptorDetails?.mobile,
		};
		setPreceptorContactDetails(mandatoryPreceptorDetails);
	}, [preceptorDetails, setPreceptorContactDetails, setNextButtonDisabled]);

	return (
		<PreceptorContactDetailsForm
			form={form}
			preceptorDetails={preceptorDetails}
		/>
	);
};
