import { useState, useEffect, useCallback, useMemo } from 'react';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';
import {
	OTP_EXPIRATION_INTERVAL_IN_SECONDS,
	OTP_REGENERATION_INTERVAL_IN_SECONDS,
} from '../../../shared/Constants';
import {
	sendOtpForEmailVerification,
	validateOtpForVerification,
} from '../../../service/VolunteerApplicationService';
import { useNewApplication } from './useNewApplication';
import Icon from '../../../components/ui-components/Icon';
import EmailIcon from '../../../assets/images/email.svg';
import { InputPopUp } from '../../../components/ui-components/InputPopUp';
import OTPRequired from '../../../assets/images/otp-required.svg';
import ConfirmTick from '../../../assets/images/confirm-tick.svg';
import { ConfirmPopUp } from '../../../components/ui-components/ConfirmPopUp';
import { InfoPopUp } from '../../../components/ui-components/InfoPopUp';
import { AxiosError } from 'axios';
import { useToastMessage } from '../../../hooks/useToastMessage';
import { myHFNProfileURL } from '../../../service/EnvService';

const { Link } = Typography;

interface EmailVerificationModalProps {
	openModal: boolean;
	onCancel: () => void;
	email?: string;
	mobile?: string;
	onEmailVerificationComplete: ((value: boolean) => void) | null;
}

export const EmailVerificationModal: React.FC<EmailVerificationModalProps> = ({
	openModal,
	onCancel,
	email,
	onEmailVerificationComplete,
}: EmailVerificationModalProps) => {
	const { t } = useTranslation();
	const { setIsEmailVerified } = useNewApplication();
	const { showToastMessage } = useToastMessage();

	const [showConfirmEmailModal, setShowConfirmEmailModal] = useState(openModal);
	const [showTimer, setShowTimer] = useState(false);
	const [showOtpInput, setShowOtpInput] = useState(false);
	const [showVerifySuccess, setShowVerifySuccess] = useState(false);
	const [errorMessage, setErrorMessage] = useState<string | undefined>();
	const [generateOtpButtonLoading, setGenerateOtpButtonLoading] =
		useState(false);
	const [verifyButtonLoading, setVerifyButtonLoading] = useState(false);
	const [otpResendIntervalInSeconds, setOtpResendIntervalInSeconds] =
		useState<number>(OTP_REGENERATION_INTERVAL_IN_SECONDS);
	const [otpExpirationIntervalInSeconds, setOtpExpirationIntervalInSeconds] =
		useState<number>(OTP_EXPIRATION_INTERVAL_IN_SECONDS);
	const [otpExpired, setOtpExpired] = useState(false);

	useEffect(() => {
		setShowConfirmEmailModal(openModal);
	}, [openModal]);

	const sendOTP = useCallback(async () => {
		try {
			setGenerateOtpButtonLoading(true);
			const sendOTPResponse = await sendOtpForEmailVerification();
			if (sendOTPResponse) {
				setShowConfirmEmailModal(false);
				setShowTimer(true);
				setShowOtpInput(true);
				setOtpExpirationIntervalInSeconds(OTP_EXPIRATION_INTERVAL_IN_SECONDS);
			} else {
				setShowTimer(false);
			}
			setGenerateOtpButtonLoading(false);
		} catch (e) {
			const errorMsg: string | undefined = get(e, 'response.data.message');

			if (e instanceof AxiosError) {
				// eslint-disable-next-line
				showToastMessage('error', e?.response?.data?.message || e.message);
			} else if (errorMsg) {
				showToastMessage('error', errorMsg);
			} else if (e instanceof Error) {
				showToastMessage('error', e.message);
			}
			throw e;
		}
	}, [showToastMessage]);

	useEffect(() => {
		if (otpResendIntervalInSeconds > 0 && showTimer) {
			const timerId = setInterval(() => {
				setOtpResendIntervalInSeconds((prevSeconds: number) => prevSeconds - 1);
			}, 1000);

			return () => {
				clearInterval(timerId);
			};
		} else if (otpResendIntervalInSeconds === 0) {
			setOtpResendIntervalInSeconds(59);
			setShowTimer(false);
		}
	}, [otpResendIntervalInSeconds, showTimer]);

	useEffect(() => {
		if (otpExpirationIntervalInSeconds > 0 && showOtpInput) {
			const expirationTimerId = setInterval(() => {
				setOtpExpirationIntervalInSeconds((prevSeconds) => prevSeconds - 1);
			}, 1000);

			return () => {
				clearInterval(expirationTimerId);
			};
		} else if (otpExpirationIntervalInSeconds === 0) {
			setOtpExpired(true);
		}
	}, [otpExpirationIntervalInSeconds, showOtpInput]);

	const minutes = useMemo(
		() => Math.floor(otpResendIntervalInSeconds / 60),
		[otpResendIntervalInSeconds]
	);

	const timerText = useMemo(() => {
		const remainingSeconds = otpResendIntervalInSeconds % 60;
		const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
		const formattedSeconds =
			remainingSeconds < 10 ? `0${remainingSeconds}` : `${remainingSeconds}`;
		return `${formattedMinutes}:${formattedSeconds}`;
	}, [minutes, otpResendIntervalInSeconds]);

	const expirationTimerText = useMemo(() => {
		const expirationMinutes = Math.floor(otpExpirationIntervalInSeconds / 60);
		const seconds = otpExpirationIntervalInSeconds % 60;
		const formattedMinutes =
			expirationMinutes < 10 ? `0${expirationMinutes}` : `${expirationMinutes}`;
		const formattedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
		return `${formattedMinutes}:${formattedSeconds}`;
	}, [otpExpirationIntervalInSeconds]);

	const onVerifyAction = useCallback(
		async (otpValue?: string) => {
			if (otpValue) {
				setVerifyButtonLoading(true);
				const request = {
					otp: otpValue,
				};
				const otpVerified: boolean = await validateOtpForVerification(request);
				if (otpVerified) {
					setIsEmailVerified(true);
					setShowVerifySuccess(true);
					setShowOtpInput(false);
					if (onEmailVerificationComplete) {
						onEmailVerificationComplete(true);
					}
					onCancel();
				} else {
					setIsEmailVerified(false);
					setShowVerifySuccess(false);
					setErrorMessage(t('wrongOTP') || 'Wrong OTP');
				}
				setVerifyButtonLoading(false);
			}
		},
		[onCancel, onEmailVerificationComplete, setIsEmailVerified, t]
	);

	const InputPopupContent = (
		<div className="font-sans">
			{
				<div className="font-bold text-sm">
					{!otpExpired
						? t('otpWillBeExpiredIn', { expirationTimerText })
						: t('otpExpiredPleaseClickOnRequest')}
				</div>
			}
			<div>{t('enterOTP')}</div>
		</div>
	);

	const InputPopupFooterContent = (
		<div className="flex font-sans text-sm">
			<div>{t('didntReceiveCode')}</div>&nbsp;
			{showTimer ? (
				<div>{t('requestAgainIn', { timerText })}</div>
			) : (
				<div
					// eslint-disable-next-line @typescript-eslint/no-misused-promises
					onClick={sendOTP}
					className="text-primary-blue hover:underline cursor-pointer"
				>
					{t('requestAgain')}
				</div>
			)}
		</div>
	);

	const ConfirmPopUpContent = (
		<div className="flex flex-col font-sans font-normal text-sm">
			<div className="flex flex-col items-center">
				<div className="w-24 h-20 my-4">
					<Icon src={EmailIcon} className="w-full h-full" />
				</div>
				<div className="text-center">
					{t('pleaseConfirm')} <b>{email}</b>
					{t('weWillSendOTP')}
				</div>
			</div>
			<div className="mt-4">
				{t('incorrectEmailAddress')}{' '}
				<Link
					href={myHFNProfileURL}
					target="_blank"
					className="px-1 color-black text-sm font-normal font-sans text-primary-blue"
					underline
				>
					{t('editYourEmail')}
				</Link>
			</div>
		</div>
	);

	const InfoPopupContent = (
		<div className="font-sans">
			<div className="font-bold text-sm">
				{t('yourEmailVerifiedSuccessfully')}
			</div>
		</div>
	);

	const onOtpVerificationModalCancel = useCallback(() => {
		setShowOtpInput(false);
		setVerifyButtonLoading(false);
	}, []);

	const onEmailVerificationModalCancel = useCallback(() => {
		setShowConfirmEmailModal(false);
		onCancel();
	}, [onCancel]);

	return (
		<>
			<ConfirmPopUp
				visible={showConfirmEmailModal}
				title={'verifyYouEmail'}
				okText={'getOTP'}
				onYes={sendOTP}
				cancelText={'cancel'}
				onClose={onEmailVerificationModalCancel}
				content={ConfirmPopUpContent}
				okButtonLoading={generateOtpButtonLoading}
			/>
			<InputPopUp
				visible={showOtpInput}
				title={'otpVerification'}
				fieldType="OTP"
				onClose={onOtpVerificationModalCancel}
				onYes={onVerifyAction}
				icon={OTPRequired}
				content={InputPopupContent}
				okText={'verify'}
				cancelText={'cancel'}
				footerContent={InputPopupFooterContent}
				okButtonLoading={verifyButtonLoading}
				inputError={errorMessage}
			/>
			<InfoPopUp
				visible={showVerifySuccess}
				title={'verifiedSuccessfully'}
				onYes={() => setShowVerifySuccess(false)}
				icon={ConfirmTick}
				content={InfoPopupContent}
				okText={'ok'}
			/>
		</>
	);
};
