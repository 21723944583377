import { volunteerServiceClient } from './HTTPClient';
import {
	ApplicantAdditionalDetailsRequest,
	Application,
	ApplicationSummaryList,
	ApproverRecommendationRequest,
	Batch,
	BooleanValue,
	BulkSaveFunctionariesOfZoneRequest,
	CanSubmitNewApplicationResponse,
	CentersPage,
	FunctionaryFeedbackRequest,
	FunctionaryOfZone,
	GetApplicationRequest,
	GetApplicationsRequest,
	GetFunctionaryListRequest,
	GetFunctionaryRequest,
	GetUserRoleRequest,
	GetUserRoleResponse,
	IntegerValue,
	NewApplicationRequest,
	OtpVerificationRequest,
	PreceptorRecommendationRequest,
	PredefinedReasonListRequest,
	PredefinedReasonListResponse,
	FunctionaryList,
	ProposerRecommendationRequest,
	SearchCenterRequest,
	SearchZonesRequest,
	ZCRecommendationRequest,
	ZonesPage,
	CanSubmitNewApplicationRequest,
	RectifyPastApplicationRejectedReasonsRequest,
	PreworkAnswersRequest,
	PreworkResponseData,
	GetPreceptorsRequest,
	GetPreceptorsResponse,
	CreatePreceptorGroupRequest,
	CreatePreceptorGroupResponse,
	UpdatePreceptorGroupRequest,
	UpdatePreceptorGroupResponse,
	GetPreceptorsGroupRequest,
	GetPreceptorsGroupResponse,
	AssignMentorRequest,
	GetPreceptorDashboardDetailsRequest,
	GetPreceptorDashboardDetailsResponse,
	PreceptorGroup,
	PreceptorGroupRequest,
	PreceptorDashboardCardDetail,
	GetRegionsResponse,
	GraphicalViewResponse,
	GetPreceptorBatchesRequest,
	GetPreceptorBatchesResponse,
	CreatePreceptorBatchRequest,
	PreceptorBatch,
	GetRegionsRequest,
	LoadPreceptorsFromMySRCMRequest,
	GetZonesRequest,
	Preceptor,
	GetPreceptorByPartnerIdRequest,
} from '../shared/VolunteerApplicationServiceTypes';
import { GetZonesResponse } from '../shared/ExamModuleTypes';

export const getRole = async (
	request: GetUserRoleRequest
): Promise<GetUserRoleResponse> => {
	const response = await volunteerServiceClient.post<GetUserRoleResponse>(
		'getRole',
		request
	);
	return response.data;
};

export const searchCenters = async (
	request: SearchCenterRequest
): Promise<CentersPage> => {
	const response = await volunteerServiceClient.post<CentersPage>(
		'searchCenters',
		request
	);
	return response.data;
};

export const searchCountry = async (
	request: SearchCenterRequest
): Promise<CentersPage> => {
	const response = await volunteerServiceClient.post<CentersPage>(
		'searchCenters',
		request
	);
	return response.data;
};

export const searchState = async (
	request: SearchCenterRequest
): Promise<CentersPage> => {
	const response = await volunteerServiceClient.post<CentersPage>(
		'searchCenters',
		request
	);
	return response.data;
};

export const searchCity = async (
	request: SearchCenterRequest
): Promise<CentersPage> => {
	const response = await volunteerServiceClient.post<CentersPage>(
		'searchCenters',
		request
	);
	return response.data;
};
export const getFunctionaryList = async (
	request: GetFunctionaryListRequest
): Promise<FunctionaryList> => {
	const response = await volunteerServiceClient.post<FunctionaryList>(
		'getFunctionaryList',
		request
	);
	return response.data;
};

export const getFunctionary = async (
	request: GetFunctionaryRequest
): Promise<FunctionaryOfZone> => {
	const response = await volunteerServiceClient.post<FunctionaryOfZone>(
		'getFunctionary',
		request
	);
	return response.data;
};

export const bulkSaveFunctionariesOfZone = async (
	request: BulkSaveFunctionariesOfZoneRequest
): Promise<BooleanValue> => {
	const response = await volunteerServiceClient.post<BooleanValue>(
		'bulkSaveFunctionariesOfZone',
		request
	);
	return response.data;
};

export const getApplications = async (
	request: GetApplicationsRequest
): Promise<ApplicationSummaryList> => {
	const response = await volunteerServiceClient.post<ApplicationSummaryList>(
		'getApplications',
		request
	);
	return response.data;
};

export const getApplication = async (
	request: GetApplicationRequest
): Promise<Application> => {
	const response = await volunteerServiceClient.post<Application>(
		'getApplication',
		request
	);
	return response.data;
};

export const submitNewApplication = async (
	request: NewApplicationRequest
): Promise<BooleanValue> => {
	const response = await volunteerServiceClient.post<BooleanValue>(
		'submitNewApplication',
		request
	);
	return response.data;
};

export const canSubmitNewApplication = async (
	request: CanSubmitNewApplicationRequest
): Promise<CanSubmitNewApplicationResponse> => {
	const response =
		await volunteerServiceClient.post<CanSubmitNewApplicationResponse>(
			'canSubmitNewApplication',
			request
		);
	return response.data;
};

export const rectifyPastApplicationRejectedReasons = async (
	request: RectifyPastApplicationRejectedReasonsRequest
): Promise<BooleanValue> => {
	const response = await volunteerServiceClient.post<BooleanValue>(
		'rectifyPastApplicationRejectedReasons',
		request
	);
	return response.data;
};

export const getBatch = async (request: IntegerValue): Promise<Batch> => {
	const response = await volunteerServiceClient.post<Batch>(
		'getBatch',
		request
	);
	return response.data;
};

export const submitFeedbackFromProposer = async (
	request: FunctionaryFeedbackRequest
): Promise<BooleanValue> => {
	const response = await volunteerServiceClient.post<BooleanValue>(
		'submitFeedbackFromProposer',
		request
	);
	return response.data;
};

export const submitPreceptorInterviewFeedback = async (
	request: PreceptorRecommendationRequest
): Promise<BooleanValue> => {
	const response = await volunteerServiceClient.post<BooleanValue>(
		'submitPreceptorInterviewFeedback',
		request
	);
	return response.data;
};

export const updateAdditionalDetailsOfApplicant = async (
	request: ApplicantAdditionalDetailsRequest
): Promise<BooleanValue> => {
	const response = await volunteerServiceClient.post<BooleanValue>(
		'updateAdditionalDetailsOfApplicant',
		request
	);
	return response.data;
};

export const submitProposerRecommendation = async (
	request: ProposerRecommendationRequest
): Promise<BooleanValue> => {
	const response = await volunteerServiceClient.post<BooleanValue>(
		'submitProposerRecommendation',
		request
	);
	return response.data;
};

export const submitCareTeamRecommendation = async (
	request: ProposerRecommendationRequest
): Promise<BooleanValue> => {
	const response = await volunteerServiceClient.post<BooleanValue>(
		'submitCareTeamRecommendation',
		request
	);
	return response.data;
};

export const submitZCRecommendation = async (
	request: ZCRecommendationRequest
): Promise<BooleanValue> => {
	const response = await volunteerServiceClient.post<BooleanValue>(
		'submitZCRecommendation',
		request
	);
	return response.data;
};

export const skipFeedbackCollectionByProposer = async (
	request: ProposerRecommendationRequest
): Promise<BooleanValue> => {
	const response = await volunteerServiceClient.post<BooleanValue>(
		'skipFeedbackCollectionByProposer',
		request
	);
	return response.data;
};

export const searchZones = async (
	request: SearchZonesRequest
): Promise<ZonesPage> => {
	const response = await volunteerServiceClient.post<ZonesPage>(
		'searchZones',
		request
	);
	return response.data;
};

export const submitApproverRecommendation = async (
	request: ApproverRecommendationRequest
): Promise<BooleanValue> => {
	const response = await volunteerServiceClient.post<BooleanValue>(
		'submitApproverRecommendation',
		request
	);
	return response.data;
};

export const sendOtpForEmailVerification = async (): Promise<BooleanValue> => {
	const response = await volunteerServiceClient.post<BooleanValue>(
		'sendOtpForVerification '
	);
	return response.data;
};

export const validateOtpForVerification = async (
	request: OtpVerificationRequest
): Promise<boolean> => {
	const response = await volunteerServiceClient.post<boolean>(
		'validateOtpForVerification',
		request
	);
	return response.data;
};

export const getAllPredefinedReasons = async (
	request: PredefinedReasonListRequest
): Promise<PredefinedReasonListResponse> => {
	const response =
		await volunteerServiceClient.post<PredefinedReasonListResponse>(
			'getAllPredefinedReasons',
			request
		);
	return response.data;
};

export const getPreworkDataByApplication = async (
	request: IntegerValue
): Promise<PreworkResponseData> => {
	const response = await volunteerServiceClient.post<PreworkResponseData>(
		'getPreworkDataByApplication',
		request
	);
	return response.data;
};

export const captureCandidatePreworkAnswers = async (
	request: PreworkAnswersRequest
): Promise<BooleanValue> => {
	const response = await volunteerServiceClient.post<BooleanValue>(
		'captureCandidatePreworkAnswers',
		request
	);
	return response.data;
};

export const getPreceptors = async (
	request: GetPreceptorsRequest
): Promise<GetPreceptorsResponse> => {
	const response = await volunteerServiceClient.post<GetPreceptorsResponse>(
		'getPreceptors',
		request
	);
	return response.data;
};

export const createPreceptorGroup = async (
	request: CreatePreceptorGroupRequest
): Promise<CreatePreceptorGroupResponse> => {
	const response =
		await volunteerServiceClient.post<CreatePreceptorGroupResponse>(
			'createPreceptorGroup',
			request
		);
	return response.data;
};

export const getPreceptorGroup = async (
	request: PreceptorGroupRequest
): Promise<PreceptorGroup> => {
	const response = await volunteerServiceClient.post<PreceptorGroup>(
		'getPreceptorGroup',
		request
	);
	return response.data;
};

export const updatePreceptorGroup = async (
	request: UpdatePreceptorGroupRequest
): Promise<UpdatePreceptorGroupResponse> => {
	const response =
		await volunteerServiceClient.post<UpdatePreceptorGroupResponse>(
			'updatePreceptorGroup',
			request
		);
	return response.data;
};

export const getPreceptorGroups = async (
	request: GetPreceptorsGroupRequest
): Promise<GetPreceptorsGroupResponse> => {
	const response =
		await volunteerServiceClient.post<GetPreceptorsGroupResponse>(
			'getPreceptorGroups',
			request
		);
	return response.data;
};

export const assignMentor = async (
	request: AssignMentorRequest
): Promise<BooleanValue> => {
	const response = await volunteerServiceClient.post<BooleanValue>(
		'assignMentor',
		request
	);
	return response.data;
};

export const getPreceptorDashboardDetails = async (
	request: GetPreceptorDashboardDetailsRequest
): Promise<GetPreceptorDashboardDetailsResponse> => {
	const response =
		await volunteerServiceClient.post<GetPreceptorDashboardDetailsResponse>(
			'getPreceptorDashboardDetails',
			request
		);
	return response.data;
};

export const getPreceptorDashboardCardDetail = async (
	request: GetPreceptorsRequest
): Promise<PreceptorDashboardCardDetail> => {
	const response =
		await volunteerServiceClient.post<PreceptorDashboardCardDetail>(
			'getPreceptorDashboardCardDetail',
			request
		);
	return response.data;
};

export const getRegionsOfLoggedInPreceptor = async (
	request: GetRegionsRequest
): Promise<GetRegionsResponse> => {
	const response = await volunteerServiceClient.post<GetRegionsResponse>(
		'getRegionsOfLoggedInPreceptor',
		request
	);
	return response.data;
};

export const getGraphicalView = async (
	request: GetPreceptorsRequest
): Promise<GraphicalViewResponse> => {
	const response = await volunteerServiceClient.post<GraphicalViewResponse>(
		'getGraphicalView',
		request
	);
	return response.data;
};

export const getPreceptorBatches = async (
	request: GetPreceptorBatchesRequest
): Promise<GetPreceptorBatchesResponse> => {
	const response =
		await volunteerServiceClient.post<GetPreceptorBatchesResponse>(
			'getPreceptorBatches',
			request
		);
	return response.data;
};

export const createPreceptorBatch = async (
	request: CreatePreceptorBatchRequest
): Promise<PreceptorBatch> => {
	const response = await volunteerServiceClient.post<PreceptorBatch>(
		'createPreceptorBatch',
		request
	);
	return response.data;
};

export const updatePreceptorBatch = async (
	request: CreatePreceptorBatchRequest
): Promise<PreceptorBatch> => {
	const response = await volunteerServiceClient.post<PreceptorBatch>(
		'updatePreceptorBatch',
		request
	);
	return response.data;
};

export const loadPreceptorsFromMySrcm = async (
	request: LoadPreceptorsFromMySRCMRequest
): Promise<boolean> => {
	const response = await volunteerServiceClient.post<boolean>(
		'loadPreceptorsFromMySrcm',
		request
	);
	return response.data;
};

export const getZonesOfLoggedInPreceptor = async (
	request: GetZonesRequest
): Promise<GetZonesResponse> => {
	const response = await volunteerServiceClient.post<GetZonesResponse>(
		'getZonesOfLoggedInPreceptor',
		request
	);
	return response.data;
};

export const getPreceptorByPartnerId = async (
	request: GetPreceptorByPartnerIdRequest
): Promise<Preceptor> => {
	const response = await volunteerServiceClient.post<Preceptor>(
		'getPreceptorByPartnerId',
		request
	);
	return response.data;
};
