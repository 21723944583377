import { MultipleChoiceQuestionUIConfig } from './MultipleChoiceQuestionUIConfig';
import {
	AnswerOptionsField,
	EvaluationCategoryID,
	MedicationQuestionId,
	PreWorkQuestionId,
} from './Constants';
export interface CategoryStepUIConfig {
	id: number;
	title: string;
	functionaryTitle?: string;
	subTitle: string;
	questions: MultipleChoiceQuestionUIConfig[];
	questionsToAskInPreceptorInterview: {
		suggestedQuestionsToAsk: Array<string>;
		mandatoryQuestionsToAsk: MultipleChoiceQuestionUIConfig[];
	};
	questionsToAskInApplicantInterview: {
		suggestedQuestionsToAsk: Array<string>;
		mandatoryQuestionsToAsk: MultipleChoiceQuestionUIConfig[];
	};
	questionsToAskInFamilyMemberInterview: {
		suggestedQuestionsToAsk: Array<string>;
		mandatoryQuestionsToAsk: MultipleChoiceQuestionUIConfig[];
	};
	questionsToAskInOtherReferenceInterview: Array<string>;
}

export const InspirationAndWillingnessUIConfig: CategoryStepUIConfig = {
	id: EvaluationCategoryID.INSPIRATION_AND_WILLINGNESS,
	title: 'inspirationAndWillingness',
	subTitle: 'primaryQualificationForATrainer',
	questions: [
		{
			questionId: 1,
			categoryId: 1,
			questionNo: 1,
			answerOptionsField: AnswerOptionsField.BUTTONS,
			questionText: 'iAmWillingToVolunteerTime',
			answerOptions: [
				{ optionId: 1, optionText: 'yes' },
				{ optionId: 2, optionText: 'somewhat' },
				{ optionId: 3, optionText: 'no' },
			],
			required: true,
			hideComments: true,
		},
		{
			questionId: 2,
			categoryId: 1,
			questionNo: 2,
			answerOptionsField: AnswerOptionsField.BUTTONS,
			questionText: 'iUnderstandAndWantToContributeToVision',
			answerOptions: [
				{ optionId: 1, optionText: 'yes' },
				{ optionId: 2, optionText: 'somewhat' },
				{ optionId: 3, optionText: 'no' },
			],
			required: true,
			hideComments: true,
		},
		{
			questionId: 3,
			categoryId: 1,
			questionNo: 3,
			answerOptionsField: AnswerOptionsField.BUTTONS,
			questionText: 'iAmWillingToVolunteerAndServe',
			answerOptions: [
				{ optionId: 1, optionText: 'yes' },
				{ optionId: 2, optionText: 'somewhat' },
				{ optionId: 3, optionText: 'no' },
			],
			required: true,
			hideComments: true,
		},
		{
			questionId: 150,
			categoryId: 1,
			questionNo: 4,
			questionText: 'whyDoYouWantToVolunteerWithHFN',
			required: true,
			commentsRequired: true,
		},
	],
	questionsToAskInPreceptorInterview: {
		suggestedQuestionsToAsk: [],
		mandatoryQuestionsToAsk: [
			{
				questionId: 222,
				categoryId: 1,
				questionNo: 1,
				answerOptionsField: AnswerOptionsField.SELECT,
				questionText: 'howLongHaveYouSeenThisPersonAsAbhyasi',
				answerOptions: [
					{ optionId: 1, optionText: 'lessThanSixMonthsInWords' },
					{ optionId: 2, optionText: 'sixToTwelveMonths' },
					{ optionId: 3, optionText: 'moreThanTwelveMonths' },
				],
				required: true,
				commentsText: 'proposersComments',
			},
			{
				questionId: 223,
				categoryId: 1,
				questionNo: 2,
				answerOptionsField: AnswerOptionsField.SELECT,
				questionText: 'isInspiredBySMAndWillingToServeAsPreceptor',
				answerOptions: [
					{ optionId: 1, optionText: 'yesPreceptorConfirmsSame' },
					{ optionId: 2, optionText: 'preceptorSaysSomewhatYes' },
					{ optionId: 3, optionText: 'preceptorNotConvinced' },
					{ optionId: 4, optionText: 'preceptorDoesNotKnow' },
				],
				required: true,
				commentsText: 'proposersComments',
			},
			{
				questionId: 224,
				categoryId: 1,
				questionNo: 3,
				answerOptionsField: AnswerOptionsField.SELECT,
				questionText: 'haveYouSeenCandidateVolunteering',
				answerOptions: [
					{ optionId: 1, optionText: 'yesPreceptorHasSeenVolunteer' },
					{ optionId: 2, optionText: 'noPreceptorKnowsThatDoesNotVolunteer' },
					{ optionId: 3, optionText: 'preceptorDoesNotKnow' },
				],
				required: true,
				commentsText: 'proposersComments',
			},
		],
	},
	questionsToAskInApplicantInterview: {
		suggestedQuestionsToAsk: [],
		mandatoryQuestionsToAsk: [
			{
				questionId: 201,
				categoryId: 1,
				questionNo: 1,
				answerOptionsField: AnswerOptionsField.SELECT,
				questionText: 'thankYouWouldYouLikeToServeAsAPreceptor',
				answerOptions: [
					{ optionId: 1, optionText: 'appealing' },
					{ optionId: 2, optionText: 'somewhatAppealing' },
					{ optionId: 3, optionText: 'notAppealing' },
					{ optionId: 4, optionText: 'dontKnow' },
				],
				required: true,
				commentsText: 'proposersComments',
			},
			{
				questionId: 202,
				categoryId: 1,
				questionNo: 2,
				answerOptionsField: AnswerOptionsField.SELECT,
				questionText: 'haveYouVolunteered',
				answerOptions: [
					{ optionId: 1, optionText: 'appealing' },
					{ optionId: 2, optionText: 'somewhatAppealing' },
					{ optionId: 3, optionText: 'notAppealing' },
					{ optionId: 4, optionText: 'dontKnow' },
				],
				required: true,
				commentsText: 'proposersComments',
			},
		],
	},
	questionsToAskInFamilyMemberInterview: {
		suggestedQuestionsToAsk: [],
		mandatoryQuestionsToAsk: [],
	},
	questionsToAskInOtherReferenceInterview: [],
};

export const PracticeUIConfig: CategoryStepUIConfig = {
	id: EvaluationCategoryID.PRACTICE,
	title: 'practice',
	subTitle: 'trainerNeedsToBeIntensePractitioner',
	questions: [
		{
			questionId: 4,
			categoryId: 2,
			questionNo: 1,
			answerOptionsField: AnswerOptionsField.BUTTONS,
			questionText: 'howLongHaveIBeenAPractitioner',
			answerOptions: [
				{ optionId: 1, optionText: 'zeroToSixMonths' },
				{ optionId: 2, optionText: 'sixMonthsToOneYear' },
				{ optionId: 3, optionText: 'oneToThreeYears' },
				{ optionId: 4, optionText: 'moreThanThreeYears' },
			],
			displayAnswerOptionsVertically: true,
			required: true,
			hideComments: true,
		},
		{
			questionId: 5,
			categoryId: 2,
			questionNo: 2,
			answerOptionsField: AnswerOptionsField.BUTTONS,
			questionText: 'myDailyPractiseIsRegular',
			answerOptions: [
				{ optionId: 1, optionText: 'yes' },
				{ optionId: 2, optionText: 'somewhat' },
				{ optionId: 3, optionText: 'no' },
			],
			required: true,
			hideComments: true,
		},
		{
			questionId: 6,
			categoryId: 2,
			questionNo: 3,
			answerOptionsField: AnswerOptionsField.BUTTONS,
			questionText: 'iTakeSittings',
			answerOptions: [
				{ optionId: 1, optionText: 'yes' },
				{ optionId: 2, optionText: 'somewhat' },
				{ optionId: 3, optionText: 'no' },
			],
			required: true,
			hideComments: true,
		},
		{
			questionId: 7,
			categoryId: 2,
			questionNo: 4,
			answerOptionsField: AnswerOptionsField.BUTTONS,
			questionText: 'iAmConvincedAboutTheRoleAndSupport',
			answerOptions: [
				{ optionId: 1, optionText: 'yes' },
				{ optionId: 2, optionText: 'somewhat' },
				{ optionId: 3, optionText: 'no' },
			],
			required: true,
			hideComments: true,
		},
		{
			questionId: 151,
			categoryId: 2,
			questionNo: 5,
			questionText: 'whatChangesDoYouSee',
			required: true,
			commentsRequired: true,
		},
	],
	questionsToAskInPreceptorInterview: {
		suggestedQuestionsToAsk: [],
		mandatoryQuestionsToAsk: [
			{
				questionId: 225,
				categoryId: 2,
				questionNo: 1,
				answerOptionsField: AnswerOptionsField.SELECT,
				questionText: 'doYouKnowIfCandidateDoesTheirSadhanaRegularly',
				answerOptions: [
					{ optionId: 1, optionText: 'yesPreceptorCanConfirmRegularity' },
					{ optionId: 2, optionText: 'preceptorConfirmsSomewhatRegular' },
					{ optionId: 3, optionText: 'preceptorConfirmsIrregular' },
					{ optionId: 4, optionText: 'preceptorDoesNotKnow' },
				],
				required: true,
				commentsText: 'proposersComments',
			},
			{
				questionId: 226,
				categoryId: 2,
				questionNo: 2,
				answerOptionsField: AnswerOptionsField.SELECT,
				questionText: 'doesTheCandidateTakeSittingsRegularly',
				answerOptions: [
					{ optionId: 1, optionText: 'yesPreceptorCanConfirmRegularity' },
					{ optionId: 2, optionText: 'preceptorConfirmsSomewhatRegular' },
					{ optionId: 3, optionText: 'preceptorConfirmsIrregular' },
				],
				required: true,
				commentsText: 'proposersComments',
			},
			{
				questionId: 227,
				categoryId: 2,
				questionNo: 3,
				answerOptionsField: AnswerOptionsField.SELECT,
				questionText: 'doesTheCandidateAttendSatsanghsRegularly',
				answerOptions: [
					{ optionId: 1, optionText: 'yesPreceptorCanConfirmRegularity' },
					{ optionId: 2, optionText: 'preceptorConfirmsSomewhatRegular' },
					{ optionId: 3, optionText: 'preceptorConfirmsIrregular' },
					{ optionId: 4, optionText: 'preceptorDoesNotKnow' },
				],
				required: true,
				commentsText: 'proposersComments',
			},
		],
	},
	questionsToAskInApplicantInterview: {
		suggestedQuestionsToAsk: [],
		mandatoryQuestionsToAsk: [
			{
				questionId: 203,
				categoryId: 2,
				questionNo: 1,
				answerOptionsField: AnswerOptionsField.SELECT,
				questionText: 'whenAndWhyDidYouStartSMPractice',
				answerOptions: [
					{ optionId: 1, optionText: 'lessThanSixMonthsInWords' },
					{ optionId: 2, optionText: 'sixMonthsToOneYear' },
					{ optionId: 3, optionText: 'oneToThreeYears' },
					{ optionId: 4, optionText: 'moreThanThreeYears' },
				],
				required: true,
				commentsText: 'proposersComments',
			},
			{
				questionId: 204,
				categoryId: 2,
				questionNo: 2,
				answerOptionsField: AnswerOptionsField.SELECT,
				questionText: 'yourExperienceWithIndividualSittings',
				answerOptions: [
					{ optionId: 1, optionText: 'regularInSittings' },
					{ optionId: 2, optionText: 'somewhatRegular' },
					{ optionId: 3, optionText: 'sporadic' },
					{ optionId: 4, optionText: 'irregular' },
					{ optionId: 5, optionText: 'dontKnow' },
				],
				required: true,
				commentsText: 'proposersComments',
			},
			{
				questionId: 205,
				categoryId: 2,
				questionNo: 3,
				answerOptionsField: AnswerOptionsField.SELECT,
				questionText: 'yourExperienceWithSatsangh',
				answerOptions: [
					{ optionId: 1, optionText: 'regularForSundaySatsangh' },
					{ optionId: 2, optionText: 'somewhatRegular' },
					{ optionId: 3, optionText: 'irregular' },
					{ optionId: 4, optionText: 'dontKnow' },
				],
				required: true,
				commentsText: 'proposersComments',
			},
			{
				questionId: 206,
				categoryId: 2,
				questionNo: 4,
				answerOptionsField: AnswerOptionsField.SELECT,
				questionText: 'yourExperienceWithBandaras',
				answerOptions: [
					{ optionId: 1, optionText: 'regularAttendee' },
					{ optionId: 2, optionText: 'attendedFew' },
					{ optionId: 3, optionText: 'notAttended' },
					{ optionId: 4, optionText: 'dontKnow' },
				],
				required: true,
				commentsText: 'proposersComments',
			},
			{
				questionId: 207,
				categoryId: 2,
				questionNo: 5,
				answerOptionsField: AnswerOptionsField.SELECT,
				questionText: 'describeYourAssociationWithMaster',
				answerOptions: [
					{ optionId: 1, optionText: 'seemsWellConnectedWithMaster' },
					{ optionId: 2, optionText: 'metTheMasterFewTimes' },
					{ optionId: 3, optionText: 'meditatedWithMaster' },
					{ optionId: 4, optionText: 'neverMetMaster' },
				],
				required: true,
				commentsText: 'proposersComments',
			},
			{
				questionId: 208,
				categoryId: 2,
				questionNo: 6,
				answerOptionsField: AnswerOptionsField.SELECT,
				questionText: 'howHasThePracticeImpactedYou',
				answerOptions: [
					{ optionId: 1, optionText: 'clearThatDeepTransformation' },
					{ optionId: 2, optionText: 'positiveImpactVisible' },
					{ optionId: 3, optionText: 'unsureOfImpact' },
					{ optionId: 4, optionText: 'notReallyConvinced' },
				],
				required: true,
				commentsText: 'proposersComments',
			},
			{
				questionId: 209,
				categoryId: 2,
				questionNo: 7,
				answerOptionsField: AnswerOptionsField.SELECT,
				questionText: 'doYouFollowAnyOtherPractice',
				answerOptions: [
					{ optionId: 1, optionText: 'anchoredAndOnlyPracticesSM' },
					{
						optionId: 2,
						optionText: 'anchoredInSahajMargAndHasOtherPractices',
					},
					{ optionId: 3, optionText: 'practicesMultipleSystems' },
					{
						optionId: 4,
						optionText: 'anchoredElsewhereAndAlsoPracticesSahajMarg',
					},
				],
				required: true,
				commentsText: 'proposersComments',
			},
		],
	},
	questionsToAskInFamilyMemberInterview: {
		suggestedQuestionsToAsk: [],
		mandatoryQuestionsToAsk: [],
	},
	questionsToAskInOtherReferenceInterview: [],
};

export const AvailabilityAfterProgramUIConfig: CategoryStepUIConfig = {
	id: EvaluationCategoryID.AVAILABILITY_AFTER_PROGRAM,
	title: 'availabilityAfterProgram',
	subTitle: 'exploreIfWillingnessTranslatesToAction',
	questions: [
		{
			questionId: 14,
			categoryId: 4,
			questionNo: 1,
			answerOptionsField: AnswerOptionsField.BUTTONS,
			questionText: 'iCommitOfAtleastFiveHoursEveryWeek',
			answerOptions: [
				{ optionId: 1, optionText: 'yes' },
				{ optionId: 2, optionText: 'somewhat' },
				{ optionId: 3, optionText: 'no' },
			],
			required: true,
			hideComments: true,
		},
		{
			questionId: 15,
			categoryId: 4,
			questionNo: 2,
			answerOptionsField: AnswerOptionsField.BUTTONS,
			questionText: 'iUndertakeThatIBalanceMyProfessionalAndSpiritualLives',
			answerOptions: [
				{ optionId: 1, optionText: 'yes' },
				{ optionId: 2, optionText: 'somewhat' },
				{ optionId: 3, optionText: 'no' },
			],
			required: true,
			hideComments: true,
		},
		{
			questionId: 153,
			categoryId: 4,
			questionNo: 3,
			questionText: 'whatTypeOfVolunteerHaveYouBeen',
			required: true,
			commentsRequired: true,
		},
	],
	questionsToAskInPreceptorInterview: {
		suggestedQuestionsToAsk: [],
		mandatoryQuestionsToAsk: [
			{
				questionId: 231,
				categoryId: 4,
				questionNo: 1,
				answerOptionsField: AnswerOptionsField.SELECT,
				questionText: 'doYouThinkCandidateWillHaveTimeForPreceptorWork',
				answerOptions: [
					{ optionId: 1, optionText: 'preceptorBelievesYes' },
					{ optionId: 2, optionText: 'preceptorDoesNotKnow' },
					{ optionId: 3, optionText: 'preceptorBelievesCandidateIsTooBusy' },
				],
				required: true,
				commentsText: 'proposersComments',
			},
		],
	},
	questionsToAskInApplicantInterview: {
		suggestedQuestionsToAsk: [],
		mandatoryQuestionsToAsk: [
			{
				questionId: 212,
				categoryId: 4,
				questionNo: 1,
				answerOptionsField: AnswerOptionsField.SELECT,
				questionText: 'howIsYourScheduleLike',
				answerOptions: [
					{ optionId: 1, optionText: 'hasTime' },
					{ optionId: 2, optionText: 'tightSchedule' },
					{ optionId: 3, optionText: 'unsureAndHasNotThought' },
					{ optionId: 4, optionText: 'appearsPreoccupied' },
				],
				required: true,
				commentsText: 'proposersComments',
			},
			{
				questionId: 213,
				categoryId: 4,
				questionNo: 2,
				answerOptionsField: AnswerOptionsField.SELECT,
				questionText: 'availableForAtLeastThreeToFiveHrs',
				answerOptions: [
					{ optionId: 1, optionText: 'hasTime' },
					{ optionId: 2, optionText: 'tightSchedule' },
					{ optionId: 3, optionText: 'unsureAndHasNotThought' },
					{ optionId: 4, optionText: 'appearsPreoccupied' },
				],
				required: true,
				commentsText: 'proposersComments',
			},
		],
	},
	questionsToAskInFamilyMemberInterview: {
		suggestedQuestionsToAsk: [],
		mandatoryQuestionsToAsk: [
			{
				questionId: 240,
				categoryId: 4,
				questionNo: 1,
				answerOptionsField: AnswerOptionsField.SELECT,
				questionText: 'doesYourFamilyAllowTheCandidateTimeToDevote',
				answerOptions: [
					{ optionId: 1, optionText: 'familyMemberConfirmsFavourable' },
					{ optionId: 2, optionText: 'familyMemberSlightlyDoubtful' },
					{ optionId: 3, optionText: 'familyMemberConfirmsNotFavourable' },
					{ optionId: 4, optionText: 'familyMemberDoesNotComment' },
				],
				required: true,
				commentsText: 'proposersComments',
			},
			{
				questionId: 241,
				categoryId: 4,
				questionNo: 2,
				answerOptionsField: AnswerOptionsField.SELECT,
				questionText: 'doYouBelieveCandidateCanBalanceSpiritualAndMaterialLife',
				answerOptions: [
					{ optionId: 1, optionText: 'familyMemberConfirmsFavourable' },
					{ optionId: 2, optionText: 'familyMemberSlightlyDoubtful' },
					{ optionId: 3, optionText: 'familyMemberConfirmsNotFavourable' },
					{ optionId: 4, optionText: 'familyMemberDoesNotComment' },
				],
				required: true,
				commentsText: 'proposersComments',
			},
		],
	},
	questionsToAskInOtherReferenceInterview: [],
};

export const BasicEligibilityUIConfig: CategoryStepUIConfig = {
	id: EvaluationCategoryID.BASIC_ELIGIBILITY,
	title: 'essentials',
	subTitle: 'thisIsMandatoryCriteria',
	questions: [
		{
			questionId: 23,
			categoryId: 7,
			questionNo: 1,
			answerOptionsField: AnswerOptionsField.BUTTONS,
			questionText: 'heartfulnessStartsAtMyHome',
			answerOptions: [
				{ optionId: 1, optionText: 'yes' },
				{ optionId: 2, optionText: 'no' },
			],
			required: true,
			hideComments: true,
		},
		{
			questionId: 24,
			categoryId: 7,
			questionNo: 2,
			answerOptionsField: AnswerOptionsField.BUTTONS,
			questionText: 'notInvolvedInDomesticAbuseOrViolence',
			answerOptions: [
				{ optionId: 1, optionText: 'yes' },
				{ optionId: 2, optionText: 'no' },
			],
			required: true,
			hideComments: true,
		},
		{
			questionId: 25,
			categoryId: 7,
			questionNo: 3,
			answerOptionsField: AnswerOptionsField.BUTTONS,
			questionText: 'notInvolvedInSexualAbuse',
			answerOptions: [
				{ optionId: 1, optionText: 'yes' },
				{ optionId: 2, optionText: 'no' },
			],
			required: true,
			hideComments: true,
		},
		{
			questionId: 26,
			categoryId: 7,
			questionNo: 4,
			answerOptionsField: AnswerOptionsField.BUTTONS,
			questionText: 'notPartOfNegativeImpact',
			answerOptions: [
				{ optionId: 1, optionText: 'yes' },
				{ optionId: 2, optionText: 'no' },
			],
			required: true,
			hideComments: true,
		},
		{
			questionId: 27,
			categoryId: 7,
			questionNo: 5,
			answerOptionsField: AnswerOptionsField.BUTTONS,
			questionText: 'noLegalCasesOrComplaints',
			answerOptions: [
				{ optionId: 1, optionText: 'yes' },
				{ optionId: 2, optionText: 'no' },
			],
			required: true,
			hideComments: true,
		},
		{
			questionId: 28,
			categoryId: 7,
			questionNo: 6,
			answerOptionsField: AnswerOptionsField.BUTTONS,
			questionText: 'neverSeekFinancialTransactions',
			answerOptions: [
				{ optionId: 1, optionText: 'yes' },
				{ optionId: 2, optionText: 'no' },
			],
			required: true,
			hideComments: true,
		},
		{
			questionId: 29,
			categoryId: 7,
			questionNo: 7,
			answerOptionsField: AnswerOptionsField.BUTTONS,
			questionText: 'myFinancialDealingsAreHonest',
			answerOptions: [
				{ optionId: 1, optionText: 'yes' },
				{ optionId: 2, optionText: 'no' },
			],
			required: true,
			hideComments: true,
		},
		{
			questionId: 31,
			categoryId: 7,
			questionNo: 8,
			answerOptionsField: AnswerOptionsField.BUTTONS,
			questionText: 'iConsumeAlcoholOccassionally',
			answerOptions: [
				{ optionId: 1, optionText: 'yes' },
				{ optionId: 2, optionText: 'no' },
			],
			required: true,
			hideComments: true,
		},
		{
			questionId: 32,
			categoryId: 7,
			questionNo: 9,
			answerOptionsField: AnswerOptionsField.BUTTONS,
			questionText: 'iConsumeNonVegFoodOccassionally',
			answerOptions: [
				{ optionId: 1, optionText: 'yes' },
				{ optionId: 2, optionText: 'no' },
			],
			required: true,
			hideComments: true,
		},
		{
			questionId: 33,
			categoryId: 7,
			questionNo: 10,
			answerOptionsField: AnswerOptionsField.BUTTONS,
			questionText: 'iAmOfReasonablySoundPhysicalHealth',
			answerOptions: [
				{ optionId: 1, optionText: 'yes' },
				{ optionId: 2, optionText: 'no' },
			],
			required: true,
			hideComments: true,
		},
		{
			questionId: 34,
			categoryId: 7,
			questionNo: 11,
			answerOptionsField: AnswerOptionsField.BUTTONS,
			questionText: 'iAmNotOnAnyMedication',
			answerOptions: [
				{ optionId: 1, optionText: 'yes' },
				{ optionId: 2, optionText: 'no' },
			],
			required: true,
			hideComments: true,
		},
		// {
		// 	questionId: 30,
		// 	categoryId: 7,
		// 	questionNo: 12,
		// 	answerOptionsField: AnswerOptionsField.RADIO,
		// 	questionText: 'haveANewBorn',
		// 	answerOptions: [
		// 		{ optionId: 1, optionText: 'yes' },
		// 		{ optionId: 2, optionText: 'no' },
		// 	],
		// 	required: true,
		// 	hideComments: true,
		// },
	],
	questionsToAskInPreceptorInterview: {
		suggestedQuestionsToAsk: [],
		mandatoryQuestionsToAsk: [],
	},
	questionsToAskInApplicantInterview: {
		suggestedQuestionsToAsk: [],
		mandatoryQuestionsToAsk: [],
	},
	questionsToAskInFamilyMemberInterview: {
		suggestedQuestionsToAsk: [],
		mandatoryQuestionsToAsk: [],
	},
	questionsToAskInOtherReferenceInterview: [],
};

export const CharacterAndHabitsUIConfig: CategoryStepUIConfig = {
	id: EvaluationCategoryID.CHARACTER_AND_HABITS,
	title: 'characterAndHabits',
	subTitle: 'expectedToBeRoleModel',
	questions: [
		{
			questionId: 9,
			categoryId: 3,
			questionNo: 1,
			answerOptionsField: AnswerOptionsField.BUTTONS,
			questionText: 'iCommitToUpholdBrotherhoodInMyCenter',
			answerOptions: [
				{ optionId: 1, optionText: 'yes' },
				{ optionId: 2, optionText: 'somewhat' },
				{ optionId: 3, optionText: 'no' },
			],
			required: true,
			hideComments: true,
		},
		{
			questionId: 10,
			categoryId: 3,
			questionNo: 2,
			answerOptionsField: AnswerOptionsField.BUTTONS,
			questionText: 'iCommitToUpholdMyBehaviour',
			answerOptions: [
				{ optionId: 1, optionText: 'yes' },
				{ optionId: 2, optionText: 'somewhat' },
				{ optionId: 3, optionText: 'no' },
			],
			required: true,
			hideComments: true,
		},
		{
			questionId: 11,
			categoryId: 3,
			questionNo: 3,
			answerOptionsField: AnswerOptionsField.BUTTONS,
			questionText: 'noExtremeBehaviourSuchAsAbuseOrAnger',
			answerOptions: [
				{ optionId: 1, optionText: 'yes' },
				{ optionId: 2, optionText: 'somewhat' },
				{ optionId: 3, optionText: 'no' },
			],
			required: true,
			hideComments: true,
		},
		{
			questionId: 12,
			categoryId: 3,
			questionNo: 4,
			answerOptionsField: AnswerOptionsField.BUTTONS,
			questionText: 'iReconfirmMaritalHarmonyAndFidelity',
			answerOptions: [
				{ optionId: 1, optionText: 'yes' },
				{ optionId: 2, optionText: 'somewhat' },
				{ optionId: 3, optionText: 'no' },
			],
			required: true,
			hideComments: true,
		},
		{
			questionId: 36,
			categoryId: 3,
			questionNo: 5,
			answerOptionsField: AnswerOptionsField.BUTTONS,
			questionText: 'iReconfirmRepurationAndFinancialIntegrity',
			answerOptions: [
				{ optionId: 1, optionText: 'yes' },
				{ optionId: 2, optionText: 'iReconfirmOrNo' },
				{ optionId: 3, optionText: 'iWithdraw' },
			],
			required: true,
			hideComments: true,
		},
		{
			questionId: 152,
			categoryId: 3,
			questionNo: 6,
			questionText: 'pleaseElaborateYourBehaviour',
			required: true,
			commentsRequired: true,
		},
	],
	questionsToAskInPreceptorInterview: {
		suggestedQuestionsToAsk: [],
		mandatoryQuestionsToAsk: [
			{
				questionId: 228,
				categoryId: 3,
				questionNo: 1,
				answerOptionsField: AnswerOptionsField.SELECT,
				questionText: 'doYouFindCandidatesConductAcceptable',
				answerOptions: [
					{ optionId: 1, optionText: 'yes' },
					{ optionId: 2, optionText: 'no' },
					{ optionId: 3, optionText: 'somewhat' },
					{ optionId: 4, optionText: 'doNotKnow' },
				],
				required: true,
				commentsText: 'proposersComments',
			},
			{
				questionId: 229,
				categoryId: 3,
				questionNo: 2,
				answerOptionsField: AnswerOptionsField.SELECT,
				questionText: 'haveYouHeardOfAnySpecificSeriousAberrations',
				answerOptions: [
					{ optionId: 1, optionText: 'preceptorConfirmsFavourableConduct' },
					{ optionId: 2, optionText: 'preceptorHasHeardSomeMinorIssues' },
					{ optionId: 3, optionText: 'preceptorHasMajorConcerns' },
					{ optionId: 4, optionText: 'preceptorDoesNotKnow' },
				],
				required: true,
				commentsText: 'proposersComments',
			},
			{
				questionId: 230,
				categoryId: 3,
				questionNo: 3,
				answerOptionsField: AnswerOptionsField.SELECT,
				questionText: 'haveYouSeenThisCandidateWorkHarmoniously',
				answerOptions: [
					{
						optionId: 1,
						optionText: 'yesPreceptorHaveSeenCandidateWorkReasonably',
					},
					{
						optionId: 2,
						optionText:
							'preceptorHasSomeConcernsAsIHaveSeenCandidateAsNotCollaborative',
					},
					{ optionId: 3, optionText: 'preceptorDoesNotKnow' },
				],
				required: true,
				commentsText: 'proposersComments',
			},
		],
	},
	questionsToAskInApplicantInterview: {
		suggestedQuestionsToAsk: [],
		mandatoryQuestionsToAsk: [
			{
				questionId: 210,
				categoryId: 3,
				questionNo: 1,
				answerOptionsField: AnswerOptionsField.SELECT,
				questionText: 'shareThreePositiveQualitiesInYourself',
				answerOptions: [
					{ optionId: 1, optionText: 'convincedAboutThreeQualities' },
					{ optionId: 2, optionText: 'somewhatConvinced' },
					{ optionId: 3, optionText: 'notSure' },
					{ optionId: 4, optionText: 'doesNotSeemToHaveQualities' },
				],
				required: true,
				commentsText: 'proposersComments',
			},
			{
				questionId: 211,
				categoryId: 3,
				questionNo: 2,
				answerOptionsField: AnswerOptionsField.SELECT,
				questionText: 'reconfirmThatNoCharacterOrReputationalIssues',
				answerOptions: [
					{ optionId: 1, optionText: 'appearsLikeACleanCase' },
					{ optionId: 2, optionText: 'someMinorConcerns' },
					{ optionId: 3, optionText: 'majorConcerns' },
					{ optionId: 4, optionText: 'dontKnow' },
				],
				required: true,
				commentsText: 'proposersComments',
			},
		],
	},
	questionsToAskInFamilyMemberInterview: {
		suggestedQuestionsToAsk: [],
		mandatoryQuestionsToAsk: [
			{
				questionId: 236,
				categoryId: 3,
				questionNo: 1,
				answerOptionsField: AnswerOptionsField.SELECT,
				questionText: 'confirmThatYourFamilyLifeIsReasonablyHarmonious',
				answerOptions: [
					{
						optionId: 1,
						optionText: 'spouseOrFamilyMemberConfirmsHarmoniousFamily',
					},
					{
						optionId: 2,
						optionText: 'spouseOrFamilyMemberSuggestsSomeConcern',
					},
					{
						optionId: 3,
						optionText: 'spouseOrFamilyMemberConfirmsSevereConcerns',
					},
					{ optionId: 4, optionText: 'spouseOrFamilyMemberDoesNotComment' },
				],
				required: true,
				commentsText: 'proposersComments',
			},
			{
				questionId: 237,
				categoryId: 3,
				questionNo: 2,
				answerOptionsField: AnswerOptionsField.SELECT,
				questionText: 'confirmThatThereIsNoCaseOfAbuse',
				answerOptions: [
					{
						optionId: 1,
						optionText: 'spouseOrFamilyMemberConfirmsHarmoniousFamily',
					},
					{
						optionId: 2,
						optionText: 'spouseOrFamilyMemberSuggestsSomeConcern',
					},
					{
						optionId: 3,
						optionText: 'spouseOrFamilyMemberConfirmsSevereConcerns',
					},
					{ optionId: 4, optionText: 'spouseOrFamilyMemberDoesNotComment' },
				],
				required: true,
				commentsText: 'proposersComments',
			},
			{
				questionId: 238,
				categoryId: 3,
				questionNo: 3,
				answerOptionsField: AnswerOptionsField.SELECT,
				questionText: 'confirmThatYourSpousesFinancialDealingsArePure',
				answerOptions: [
					{
						optionId: 1,
						optionText: 'spouseOrFamilyMemberConfirmsHarmoniousFamily',
					},
					{
						optionId: 2,
						optionText: 'spouseOrFamilyMemberSuggestsSomeConcern',
					},
					{
						optionId: 3,
						optionText: 'spouseOrFamilyMemberConfirmsSevereConcerns',
					},
					{ optionId: 4, optionText: 'spouseOrFamilyMemberDoesNotComment' },
				],
				required: true,
				commentsText: 'proposersComments',
			},
			{
				questionId: 239,
				categoryId: 3,
				questionNo: 4,
				answerOptionsField: AnswerOptionsField.SELECT,
				questionText: 'spouseOrFamilyMembersThreeSpecialQualities',
				answerOptions: [
					{
						optionId: 1,
						optionText: 'spouseOrFamilyMemberConfirmsHarmoniousFamily',
					},
					{
						optionId: 2,
						optionText: 'spouseOrFamilyMemberSuggestsSomeConcern',
					},
					{
						optionId: 3,
						optionText: 'spouseOrFamilyMemberConfirmsSevereConcerns',
					},
					{ optionId: 4, optionText: 'spouseOrFamilyMemberDoesNotComment' },
				],
				required: true,
				commentsText: 'proposersComments',
			},
		],
	},
	questionsToAskInOtherReferenceInterview: [],
};

export const SupportUIConfig: CategoryStepUIConfig = {
	id: EvaluationCategoryID.SUPPORT,
	title: 'familySupport',
	subTitle: 'exploreIfConditionsAroundAreConducive',
	questions: [
		{
			questionId: 19,
			categoryId: 5,
			questionNo: 1,
			answerOptionsField: AnswerOptionsField.BUTTONS,
			questionText: 'myFamilyAndIAreComfortableWithSeekersVisitingOurHome',
			answerOptions: [
				{ optionId: 1, optionText: 'yes' },
				{ optionId: 2, optionText: 'somewhat' },
				{ optionId: 3, optionText: 'no' },
			],
			required: true,
			hideComments: true,
		},
		{
			questionId: 20,
			categoryId: 5,
			questionNo: 2,
			answerOptionsField: AnswerOptionsField.BUTTONS,
			questionText: 'iHaveExplicitlyAskedSupportFromFamily',
			answerOptions: [
				{ optionId: 1, optionText: 'yes' },
				{ optionId: 2, optionText: 'somewhat' },
				{ optionId: 3, optionText: 'no' },
			],
			required: true,
			hideComments: true,
		},
		{
			questionId: 154,
			categoryId: 5,
			questionNo: 3,
			questionText: 'pleaseDescribeYourFamily',
			required: true,
			commentsRequired: true,
		},
	],
	questionsToAskInPreceptorInterview: {
		suggestedQuestionsToAsk: [],
		mandatoryQuestionsToAsk: [
			{
				questionId: 232,
				categoryId: 5,
				questionNo: 1,
				answerOptionsField: AnswerOptionsField.SELECT,
				questionText: 'haveYouMetTheCandidatesFamily',
				answerOptions: [
					{
						optionId: 1,
						optionText: 'preceptorKnowsFamilyWillSupportCandidate',
					},
					{ optionId: 2, optionText: 'preceptorConcernedOnFamilySupport' },
					{ optionId: 3, optionText: 'preceptorDoesNotKnow' },
				],
				required: true,
				commentsText: 'proposersComments',
			},
			{
				questionId: 233,
				categoryId: 5,
				questionNo: 2,
				answerOptionsField: AnswerOptionsField.SELECT,
				questionText: 'areYouAwareThatTheCandidateHasHarmoniousFamily',
				answerOptions: [
					{ optionId: 1, optionText: 'preceptorConfirmsHarmoniousFamily' },
					{ optionId: 2, optionText: 'preceptorSensesSomeStrainInFamily' },
					{ optionId: 3, optionText: 'preceptorConfirmsLackOfHarmonyInFamily' },
					{ optionId: 4, optionText: 'preceptorDoesNotKnow' },
				],
				required: true,
				commentsText: 'proposersComments',
			},
		],
	},
	questionsToAskInApplicantInterview: {
		suggestedQuestionsToAsk: [],
		mandatoryQuestionsToAsk: [
			{
				questionId: 214,
				categoryId: 5,
				questionNo: 1,
				answerOptionsField: AnswerOptionsField.SELECT,
				questionText: 'areYourFamilyAlsoPractitioners',
				answerOptions: [
					{ optionId: 1, optionText: 'yes' },
					{ optionId: 2, optionText: 'no' },
					{ optionId: 3, optionText: 'doNotKnow' },
				],
				required: true,
				commentsText: 'proposersComments',
			},
			{
				questionId: 215,
				categoryId: 5,
				questionNo: 2,
				answerOptionsField: AnswerOptionsField.SELECT,
				questionText: 'haveYouExplicitlySoughtTheirConsent',
				answerOptions: [
					{ optionId: 1, optionText: 'yes' },
					{ optionId: 2, optionText: 'informedAndTheyAreFine' },
					{ optionId: 3, optionText: 'notInformed' },
					{ optionId: 4, optionText: 'informedButFamilyIsCurrentlyAgainst' },
				],
				required: true,
				commentsText: 'proposersComments',
			},
			{
				questionId: 216,
				categoryId: 5,
				questionNo: 3,
				answerOptionsField: AnswerOptionsField.SELECT,
				questionText: 'confirmYouHaveAHarmoniousFamilyLife',
				answerOptions: [
					{ optionId: 1, optionText: 'yes' },
					{ optionId: 2, optionText: 'no' },
					{ optionId: 3, optionText: 'somewhat' },
					{ optionId: 4, optionText: 'doNotKnow' },
				],
				required: true,
				commentsText: 'proposersComments',
			},
		],
	},
	questionsToAskInFamilyMemberInterview: {
		suggestedQuestionsToAsk: [],
		mandatoryQuestionsToAsk: [
			{
				questionId: 242,
				categoryId: 5,
				questionNo: 1,
				answerOptionsField: AnswerOptionsField.SELECT,
				questionText: 'areYourSupportiveOfYourSpouseServing',
				answerOptions: [
					{ optionId: 1, optionText: 'familyMemberConfirmsSupport' },
					{ optionId: 2, optionText: 'familyMemberLukeWarmAboutSupport' },
					{ optionId: 3, optionText: 'familyMemberNotSupportive' },
					{ optionId: 4, optionText: 'familyMemberDoesNotComment' },
				],
				required: true,
				commentsText: 'proposersComments',
			},
			{
				questionId: 243,
				categoryId: 5,
				questionNo: 2,
				answerOptionsField: AnswerOptionsField.SELECT,
				questionText: 'areYouComfortableWithAbhyasisVisitingYourHome',
				answerOptions: [
					{ optionId: 1, optionText: 'familyMemberConfirmsSupport' },
					{ optionId: 2, optionText: 'familyMemberLukeWarmAboutSupport' },
					{ optionId: 3, optionText: 'familyMemberNotSupportive' },
					{ optionId: 4, optionText: 'familyMemberDoesNotComment' },
				],
				required: true,
				commentsText: 'proposersComments',
			},
			{
				questionId: 244,
				categoryId: 5,
				questionNo: 3,
				answerOptionsField: AnswerOptionsField.SELECT,
				questionText: 'hasYourSpouseOrFamilySoughtExplicitConsent',
				answerOptions: [
					{
						optionId: 1,
						optionText: 'familyMemberConfirmsConsentSoughtGranted',
					},
					{
						optionId: 2,
						optionText: 'familyMemberConfirmsConsentSoughtNotGranted',
					},
					{
						optionId: 3,
						optionText: 'familyMemberConfirmsCandidateDidNotSeekConsent',
					},
					{ optionId: 4, optionText: 'familyMemberDoesNotComment' },
				],
				required: true,
				commentsText: 'proposersComments',
			},
			{
				questionId: 245,
				categoryId: 5,
				questionNo: 4,
				answerOptionsField: AnswerOptionsField.SELECT,
				questionText: 'yourAssociationAndRelationshipWithSM',
				answerOptions: [
					{ optionId: 1, optionText: 'familyMemberIsPreceptor' },
					{ optionId: 2, optionText: 'familyMemberIsAnAbhyasi' },
					{
						optionId: 3,
						optionText: 'familyMemberIsNotAnAbhyasiButFavourable',
					},
					{ optionId: 4, optionText: 'familyMemberIsAgainstSM' },
					{ optionId: 5, optionText: 'familyMemberDoesNotComment' },
				],
				required: true,
				commentsText: 'proposersComments',
			},
		],
	},
	questionsToAskInOtherReferenceInterview: [],
};

export const UnderstandingOfHeartfulnessUIConfig: CategoryStepUIConfig = {
	id: EvaluationCategoryID.UNDERSTANDING_OF_HEARTFULNESS,
	title: 'understandingHeartfulness',
	subTitle: 'abilityToExplainHeartfulness',
	questions: [
		{
			questionId: 21,
			categoryId: 6,
			questionNo: 1,
			answerOptionsField: AnswerOptionsField.BUTTONS,
			questionText: 'iCanExplainBasicPracticesOfHeartfulness',
			answerOptions: [
				{ optionId: 1, optionText: 'yes' },
				{ optionId: 2, optionText: 'somewhat' },
				{ optionId: 3, optionText: 'no' },
			],
			required: true,
			hideComments: true,
		},
		{
			questionId: 22,
			categoryId: 6,
			questionNo: 2,
			answerOptionsField: AnswerOptionsField.BUTTONS,
			questionText: 'iHaveReadBasicLiteratureOfTheMission',
			answerOptions: [
				{ optionId: 1, optionText: 'yes' },
				{ optionId: 2, optionText: 'somewhat' },
				{ optionId: 3, optionText: 'no' },
			],
			required: true,
			hideComments: true,
		},
		{
			questionId: 37,
			categoryId: 6,
			questionNo: 3,
			answerOptionsField: AnswerOptionsField.BUTTONS,
			questionText: 'iHaveInterestInReadingTheMissionLiterature',
			answerOptions: [
				{ optionId: 1, optionText: 'yes' },
				{ optionId: 2, optionText: 'somewhat' },
				{ optionId: 3, optionText: 'no' },
			],
			required: true,
			hideComments: true,
		},
		{
			questionId: 155,
			categoryId: 6,
			questionNo: 4,
			questionText: 'whatIsHfnToYou',
			required: true,
			commentsRequired: true,
		},
	],
	questionsToAskInPreceptorInterview: {
		suggestedQuestionsToAsk: [],
		mandatoryQuestionsToAsk: [
			{
				questionId: 234,
				categoryId: 6,
				questionNo: 1,
				answerOptionsField: AnswerOptionsField.SELECT,
				questionText: 'doYouBelieveCandidateUnderstandsBasicPrinciples',
				answerOptions: [
					{
						optionId: 1,
						optionText: 'preceptorConfirmsCandidatesUnderstanding',
					},
					{ optionId: 2, optionText: 'preceptorIsDoubtful' },
					{
						optionId: 3,
						optionText: 'preceptorConfirmsCandidateDoesNotUnderstand',
					},
					{ optionId: 4, optionText: 'preceptorDoesNotKnow' },
				],
				required: true,
				commentsText: 'proposersComments',
			},
			{
				questionId: 235,
				categoryId: 6,
				questionNo: 2,
				answerOptionsField: AnswerOptionsField.SELECT,
				questionText: 'doYouBelieveCandidateHasReasonableCommunicationSkills',
				answerOptions: [
					{ optionId: 1, optionText: 'preceptorConfirmsSomewhatYes' },
					{ optionId: 2, optionText: 'preceptorConfirmsNo' },
					{ optionId: 3, optionText: 'preceptorConfirmsYes' },
				],
				required: true,
				commentsText: 'proposersComments',
			},
		],
	},
	questionsToAskInApplicantInterview: {
		suggestedQuestionsToAsk: [],
		mandatoryQuestionsToAsk: [
			{
				questionId: 217,
				categoryId: 6,
				questionNo: 1,
				answerOptionsField: AnswerOptionsField.SELECT,
				questionText: 'haveYouReadSahajMargLiterature',
				answerOptions: [
					{ optionId: 1, optionText: 'readALot' },
					{ optionId: 2, optionText: 'readSome' },
					{ optionId: 3, optionText: 'readVeryFew' },
					{ optionId: 4, optionText: 'notRead' },
				],
				required: true,
				commentsText: 'proposersComments',
			},
			{
				questionId: 218,
				categoryId: 6,
				questionNo: 2,
				answerOptionsField: AnswerOptionsField.SELECT,
				questionText: 'interestedInMissionLiterature',
				answerOptions: [
					{ optionId: 1, optionText: 'absolutelyYes' },
					{ optionId: 2, optionText: 'somewhatYes' },
					{ optionId: 3, optionText: 'notVeryMuch' },
					{ optionId: 4, optionText: 'cannotReadOrNotInterested' },
				],
				required: true,
				commentsText: 'proposersComments',
			},
		],
	},
	questionsToAskInFamilyMemberInterview: {
		suggestedQuestionsToAsk: [],
		mandatoryQuestionsToAsk: [],
	},
	questionsToAskInOtherReferenceInterview: [],
};

export const DietaryHabitsAndHealthUIConfig: CategoryStepUIConfig = {
	id: EvaluationCategoryID.DIETARY_HABITS_AND_HEALTH,
	title: 'dietaryHabitsAndHealth',
	functionaryTitle: 'basicEligibility',
	subTitle: 'expectedToBeRoleModel',
	questions: [
		{
			questionId: 38,
			categoryId: 8,
			questionNo: 1,
			answerOptionsField: AnswerOptionsField.BUTTONS,
			questionText: 'iDoNotConsumeAlcohol',
			answerOptions: [
				{ optionId: 1, optionText: 'doNotConsume' },
				{ optionId: 2, optionText: 'consumeOccasionallyAndWillingToGiveUp' },
				{ optionId: 3, optionText: 'cannotGiveUp' },
			],
			required: true,
			displayAnswerOptionsVertically: true,
			hideComments: true,
		},
		{
			questionId: 39,
			categoryId: 8,
			questionNo: 2,
			answerOptionsField: AnswerOptionsField.BUTTONS,
			questionText: 'iDoNotConsumeNonVegFood',
			answerOptions: [
				{ optionId: 1, optionText: 'doNotConsumeNonVeg' },
				{
					optionId: 2,
					optionText: 'consumeNonVegOccasionallyAndWillingToGiveUp',
				},
				{ optionId: 3, optionText: 'cannotGiveUpNonVeg' },
			],
			required: true,
			displayAnswerOptionsVertically: true,
			hideComments: true,
		},
		{
			questionId: 40,
			categoryId: 8,
			questionNo: 3,
			answerOptionsField: AnswerOptionsField.BUTTONS,
			questionText: 'iAmOfReasonablySoundPhysicalHealth',
			answerOptions: [
				{ optionId: 1, optionText: 'yes' },
				{ optionId: 2, optionText: 'somewhat' },
				{ optionId: 3, optionText: 'no' },
			],
			required: true,
			hideComments: true,
		},
		{
			questionId: 41,
			categoryId: 8,
			questionNo: 4,
			answerOptionsField: AnswerOptionsField.BUTTONS,
			questionText: 'notStudyingHypnotherapy',
			answerOptions: [
				{ optionId: 1, optionText: 'iMNotPracticing' },
				{ optionId: 2, optionText: 'iMPracticing' },
			],
			required: true,
			displayAnswerOptionsVertically: true,
			hideComments: true,
		},
		{
			questionId: MedicationQuestionId.NEW_APPLICATION_ONE,
			categoryId: 8,
			questionNo: 5,
			answerOptionsField: AnswerOptionsField.BUTTONS,
			questionText: 'iAmOnMedication',
			answerOptions: [
				{ optionId: 1, optionText: 'yesIMOnMedication' },
				{ optionId: 2, optionText: 'iMNotOnMedication' },
			],
			required: true,
			displayAnswerOptionsVertically: true,
			hideComments: true,
		},
		{
			questionId: MedicationQuestionId.NEW_APPLICATION_TWO,
			categoryId: 8,
			questionNo: 6,
			answerOptionsField: AnswerOptionsField.BUTTONS,
			questionText: 'iMNotAddictedToSubstances',
			answerOptions: [
				{ optionId: 1, optionText: 'iMNotAddicted' },
				{ optionId: 2, optionText: 'iMOrWasAddicted' },
			],
			required: true,
			displayAnswerOptionsVertically: true,
			hideComments: true,
		},
		{
			questionId: MedicationQuestionId.NEW_APPLICATION_THREE,
			categoryId: 8,
			questionNo: 7,
			answerOptionsField: AnswerOptionsField.BUTTONS,
			questionText: 'iDoNotHaveConditions',
			answerOptions: [
				{ optionId: 1, optionText: 'iHaveTheConditionNow' },
				{ optionId: 2, optionText: 'iHadItInThePast' },
				{ optionId: 3, optionText: 'iDontHaveTheseConditions' },
			],
			required: true,
			displayAnswerOptionsVertically: true,
			hideComments: true,
		},
		{
			questionId: 156,
			categoryId: 8,
			questionNo: 8,
			questionText: 'areThereAnyHealthConditions',
			required: true,
			commentsRequired: true,
		},
	],
	questionsToAskInPreceptorInterview: {
		suggestedQuestionsToAsk: [],
		mandatoryQuestionsToAsk: [],
	},
	questionsToAskInApplicantInterview: {
		suggestedQuestionsToAsk: [],
		mandatoryQuestionsToAsk: [
			{
				questionId: 219,
				categoryId: 7,
				questionNo: 1,
				answerOptionsField: AnswerOptionsField.SELECT,
				questionText: 'inCaseYouDoNotMaintainVegDiet',
				answerOptions: [
					{ optionId: 1, optionText: 'vegetarian' },
					{ optionId: 2, optionText: 'occasionalNonVeg' },
					{ optionId: 3, optionText: 'habitualNonVegCanTryToGiveUp' },
					{ optionId: 4, optionText: 'habitualNonVegNotWillingToGiveUp' },
				],
				required: true,
				commentsText: 'proposersComments',
			},
			{
				questionId: 220,
				categoryId: 7,
				questionNo: 2,
				answerOptionsField: AnswerOptionsField.SELECT,
				questionText: 'inCaseYouConsumeAlcohol',
				answerOptions: [
					{ optionId: 1, optionText: 'doNotConsumeAlcohol' },
					{
						optionId: 2,
						optionText: 'nonHabitualAlcoholConsumptionWillingToGiveUp',
					},
					{ optionId: 3, optionText: 'habituallyConsumeAlcoholCanTryToGiveUp' },
					{
						optionId: 4,
						optionText: 'habituallyConsumeAlcoholNotWillingToGiveUp',
					},
				],
				required: true,
				commentsText: 'proposersComments',
			},
			{
				questionId: MedicationQuestionId.APPLICANT_INTERVIEW,
				categoryId: 7,
				questionNo: 3,
				answerOptionsField: AnswerOptionsField.SELECT,
				questionText: 'confirmYouAreNotOnPsychiatricDrugs',
				answerOptions: [
					{ optionId: 1, optionText: 'notOnPsychiatricMedication' },
					{
						optionId: 2,
						optionText: 'onPsychiatricMedicationAndCanSharePrecription',
					},
					{
						optionId: 3,
						optionText: 'onPsychiatricMedicationAndCantSharePrecription',
					},
				],
				required: true,
				commentsText: 'proposersComments',
			},
		],
	},
	questionsToAskInFamilyMemberInterview: {
		suggestedQuestionsToAsk: [],
		mandatoryQuestionsToAsk: [
			{
				questionId: 246,
				categoryId: 7,
				questionNo: 1,
				answerOptionsField: AnswerOptionsField.SELECT,
				questionText: 'confirmFamilyMemberMaintainsVegetarianDiet',
				answerOptions: [
					{ optionId: 1, optionText: 'familyMemberConfirmsCompliance' },
					{
						optionId: 2,
						optionText: 'familyMemberConfirmsOccassionalNonCompliance',
					},
					{
						optionId: 3,
						optionText: 'familyMemberConfirmsHabitualNonCompliance',
					},
					{ optionId: 4, optionText: 'familyMemberDoesNotComment' },
				],
				required: true,
				commentsText: 'proposersComments',
			},
			{
				questionId: 247,
				categoryId: 7,
				questionNo: 2,
				answerOptionsField: AnswerOptionsField.SELECT,
				questionText: 'confirmFamilyMemberDoesNotConsumeAlcohol',
				answerOptions: [
					{ optionId: 1, optionText: 'familyMemberConfirmsCompliance' },
					{
						optionId: 2,
						optionText: 'familyMemberConfirmsOccassionalNonCompliance',
					},
					{
						optionId: 3,
						optionText: 'familyMemberConfirmsHabitualNonCompliance',
					},
					{ optionId: 4, optionText: 'familyMemberDoesNotComment' },
				],
				required: true,
				commentsText: 'proposersComments',
			},
			{
				questionId: MedicationQuestionId.FAMILY_MEMBER_INTERVIEW,
				categoryId: 7,
				questionNo: 3,
				answerOptionsField: AnswerOptionsField.SELECT,
				questionText: 'confirmFamilyIsEmotionallyStable',
				answerOptions: [
					{ optionId: 1, optionText: 'notOnPsychiatricMedication' },
					{
						optionId: 2,
						optionText: 'onPsychiatricMedicationAndCanSharePrecription',
					},
					{
						optionId: 3,
						optionText: 'onPsychiatricMedicationAndCantSharePrecription',
					},
				],
				required: true,
				commentsText: 'proposersComments',
			},
		],
	},
	questionsToAskInOtherReferenceInterview: [],
};

export const PreWorkUIConfig: CategoryStepUIConfig = {
	id: EvaluationCategoryID.PRE_WORK,
	title: '',
	subTitle: '',
	questions: [
		{
			questionId: 51,
			categoryId: EvaluationCategoryID.PRE_WORK,
			questionNo: 1,
			answerOptionsField: AnswerOptionsField.RADIO,
			questionText: 'sadhanaAndSundaySatsangh',
			answerOptions: [
				{ optionId: 1, optionText: 'yes' },
				{ optionId: 2, optionText: 'somewhat' },
				{ optionId: 3, optionText: 'notReally' },
			],
			required: true,
		},
		{
			questionId: 52,
			categoryId: EvaluationCategoryID.PRE_WORK,
			questionNo: 2,
			answerOptionsField: AnswerOptionsField.RADIO,
			questionText: 'iHaveTaken2Sittings',
			answerOptions: [
				{ optionId: 1, optionText: 'yes12To16Sittings' },
				{ optionId: 2, optionText: 'somewhat8To12Sittings' },
				{ optionId: 3, optionText: 'notReallyLessThan8Sittings' },
			],
			required: true,
			displayAnswerOptionsVertically: true,
		},
		{
			questionId: PreWorkQuestionId.HEARTFUL_COMMUNICATION,
			categoryId: EvaluationCategoryID.PRE_WORK,
			questionNo: 3,
			answerOptionsField: AnswerOptionsField.RADIO,
			questionText: 'iHaveCompletedHeartfulCommunication',
			answerOptions: [
				{ optionId: 1, optionText: 'completed' },
				{ optionId: 2, optionText: 'startedAndYetToComplete' },
				{ optionId: 3, optionText: 'notStarted' },
			],
			required: true,
			displayAnswerOptionsVertically: true,
		},
		{
			questionId: 54,
			categoryId: EvaluationCategoryID.PRE_WORK,
			questionNo: 4,
			answerOptionsField: AnswerOptionsField.RADIO,
			questionText: 'iHaveCompletedReadingRealityAtDawn',
			answerOptions: [
				{ optionId: 1, optionText: 'completed' },
				{ optionId: 2, optionText: 'partiallyCompleteGreaterThan50Percent' },
				{ optionId: 3, optionText: 'startedRecently' },
				{ optionId: 4, optionText: 'notStarted' },
			],
			required: true,
			displayAnswerOptionsVertically: true,
		},
		{
			questionId: 55,
			categoryId: EvaluationCategoryID.PRE_WORK,
			questionNo: 5,
			answerOptionsField: AnswerOptionsField.RADIO,
			questionText: 'iHaveStudiedAndAppliedThePracticeDocument',
			answerOptions: [
				{ optionId: 1, optionText: 'yes' },
				{ optionId: 2, optionText: 'somewhat' },
				{ optionId: 3, optionText: 'notReally' },
			],
			required: true,
		},
		{
			questionId: PreWorkQuestionId.SYSTEM_GENERATED,
			categoryId: EvaluationCategoryID.PRE_WORK,
			questionNo: 6,
			answerOptionsField: AnswerOptionsField.SELECT,
			questionText: 'systemGeneratedStateOfPreWork',
			answerOptions: [
				{ optionId: 1, optionText: 'complete' },
				{ optionId: 2, optionText: 'partiallyComplete' },
				{ optionId: 3, optionText: 'incomplete' },
			],
			required: true,
			hideComments: true,
			disabled: true,
		},
		{
			questionId: PreWorkQuestionId.CANDIDATE_VIEW_ON_COMPLETION,
			categoryId: EvaluationCategoryID.PRE_WORK,
			questionNo: 7,
			answerOptionsField: AnswerOptionsField.SELECT,
			questionText: 'candidateViewOnCompletionOfPreWork',
			answerOptions: [
				{ optionId: 1, optionText: 'completedPreWork' },
				{ optionId: 2, optionText: 'completedPreWorkBut' },
				{ optionId: 3, optionText: 'partiallyCompletedPreWork' },
			],
			required: true,
		},
	],
	questionsToAskInPreceptorInterview: {
		suggestedQuestionsToAsk: [],
		mandatoryQuestionsToAsk: [],
	},
	questionsToAskInApplicantInterview: {
		suggestedQuestionsToAsk: [],
		mandatoryQuestionsToAsk: [],
	},
	questionsToAskInFamilyMemberInterview: {
		suggestedQuestionsToAsk: [],
		mandatoryQuestionsToAsk: [],
	},
	questionsToAskInOtherReferenceInterview: [],
};
