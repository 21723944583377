import { useCallback } from 'react';
import { useLocalStorage } from './useLocalStorage';
import {
	APP_INITIATED,
	BATCH_ID,
	FIREBASE_ID,
	KEYCLOAK_ID_TOKEN,
	LOGGED_IN_USER_ROLE,
	ME_PROFILE,
} from '../shared/Constants';
import { MeProfile } from '../shared/MySrcm';
import { UserRole } from '../shared/VolunteerApplicationServiceTypes';

export default function useUserSession() {
	const {
		storedValue: appBatchId,
		setValue: setAppBatchId,
		deleteValue: deleteAppBatchId,
	} = useLocalStorage<number | null>(BATCH_ID, null);
	const {
		storedValue: keycloakToken,
		setValue: setKeycloakToken,
		deleteValue: deleteKeycloakToken,
	} = useLocalStorage<string | null>(KEYCLOAK_ID_TOKEN, null);
	const {
		storedValue: meProfile,
		setValue: setMeProfile,
		deleteValue: deleteMeProfile,
	} = useLocalStorage<MeProfile | null>(ME_PROFILE, null);
	const {
		storedValue: srcmFirebaseId,
		setValue: setSrcmFirebaseId,
		deleteValue: deleteSrcmFirebaseId,
	} = useLocalStorage<string | null>(FIREBASE_ID, null);
	const {
		storedValue: loggedInUserRole,
		setValue: setLoggedInUserRole,
		deleteValue: deleteLoggedInUserRole,
	} = useLocalStorage<UserRole | null>(LOGGED_IN_USER_ROLE, null);
	const {
		storedValue: appInitiated,
		setValue: setAppInitiated,
		deleteValue: deleteAppInitiated,
	} = useLocalStorage<boolean | null>(APP_INITIATED, null);

	const clearAppBatchId = useCallback(() => {
		setAppBatchId(null);
		deleteAppBatchId();
	}, [setAppBatchId, deleteAppBatchId]);

	const clearKeycloakToken = useCallback(() => {
		setKeycloakToken(null);
		deleteKeycloakToken();
	}, [setKeycloakToken, deleteKeycloakToken]);

	const clearMeProfile = useCallback(() => {
		setMeProfile(null);
		deleteMeProfile();
	}, [setMeProfile, deleteMeProfile]);

	const clearSrcmFirebaseId = useCallback(() => {
		setSrcmFirebaseId(null);
		deleteSrcmFirebaseId();
	}, [setSrcmFirebaseId, deleteSrcmFirebaseId]);

	const clearLoggedInUserRole = useCallback(() => {
		setLoggedInUserRole(null);
		deleteLoggedInUserRole();
	}, [setLoggedInUserRole, deleteLoggedInUserRole]);

	const clearAppInitiated = useCallback(() => {
		setAppInitiated(null);
		deleteAppInitiated();
	}, [setAppInitiated, deleteAppInitiated]);

	return {
		appBatchId,
		setAppBatchId,
		clearAppBatchId,
		keycloakToken,
		setKeycloakToken,
		clearKeycloakToken,
		meProfile,
		setMeProfile,
		clearMeProfile,
		srcmFirebaseId,
		setSrcmFirebaseId,
		clearSrcmFirebaseId,
		loggedInUserRole,
		setLoggedInUserRole,
		clearLoggedInUserRole,
		appInitiated,
		setAppInitiated,
		clearAppInitiated,
	};
}
