import { Form } from 'antd';
import { useNewApplication } from './useNewApplication';
import { useWizard } from '../../../components/wizard/useWizard';
import { useCallback, useEffect } from 'react';
import { TrainingCourseDetailsForm } from '../../../components/TrainingCourseDetailsForm';
import { TrainingCourseDetailsType } from '../../../components/TrainingCourseDetailsType';

export const TrainingCourseDetailsStep = () => {
	const { trainingCourseDetails, setTrainingCourseDetails } =
		useNewApplication();
	const [form] = Form.useForm<TrainingCourseDetailsType>();
	const { setNextButtonDisabled, setCancelButtonDisabled } = useWizard();
	const values = Form.useWatch<TrainingCourseDetailsType>([], form);

	const handleFormValuesChange = useCallback(async () => {
		try {
			const fieldValues = await form.validateFields({ validateOnly: true });
			setNextButtonDisabled(false);
			setTrainingCourseDetails(fieldValues);
		} catch (e) {
			const fields = e as { values: TrainingCourseDetailsType };
			setNextButtonDisabled(true);
			setTrainingCourseDetails(fields.values);
		}
		setCancelButtonDisabled(false);
	}, [
		form,
		setTrainingCourseDetails,
		setCancelButtonDisabled,
		setNextButtonDisabled,
	]);

	useEffect(() => {
		void handleFormValuesChange();
	}, [handleFormValuesChange, values]);

	useEffect(() => {
		form.setFieldsValue(trainingCourseDetails);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [form]);

	return <TrainingCourseDetailsForm form={form} />;
};
