import { Form } from 'antd';
import { useNewApplication } from './useNewApplication';
import { useWizard } from '../../../components/wizard/useWizard';
import { useCallback, useEffect } from 'react';
import { FamilyMemberDetailsForm } from '../../../components/FamilyMemberDetailsForm';
import { FamilyMemberDetailsType } from '../../../components/FamilyMemberDetailsType';

export const FamilyMemberDetailsStep = () => {
	const { familyMemberContactDetails, setFamilyMemberContactDetails } =
		useNewApplication();
	const [form] = Form.useForm<FamilyMemberDetailsType>();
	const { setNextButtonDisabled, setCancelButtonDisabled } = useWizard();
	const values = Form.useWatch<FamilyMemberDetailsType>([], form);

	const handleFormValuesChange = useCallback(async () => {
		try {
			const fieldValues = await form.validateFields({ validateOnly: true });
			setNextButtonDisabled(false);
			setFamilyMemberContactDetails(fieldValues);
		} catch (e) {
			const fields = e as { values: FamilyMemberDetailsType };
			setNextButtonDisabled(true);
			setFamilyMemberContactDetails(fields.values);
		}
		setCancelButtonDisabled(false);
	}, [
		form,
		setFamilyMemberContactDetails,
		setCancelButtonDisabled,
		setNextButtonDisabled,
	]);

	useEffect(() => {
		void handleFormValuesChange();
	}, [handleFormValuesChange, values]);

	useEffect(() => {
		form.setFieldsValue(familyMemberContactDetails);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [form]);

	return <FamilyMemberDetailsForm form={form} />;
};
