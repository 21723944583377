import { useEffect, useState } from 'react';
import HfnBlackLogo from '../assets/images/hfn-black-125.svg';
import { ApplicantWebHeader } from './ApplicantWebHeader';
import { ApplicantMobileHeader } from './ApplicantMobileHeader';
import { MeProfile } from '../shared/MySrcm';
import defaultAvatar from '../assets/images/defaultAvatar.svg';
import { checkImage } from '../shared/Utils';

interface ApplicantAppHeaderProps {
	logout?: () => void;
	mySrcmProfile?: MeProfile | null;
}

export const ApplicantAppHeader = ({
	logout,
	mySrcmProfile,
}: ApplicantAppHeaderProps) => {
	const userName = `${mySrcmProfile?.first_name || ''} ${
		mySrcmProfile?.last_name || ''
	}`.trim();
	const profileImage = mySrcmProfile?.photo_url || '';

	const [validImage, setValidImage] = useState(profileImage);

	useEffect(() => {
		const fetchImage = async () => {
			const checkImageValue = await checkImage(profileImage);
			if (profileImage && checkImageValue) {
				setValidImage(profileImage);
			} else {
				setValidImage(defaultAvatar);
			}
		};

		void fetchImage();
	}, [profileImage]);

	return (
		<div className="flex justify-between items-center p-[8px] bg-white">
			<div className="flex items-center">
				<img src={HfnBlackLogo} alt="Header Logo" />
			</div>
			<ApplicantWebHeader
				logout={logout}
				username={userName}
				profileImage={validImage}
			/>
			<ApplicantMobileHeader
				logout={logout}
				username={userName}
				profileImage={validImage}
			/>
		</div>
	);
};
