import { Form, FormInstance, Input, Radio, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { PersonRole, VALID_STRING_INPUT_REGEX } from '../shared/Constants';
import { ApplicantInfo } from '../shared/VolunteerApplicationServiceTypes';
import { StepHeader } from './StepHeader';
import Icon from './ui-components/Icon';
import BlackChevronDown from '../assets/images/black-chevron-down.png';

interface FamilyMemberDetailsFormProps {
	form: FormInstance;
	applicant?: ApplicantInfo;
}

export const FamilyMemberDetailsForm: React.FC<
	FamilyMemberDetailsFormProps
> = ({ form, applicant }: FamilyMemberDetailsFormProps) => {
	const { t } = useTranslation();

	const familyMemberRelationship = Form.useWatch<string | undefined>(
		'familyMemberRelationship',
		form
	);

	return (
		<div className="flex flex-col mt-8">
			<Form
				name="basic"
				layout="vertical"
				size="middle"
				labelCol={{ span: 16 }}
				style={{ maxWidth: 600 }}
				initialValues={{ remember: true }}
				autoComplete="off"
				form={form}
			>
				<>
					<StepHeader
						heading={t('familyMemberContact')}
						description={
							applicant
								? t('provideCandidateFamilyMemberContact')
								: t('provideFamilyMemberContact')
						}
					/>
					<Form.Item
						label={
							<span className="font-sans font-normal text-xs text-secondary-black">
								{t('familyMemberName')}
							</span>
						}
						labelCol={{ className: '!pb-0' }}
						name="familyMemberName"
						rules={[
							{
								required: true,
								message: <span>{t('required')}</span>,
							},
							{
								pattern: VALID_STRING_INPUT_REGEX,
								message: <span>{t('invalidName')}</span>,
							},
						]}
						className="mb-4"
					>
						<Input
							className="h-11 text-sm font-normal font-sans text-light-gray rounded-lg"
							maxLength={255}
						/>
					</Form.Item>
					<Form.Item
						label={
							<span className="font-sans font-normal text-xs text-secondary-black">
								{t('familyMemberMobileNo')}
							</span>
						}
						labelCol={{ className: '!pb-0' }}
						name="familyMemberMobile"
						rules={[
							{
								required: true,
								message: <span>{t('required')}</span>,
							},
							{
								pattern: /^([+]\d{2})?\d{10}$/,
								message: <span>{t('invalidPhoneNo')}</span>,
							},
						]}
						className="mb-4"
					>
						<Input className="h-11 text-sm font-normal font-sans text-light-gray rounded-lg" />
					</Form.Item>
					<Form.Item
						label={
							<span className="font-sans font-normal text-xs text-secondary-black">
								{t('relationship')}
							</span>
						}
						labelCol={{ className: '!pb-0' }}
						name="familyMemberRelationship"
						rules={[
							{
								required: true,
								message: <span>{t('required')}</span>,
							},
						]}
						className="mb-4"
					>
						<Select
							className="h-11 text-sm font-normal font-sans text-light-gray rounded-lg"
							options={[
								{ value: 'spouse', label: t('spouse') },
								{ value: 'parent', label: t('parent') },
								{ value: 'other', label: t('other') },
							]}
							suffixIcon={
								<Icon src={BlackChevronDown} width={21} height={21} />
							}
							fieldNames={{ label: 'label', value: 'value' }}
							filterOption={(input, option) =>
								(option?.label ?? '')
									.toLowerCase()
									.includes(input.toLowerCase())
							}
						/>
					</Form.Item>
					{familyMemberRelationship === t('other') && (
						<Form.Item
							label={
								<span className="font-sans font-normal text-xs text-secondary-black">
									{t('relationshipOtherThanSpouseOrParent')}
								</span>
							}
							labelCol={{ className: '!pb-0' }}
							name="familyMemberRelationshipOtherThanSpouseOrParent"
							rules={[
								{
									required: true,
									message: <span>{t('required')}</span>,
								},
							]}
							className="mb-4"
						>
							<Input
								className="h-11 text-sm font-normal font-sans text-light-gray rounded-lg"
								maxLength={255}
							/>
						</Form.Item>
					)}
					<Form.Item
						label={
							<span className="font-sans font-normal text-xs text-secondary-black">
								{t('isTheFamilyMember')}
							</span>
						}
						labelCol={{ className: '!pb-0' }}
						name="familyMemberRole"
						rules={[
							{
								required: true,
								message: <span>{t('answerIsRequired')}</span>,
							},
						]}
						className="mb-4"
					>
						<Radio.Group>
							<Radio
								className="text-sm font-normal font-sans text-light-gray checked:text-primary-blue"
								value={PersonRole.AN_ABHYASI}
							>
								{t('anAbhyasi')}
							</Radio>
							<Radio
								className="text-sm font-normal font-sans text-light-gray checked:text-primary-blue"
								value={PersonRole.A_PRECEPTOR}
							>
								{t('aPreceptor')}
							</Radio>
							<Radio
								className="text-sm font-normal font-sans text-light-gray checked:text-primary-blue"
								value={PersonRole.NOT_YET_AN_ABHYASI}
							>
								{t('notYetAnAbhyasi')}
							</Radio>
						</Radio.Group>
					</Form.Item>
				</>
			</Form>
		</div>
	);
};
