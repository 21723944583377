import React from 'react';
import { Modal, Button } from 'antd';
import { useTranslation } from 'react-i18next';

interface ModalPopUpProps {
	visible: boolean; // Controls modal visibility
	onYes: () => void; // Callback for Yes action
	title?: string; // Optional title for the modal
	content?: React.ReactNode; // Optional content for the modal
	width?: number; // Optional width for the modal
	icon?: string;
	okText?: string;
}

export const InfoPopUp: React.FC<ModalPopUpProps> = ({
	visible,
	onYes,
	title = '',
	content,
	icon,
	okText = 'ok',
}) => {
	const { t } = useTranslation();

	return (
		<Modal
			open={visible}
			footer={null}
			centered
			className="max-w-sm"
			closable={false} // Disables the close icon
		>
			{title && (
				<div className="text-center font-sans font-bold text-lg">
					{t(title)}
				</div>
			)}
			{icon && (
				<div className="flex justify-center my-4">
					<img src={icon} className="mx-auto my-0 w-75" />
				</div>
			)}
			{content && <div className="text-center my-5">{content}</div>}

			<div className="flex justify-center mt-4 ant-modal-footer">
				<Button
					type="primary"
					onClick={() => void onYes()}
					className="flex-1 h-9 bg-primary-blue text-white hover:bg-primary-blue/80 font-bold"
				>
					{t(okText)}
				</Button>
			</div>
		</Modal>
	);
};
