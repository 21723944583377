import { Form, FormInstance, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import { MultipleChoiceQuestion } from './MultipleChoiceQuestion';
import { CategoryStepUIConfig } from '../../../shared/CategoryStepUIConfig';
import { MultipleChoiceQuestionUIConfig } from '../../../shared/MultipleChoiceQuestionUIConfig';
import { CategoryAnswerType } from './CategoryAnswerType';
import { StepHeader } from '../../../components/StepHeader';

interface CategoryStepProps {
	uiConfig: CategoryStepUIConfig;
	form: FormInstance;
	updateFieldValue: (questionId: number, value: number) => void;
}

export const CategoryStep = ({
	uiConfig: { title, subTitle, questions },
	form,
	updateFieldValue,
}: CategoryStepProps) => {
	const { t } = useTranslation();
	const formValues = Form.useWatch<CategoryAnswerType>([], form);

	const allQuestions = questions.map(
		(question: MultipleChoiceQuestionUIConfig) => (
			<Card
				key={question.questionId}
				className="my-5 !border !rounded-lg !border-gray-250 !bg-gray-100"
			>
				<MultipleChoiceQuestion
					uiConfig={question}
					value={{
						selectedOption: formValues?.[question.questionId].selectedOption,
					}}
					updateFieldValue={updateFieldValue}
				/>
			</Card>
		)
	);

	return (
		<div className="flex flex-col h-full min-h-0 max-w-[600px]">
			<Form
				name="basic"
				layout="vertical"
				size="middle"
				labelCol={{ span: 16 }}
				style={{ maxWidth: 600 }}
				initialValues={{ remember: true }}
				autoComplete="off"
				form={form}
			>
				<StepHeader heading={t(title)} description={t(subTitle)} />
				{allQuestions}
			</Form>
		</div>
	);
};
