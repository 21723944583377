import { Checkbox, Form, FormInstance, Input, Radio } from 'antd';
import { useTranslation } from 'react-i18next';
import { PersonRole, VALID_STRING_INPUT_REGEX } from '../shared/Constants';
import { ApplicantInfo } from '../shared/VolunteerApplicationServiceTypes';
import { StepHeader } from './StepHeader';

interface OtherReferenceDetailsFormProps {
	form: FormInstance;
	applicant?: ApplicantInfo;
}

export const OtherReferenceDetailsForm: React.FC<
	OtherReferenceDetailsFormProps
> = ({ form, applicant }: OtherReferenceDetailsFormProps) => {
	const { t } = useTranslation();

	const otherReferenceProvided = Form.useWatch<boolean | undefined>(
		'otherReferenceProvided',
		form
	);

	return (
		<div className="flex flex-col mt-8">
			<Form
				name="basic"
				layout="vertical"
				size="middle"
				labelCol={{ span: 16 }}
				style={{ maxWidth: 600 }}
				initialValues={{ remember: true }}
				autoComplete="off"
				form={form}
			>
				<>
					<StepHeader
						heading={t('otherReference')}
						description={t('provideContactOfAnyOtherPerson')}
					/>
					<Form.Item name="otherReferenceProvided" valuePropName="checked">
						<Checkbox className="text-sm font-normal font-sans text-light-gray rounded-lg checked:text-primary-blue">
							{applicant
								? t('candidateWantToProvideContactOfAnotherPersonForReference')
								: t('iWantToProvideContactOfAnotherPersonForReference')}
						</Checkbox>
					</Form.Item>
					<div className={otherReferenceProvided ? 'block' : 'hidden'}>
						<Form.Item
							label={
								<span className="font-sans font-normal text-xs text-secondary-black">
									{t('otherReferenceName')}
								</span>
							}
							labelCol={{ className: '!pb-0' }}
							name="otherReferenceName"
							rules={[
								{
									required: otherReferenceProvided,
									message: <span>{t('required')}</span>,
								},
								{
									pattern: VALID_STRING_INPUT_REGEX,
									message: <span>{t('invalidName')}</span>,
								},
							]}
							className="mb-4"
						>
							<Input
								className="h-11 text-sm font-normal font-sans text-light-gray rounded-lg"
								disabled={!otherReferenceProvided}
								maxLength={255}
							/>
						</Form.Item>
						<Form.Item
							label={
								<span className="font-sans font-normal text-xs text-secondary-black">
									{t('otherReferenceMobile')}
								</span>
							}
							labelCol={{ className: '!pb-0' }}
							name="otherReferenceMobile"
							rules={[
								{
									required: otherReferenceProvided,
									message: <span>{t('required')}</span>,
								},
								{
									pattern: /^([+]\d{2})?\d{10}$/,
									message: <span>{t('invalidPhoneNo')}</span>,
								},
							]}
							className="mb-4"
						>
							<Input
								className="h-11 text-sm font-normal font-sans text-light-gray rounded-lg"
								disabled={!otherReferenceProvided}
							/>
						</Form.Item>
						<Form.Item
							label={
								<span className="font-sans font-normal text-xs text-secondary-black">
									{t('isThePerson')}
								</span>
							}
							labelCol={{ className: '!pb-0' }}
							name="otherReferenceRole"
							rules={[
								{
									required: otherReferenceProvided,
									message: <span>{t('answerIsRequired')}</span>,
								},
							]}
							className="mb-4"
						>
							<Radio.Group>
								<Radio
									className="text-sm font-normal font-sans text-light-gray checked:text-primary-blue"
									value={PersonRole.AN_ABHYASI}
								>
									{t('anAbhyasi')}
								</Radio>
								<Radio
									className="text-sm font-normal font-sans text-light-gray checked:text-primary-blue"
									value={PersonRole.A_PRECEPTOR}
								>
									{t('aPreceptor')}
								</Radio>
								<Radio
									className="text-sm font-normal font-sans text-light-gray checked:text-primary-blue"
									value={PersonRole.NOT_YET_AN_ABHYASI}
								>
									{t('notYetAnAbhyasi')}
								</Radio>
							</Radio.Group>
						</Form.Item>
					</div>
				</>
			</Form>
		</div>
	);
};
