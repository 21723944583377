import { useCallback, useMemo } from 'react';
import { NavigateOptions, useNavigate } from 'react-router-dom';
import { useBatchId } from './useBatchId';
import useUserSession from './useUserSession';

export const useExamModuleIds = () => {
	const navigate = useNavigate();
	const { navigateWithBatchId, queryParams } = useBatchId();
	const { appBatchId } = useUserSession();

	const examId = useMemo(() => {
		const examIdParam = Number(queryParams.get('examId'));

		if (typeof examIdParam !== 'number') {
			return -1;
		}

		return examIdParam;
	}, [queryParams]);

	const navigateWithExamId = useCallback(
		(route: string, examIdParam?: number, params?: NavigateOptions) => {
			const examIdToUse = examIdParam ? `${examIdParam}` : examId;
			queryParams.set('examId', `${examIdToUse}`);
			navigateWithBatchId(route, params);
		},
		[examId, navigateWithBatchId, queryParams]
	);

	const centerId = useMemo(() => {
		const centerIdParam = Number(queryParams.get('centerId'));

		if (typeof centerIdParam !== 'number') {
			return -1;
		}

		return centerIdParam;
	}, [queryParams]);

	const navigateWithCenterId = useCallback(
		(route: string, centerIdParam?: number, params?: NavigateOptions) => {
			const centerIdToUse = centerIdParam ? `${centerIdParam}` : centerId;
			queryParams.set('centerId', `${centerIdToUse}`);
			navigateWithBatchId(route, params);
		},
		[centerId, navigateWithBatchId, queryParams]
	);

	const kanhaBatchId = useMemo(() => {
		const kanhaBatchIdParam = Number(queryParams.get('kanhaBatchId'));

		if (typeof kanhaBatchIdParam !== 'number') {
			return -1;
		}

		return kanhaBatchIdParam;
	}, [queryParams]);

	const navigateWithKanhaBatchId = useCallback(
		(route: string, kanhaBatchIdParam?: number, params?: NavigateOptions) => {
			const kanhaBatchIdToUse = kanhaBatchIdParam
				? `${kanhaBatchIdParam}`
				: kanhaBatchId;
			queryParams.set('kanhaBatchId', `${kanhaBatchIdToUse}`);
			navigateWithBatchId(route, params);
		},
		[kanhaBatchId, navigateWithBatchId, queryParams]
	);

	const batchId = useMemo(() => {
		let batchIdParam = Number(queryParams.get('batchId'));
		if (!batchIdParam || batchIdParam === 0) {
			batchIdParam = Number(appBatchId);
		}

		if (typeof batchIdParam !== 'number') {
			return -1;
		}

		return batchIdParam;
	}, [queryParams, appBatchId]);

	const getRouteWithBatchId = useCallback(
		(route: string) => {
			queryParams.set('batchId', `${batchId}`);
			queryParams.delete('examId');
			queryParams.delete('centerId');
			queryParams.delete('kanhaBatchId');
			return `${route}?${queryParams.toString()}`;
		},
		[batchId, queryParams]
	);

	const navigateWithoutExamModuleIds = useCallback(
		(route: string, params?: NavigateOptions) => {
			navigate(`${getRouteWithBatchId(route)}`, params);
		},
		[getRouteWithBatchId, navigate]
	);

	return {
		examId,
		navigateWithExamId,
		centerId,
		navigateWithCenterId,
		kanhaBatchId,
		navigateWithKanhaBatchId,
		navigateWithoutExamModuleIds,
	};
};
