import { Form } from 'antd';
import { useNewApplication } from './useNewApplication';
import { useWizard } from '../../../components/wizard/useWizard';
import { useCallback, useEffect } from 'react';
import { OtherReferenceDetailsForm } from '../../../components/OtherReferenceDetailsForm';
import { OtherReferenceDetailsType } from '../../../components/OtherReferenceDetailsType';

export const OtherReferenceDetailsStep = () => {
	const { otherReferenceContactDetails, setOtherReferenceContactDetails } =
		useNewApplication();
	const [form] = Form.useForm<OtherReferenceDetailsType>();
	const { setNextButtonDisabled, setCancelButtonDisabled } = useWizard();
	const values = Form.useWatch<OtherReferenceDetailsType>([], form);

	const handleFormValuesChange = useCallback(async () => {
		try {
			const fieldValues = await form.validateFields({ validateOnly: true });
			setNextButtonDisabled(false);
			setOtherReferenceContactDetails(fieldValues);
		} catch (e) {
			const fields = e as { values: OtherReferenceDetailsType };
			setNextButtonDisabled(true);
			setOtherReferenceContactDetails(fields.values);
		}
		setCancelButtonDisabled(false);
	}, [
		form,
		setOtherReferenceContactDetails,
		setCancelButtonDisabled,
		setNextButtonDisabled,
	]);

	useEffect(() => {
		void handleFormValuesChange();
	}, [handleFormValuesChange, values]);

	useEffect(() => {
		form.setFieldsValue(otherReferenceContactDetails);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [form]);

	return <OtherReferenceDetailsForm form={form} />;
};
