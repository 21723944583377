import { useState } from 'react';
import { deleteItem, getItem, setItem } from '../service/LocalStorageService';

export const useLocalStorage = <T,>(keyName: string, defaultValue: T) => {
	const [storedValue, setStoredValue] = useState<T>(() =>
		getItem<T>(keyName, defaultValue)
	);

	const setValue = (newValue: T) => {
		setItem(keyName, newValue);
		setStoredValue(newValue);
	};

	const deleteValue = () => {
		deleteItem(keyName);
	};

	return { storedValue, setValue, deleteValue };
};
