import { useState, ReactNode, useCallback, FC, useEffect } from 'react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import CircularStepsWithDropdown from '../ui-components/StepsDropdown';
import { useNewApplication } from '../../pages/applicant/NewApplicationWizard/useNewApplication';
import { useWizard } from './useWizard';
import StepsVertical from '../ui-components/StepsVertical';

interface WizardProps {
	steps: Array<ReactNode>;
	activeStep: number;
	nextButtonText?: string;
	backButtonText?: string;
	submitButtonText?: string;
	cancelButtonText?: string;
	loading?: boolean;
	onNext: () => void;
	onPrevious: () => void;
	onSubmit: () => void;
	onCancel?: () => void;
	isFirstWizard?: boolean;
}

export const Wizard: FC<WizardProps> = ({
	steps,
	activeStep,
	nextButtonText,
	backButtonText,
	submitButtonText,
	loading = false,
	onNext,
	onPrevious,
	onSubmit,
	isFirstWizard = false,
}) => {
	const { t } = useTranslation();
	const { nextButtonDisabled, submitButtonDisabled, cancelButtonDisabled } =
		useWizard();
	const nextBtnText =
		isFirstWizard && activeStep === 0
			? t('confirmAndContinue')
			: nextButtonText ?? t('next');
	const backBtnText = backButtonText ?? t('previous');
	const submitBtnText = submitButtonText ?? t('submit');
	return (
		<div className={`flex flex-col sm:px-[8px]`}>
			<div className="h-full min-h-0">{steps[activeStep]}</div>
			<div className="flex flex-wrap w-full h-fit space-x-8 my-8">
				{/* Only show 'Previous' button if we're not on the first step of the second wizard */}
				{(!isFirstWizard || activeStep > 0) && (
					<Button
						className="flex-1 min-h-[3rem] font-bold font-sans text-primary-blue border-primary-blue"
						onClick={onPrevious}
						disabled={cancelButtonDisabled}
					>
						{backBtnText}
					</Button>
				)}
				{/* Show 'Confirm and Continue' only for the first step of the first wizard */}
				{isFirstWizard && activeStep === 0 && (
					<Button
						className="flex-1 min-h-[3rem] font-bold font-sans bg-primary-blue !text-white"
						type="primary"
						disabled={nextButtonDisabled}
						loading={loading}
						onClick={onNext}
					>
						{nextBtnText}
					</Button>
				)}
				{/* Show 'Next' button if not the last step of any wizard */}
				{((!isFirstWizard && activeStep !== steps.length - 1) ||
					(activeStep < steps.length - 1 && activeStep !== 0)) && (
					<Button
						className="flex-1 min-h-[3rem] font-bold font-sans bg-primary-blue !text-white"
						type="primary"
						onClick={onNext}
						disabled={nextButtonDisabled}
						loading={loading}
					>
						{nextBtnText}
					</Button>
				)}

				{/* Only for last step of each wizard */}
				{activeStep === steps.length - 1 && (
					<Button
						className="flex-1 min-h-[3rem] font-bold font-sans bg-primary-blue !text-white"
						type="primary"
						disabled={submitButtonDisabled}
						loading={loading}
						onClick={onSubmit}
					>
						{submitBtnText}
					</Button>
				)}
			</div>
		</div>
	);
};

export const WizardContainer = ({
	steps,
	stepDescription,
	onSubmitClick,
	onCancelClick,
	onNextClick,
	onPreviousClick,
	stepOffset = 0, // Default offset is 0
	totalStepsInPreviousWizard = 0,
	currentWizard = 1,
	initialStepState = 0,
	onStepStateChange,
}: {
	steps: Array<ReactNode>;
	stepDescription?: Array<{
		title: string;
		description?: string;
		progress?: number;
	}>;
	onSubmitClick: () => void;
	onCancelClick?: (stepIndex: number) => void;
	onNextClick?: (stepIndex: number) => void | Promise<boolean>;
	stepOffset?: number; // Offset for global step numbering
	totalStepsInPreviousWizard?: number;
	currentWizard?: number;
	onPreviousClick?: (wizardIndex: number) => void | Promise<boolean>;
	initialStepState?: number; // New prop to set the initial step
	onStepStateChange?: (step: number) => void; // Callback to sync step state
}) => {
	const [currentStep, setCurrentStep] = useState(initialStepState);
	const { isEmailVerified } = useNewApplication();
	const { nextButtonDisabled, submitButtonText } = useWizard(); // Get the disabled state

	const [isMobile, setIsMobile] = useState(window.innerWidth < 640);

	useEffect(() => {
		const handleResize = () => setIsMobile(window.innerWidth < 640);
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	useEffect(() => {
		// Sync initial step state from parent
		setCurrentStep(initialStepState);
	}, [initialStepState, currentWizard]);

	// Automatically determine if this is the first wizard
	const isFirstWizard = currentWizard === 0;

	const globalStep = currentStep + stepOffset; // Calculate global step

	const handleNext = useCallback(async () => {
		if (onNextClick) {
			const proceed = await onNextClick(currentStep);
			if (!proceed) return;
		}
		setCurrentStep((prev) => prev + 1);
		window.scrollTo(0, 0); // Scroll to the top when moving forward
	}, [currentStep, onNextClick]);

	const handlePrevious = useCallback(async () => {
		if (onPreviousClick) {
			if (!isFirstWizard && stepOffset == 0 && currentStep === 0) {
				const previous = await onPreviousClick(currentWizard);
				if (!previous) return;
				setCurrentStep(totalStepsInPreviousWizard - 1);
				onStepStateChange?.(totalStepsInPreviousWizard - 1);
			} else {
				setCurrentStep((prev) => {
					const prevStep = prev - 1;
					onStepStateChange?.(prevStep);
					return prevStep;
				});
			}
		} else {
			setCurrentStep((prev) => prev - 1);
		}
		window.scrollTo(0, 0); // Scroll to the top when moving forward
	}, [
		stepOffset,
		currentStep,
		totalStepsInPreviousWizard,
		onPreviousClick,
		currentWizard,
		onStepStateChange,
		isFirstWizard,
	]);

	const handleCancel = useCallback(() => {
		if (onCancelClick) {
			onCancelClick(globalStep);
		} else {
			setCurrentStep(0);
		}
	}, [globalStep, onCancelClick]);

	const handleStepChange = useCallback(
		(step: number) => {
			setCurrentStep(step);
			onStepStateChange?.(step); // Notify parent
		},
		[onStepStateChange]
	);

	// Dynamically calculate progress for each step
	const stepDecriptionWithProgress = stepDescription?.map((step, index) => ({
		...step,
		progress: ((index + 1) / steps.length) * 100,
	}));

	return (
		<>
			<div className="flex flex-col sm:flex-row gap-6 justify-center">
				{/* Steps for mobile */}
				<div className="sm:hidden">
					<CircularStepsWithDropdown
						steps={stepDecriptionWithProgress}
						activeStep={currentStep}
						onNext={() => void handleNext()} // Wrap in void
						onPrevious={() => void handlePrevious()}
						onStepChange={handleStepChange}
						disableDropdown={!isEmailVerified ? true : nextButtonDisabled} // Pass the disabled state
					/>
				</div>

				{/* Steps for desktop */}
				<div
					className="hidden sm:block w-full max-w-md sm:w-fit py-4 px-6 bg-white rounded-lg h-min"
					style={{ border: '1px solid #dfdfdf' }}
				>
					<StepsVertical
						steps={stepDecriptionWithProgress ?? []}
						activeStep={currentStep}
						onNext={() => void handleNext()}
						onPrevious={() => void handlePrevious()}
						onStepChange={handleStepChange}
						disableSteps={!isEmailVerified ? true : nextButtonDisabled}
					/>
				</div>

				{/* Form container */}
				<div
					className="w-full sm:w-fit sm:py-4 sm:px-4 sm:bg-white sm:rounded-lg sm:border-0"
					style={{ border: isMobile ? 'none' : '1px solid #dfdfdf' }}
				>
					<Wizard
						steps={steps}
						activeStep={currentStep}
						onNext={() => void handleNext()}
						onPrevious={() => void handlePrevious()}
						onSubmit={onSubmitClick}
						onCancel={handleCancel}
						submitButtonText={submitButtonText}
						isFirstWizard={isFirstWizard}
					/>
				</div>
			</div>
		</>
	);
};
