export const ROOT = '/';
export const LOGIN = '/login';
export const APPLICANT_HOME = '/applicant';
export const PRE_WORK = '/pre-work';
export const FUNCTIONARY_HOME = '/functionary';
export const INTERVIEW_LANDING = '/interview-landing';
export const ANSWERS_REVIEW = '/answers-review';
export const PRECEPTOR_INTERVIEW = '/preceptor-interview';
export const FAMILY_MEMBER_INTERVIEW = '/family-member-interview';
export const OTHER_REFERENCE_INTERVIEW = '/other-reference-interview';
export const APPLICANT_INTERVIEW = '/applicant-interview';
export const PROPOSER_RECOMMENDATION = '/proposer-recommendation';
export const CARE_TEAM_RECOMMENDATION = '/care-team-recommendation';
export const ZC_RECOMMENDATION = '/zc-recommendation';
export const APPROVER_RECOMMENDATION = '/approver-recommendation';
export const APPLICANT_ADDITIONAL_DETAILS = '/applicant-additional-details';
export const SKIP_ALL_INTERVIEWS = '/skip-all-interviews';
export const VIEW_ANSWERS = '/view-answers';
export const VIEW_FUNCTIONARIES = '/view-functionaries';
export const ADD_FUNCTIONARY = '/add-functionary';
export const EDIT_FUNCTIONARY = '/edit-functionary';
export const SELECT_MODULE = '/select-module';
export const GROUPS = '/nps/groups';
export const ASSIGN_MENTOR = '/nps/assign-mentor';
export const EDIT_GROUP = '/nps/edit-group';
export const VIEW_GROUP = '/nps/view-group';
export const ADD_MORE_PRECEPTORS = '/nps/add-more-preceptors';
export const CREATE_BATCH = '/nps/create-batch';
export const EDIT_BATCH = '/nps/edit-batch';
export const EXAM_MODULE_HOME = '/exam-module';
export const VIEW_CANDIDATES = 'view-candidates';
export const EXAM_MODULE_VIEW_CANDIDATES = '/exam-module/view-candidates';
export const SEND_EMAIL = '/exam-module/send-email';
export const EXAM_REGISTRATION = '/exam-registration/';
export const ELIGIBLE_CANDIDATES = 'eligible-candidates';
export const EXAM_MODULE_ELIGIBLE_CANDIDATES =
	'/exam-module/eligible-candidates';
export const SEND_RESULTS_EMAIL = '/exam-module/send-results';
export const SEND_ADMIT_CARD_EMAIL = '/exam-module/send-admit-cards';
export const SEND_INVITE_EMAIL = '/exam-module/send-invites';
export const EXAMS_LIST = 'exams-list';
export const EXAM_MODULE_EXAMS_LIST = '/exam-module/exams-list';
export const CREATE_EXAM = '/exam-module/create-exam';
export const CENTERS_LIST = 'centers-list';
export const EXAM_MODULE_CENTERS_LIST = '/exam-module/centers-list';
export const CREATE_CENTER = '/exam-module/create-center';
export const UPDATE_CENTER = '/exam-module/update-center';
export const VIEW_CENTER = '/exam-module/view-center';
export const UPDATE_EXAM = '/exam-module/update-exam';
export const VIEW_EXAM = '/exam-module/view-exam';
export const VIEW_HELD_CANDIDATES = 'view-held-candidates';
export const EXAM_MODULE_VIEW_HELD_CANDIDATES =
	'/exam-module/view-held-candidates';
export const KANHA_INVITE = 'kanha-invite';
export const EXAM_MODULE_KANHA_INVITE = '/exam-module/kanha-invite';
export const CREATE_KANHA_BATCH = '/exam-module/create-kanha-batch';
export const EDIT_KANHA_BATCH = '/exam-module/edit-kanha-batch';
export const KANHA_ELIGIBLE_CANDIDATES = 'kanha-eligible-candidates';
export const EXAM_MODULE_KANHA_ELIGIBLE_CANDIDATES =
	'/exam-module/kanha-eligible-candidates';
export const SEND_KANHA_INVITE_EMAIL = '/exam-module/send-kanha-invites';
export const ONE_AUTH_AUTHORIZATION = '/oneauth/authorization';
