import React from 'react';
import { Steps } from 'antd';

interface Step {
	title: string;
	description?: string | null;
	progress?: number;
}

interface StepsVerticalProps {
	steps: Array<Step>; // Define the type for the `steps` prop
	activeStep: number; // Current active step, managed externally
	onNext: () => void | Promise<boolean>; // Handler for the "Next" button
	onPrevious: () => void | Promise<boolean>; // Handler for the "Previous" button
	onStepChange: (step: number) => void; // Handler for step change
	disableSteps?: boolean; // New prop to disable steps
}

const StepsVertical: React.FC<StepsVerticalProps> = ({
	steps,
	activeStep,
	onStepChange,
	disableSteps = false,
}) => {
	return (
		<div className="w-full">
			<Steps
				current={activeStep}
				direction="vertical"
				onChange={disableSteps ? undefined : onStepChange}
				className="mb-4 space-y-6"
			>
				{steps.map((step, index) => (
					<Steps.Step
						key={index}
						title={
							<div className="flex items-center gap-2">
								<div>
									<strong
										className={`${
											index < activeStep
												? 'text-primary-blue' // Completed step color
												: index === activeStep
												? 'text-primary-blue' // Active step color
												: 'text-gray-500' // Pending step color
										}`}
									>
										{step.title}
									</strong>
									<p className="m-0 text-sm text-gray-600 min-h-[3.5rem]">
										{step.description || ''}
									</p>
								</div>
							</div>
						}
						disabled={disableSteps} // Disable step interaction
					/>
				))}
			</Steps>
			{/* Custom styles for dynamic stroke color */}
		</div>
	);
};

export default StepsVertical;
