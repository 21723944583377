import { Form, FormInstance, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { ApplicantInfo } from '../shared/VolunteerApplicationServiceTypes';
import { StepHeader } from './StepHeader';
import Icon from './ui-components/Icon';
import BlackChevronDown from '../assets/images/black-chevron-down.png';

interface TrainingCourseDetailsFormProps {
	form: FormInstance;
	applicant?: ApplicantInfo;
}

export const TrainingCourseDetailsForm: React.FC<
	TrainingCourseDetailsFormProps
> = ({ form, applicant }: TrainingCourseDetailsFormProps) => {
	const { t } = useTranslation();

	return (
		<div className="flex flex-col mt-8">
			<Form
				name="basic"
				layout="vertical"
				size="middle"
				labelCol={{ span: 16 }}
				style={{ maxWidth: 600 }}
				initialValues={{ remember: true }}
				autoComplete="off"
				form={form}
			>
				<>
					<StepHeader
						heading={t('trainingCourse')}
						description={
							applicant
								? t('trainingCourseSubHeading')
								: t('trainingCourseSubHeading')
						}
					/>
					<Form.Item
						label={
							<span className="font-sans font-normal text-xs text-secondary-black">
								{t('availabilityForTakingTheCourse')}
							</span>
						}
						labelCol={{ className: '!pb-0' }}
						name="availabilityForTakingTheCourse"
						rules={[
							{
								required: true,
								message: <span>{t('required')}</span>,
							},
						]}
						className="mb-4"
					>
						<Select
							className="h-11 text-sm font-normal font-sans text-light-gray rounded-lg"
							placeholder={t('selectYourAvailability')}
							options={[
								{
									value: 'availableNow',
									label: t('availableNow'),
								},
								{
									value: 'availableAfterThreeMonths',
									label: t('availableAfterThreeMonths'),
								},
								{
									value: 'toughToBeAvailable',
									label: t('toughToBeAvailable'),
								},
							]}
							suffixIcon={
								<Icon src={BlackChevronDown} width={21} height={21} />
							}
							fieldNames={{ label: 'label', value: 'value' }}
							filterOption={(input, option) =>
								(option?.label ?? '')
									.toLowerCase()
									.includes(input.toLowerCase())
							}
						/>
					</Form.Item>
					<Form.Item
						label={
							<span className="font-sans font-normal text-xs text-secondary-black">
								{t('preferredTrainingLanguage')}
							</span>
						}
						labelCol={{ className: '!pb-0' }}
						name="preferredTrainingLanguage"
						rules={[
							{
								required: true,
								message: <span>{t('required')}</span>,
							},
						]}
						className="mb-4"
					>
						<Select
							className="h-11 text-sm font-normal font-sans text-light-gray rounded-lg"
							placeholder={t('selectYourPreferredLanguage')}
							options={[
								{ value: 'English', label: 'English' },
								{ value: 'Hindi', label: 'Hindi' },
								{ value: 'Tamil', label: 'Tamil' },
								{ value: 'Telugu', label: 'Telugu' },
								{ value: 'Kannada', label: 'Kannada' },
								{ value: 'Malayalam', label: 'Malayalam' },
								{ value: 'Marathi', label: 'Marathi' },
							]}
							suffixIcon={
								<Icon src={BlackChevronDown} width={21} height={21} />
							}
						/>
					</Form.Item>
				</>
			</Form>
		</div>
	);
};
