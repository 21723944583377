import { useCallback } from 'react';
import { Avatar, Button } from 'antd';
import LanguageDropdown from './LanguageDropdown';
import { UserOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import HeaderLogo from '../assets/images/header-logo.svg';
import LogoutImage from '../assets/images/logout.svg';
import { useAuth } from '../hooks/useAuth';
import { useLocation } from 'react-router-dom';
import { EXAM_MODULE_HOME, SELECT_MODULE } from '../shared/Routes';
import { useBatchId } from '../hooks/useBatchId';
import { UserRole } from '../shared/VolunteerApplicationServiceTypes';

interface NewAppHeaderProps {
	logout?: () => void | Promise<void>;
}

const LogoutIcon = () => (
	<>
		<img src={LogoutImage} alt="Logout Icon" />
	</>
);

export const NewAppHeader = ({ logout }: NewAppHeaderProps) => {
	const { t } = useTranslation();
	const logoutText = t('logout');
	const { mySrcmProfile, role } = useAuth();
	const { navigateWithBatchId } = useBatchId();
	const location = useLocation();
	const disableSNPClick = role === UserRole.APPLICANT;
	let module;
	if (location.pathname.includes('/nps')) {
		module = 'New Preceptor Support';
	} else if (location.pathname.includes(EXAM_MODULE_HOME)) {
		module = 'Exam Module';
	}

	const handleSNPClick = useCallback(() => {
		navigateWithBatchId(SELECT_MODULE);
	}, [navigateWithBatchId]);

	const handleLogoutClick = useCallback(() => {
		if (logout) {
			void logout();
		}
	}, [logout]);

	return (
		<div className="flex justify-between items-center p-[8px] bg-nav-blue text-white px-6">
			<div className="flex items-center">
				<img src={HeaderLogo} alt="Header Logo" className="hidden md:block" />
				<div className="h-full w-px bg-white mr-4 hidden md:block">&nbsp;</div>
				<span
					className={`text-xl font-bold ${
						!disableSNPClick ? 'cursor-pointer hover:underline' : ''
					}`}
					onClick={!disableSNPClick ? handleSNPClick : () => {}}
				>
					SNP
				</span>
				{module && (
					<span className="text-xl font-bold hidden md:block">
						&nbsp;{`- ${module}`}
					</span>
				)}
			</div>
			<div className="flex items-center gap-4">
				<div className="hidden md:block">
					<Avatar className="mr-2" size="default" icon={<UserOutlined />} />
					<span className="font-medium mr-3">{mySrcmProfile?.name}</span>
				</div>
				<div>
					<LanguageDropdown className="w-24" />
				</div>
				<div>
					<Button
						type="link"
						shape="round"
						icon={<LogoutIcon />}
						onClick={handleLogoutClick}
						size="small"
						className="text-white"
						title={logoutText}
					/>
				</div>
			</div>
		</div>
	);
};
