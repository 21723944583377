import { useCallback } from 'react';
import { Moment } from 'moment';
import { ExamFilter } from '../../../shared/ExamModuleTypes';
import { useTranslation } from 'react-i18next';
import { Button, DatePicker, Form, Input, Typography } from 'antd';
import { DATE_DD_MMM_YYYY } from '../../../shared/Constants';
import { convertDateToUTCString } from '../../../shared/Utils';

interface FilterProps {
	value: ExamFilter;
	onFilterChange: (value: ExamFilter) => void;
}

interface ExamFilterFormType {
	examName?: string;
	examDate?: Moment;
}

const { Text } = Typography;

export const FilterExams = ({ value, onFilterChange }: FilterProps) => {
	const { t } = useTranslation();
	const [form] = Form.useForm<ExamFilterFormType>();
	const formValues = Form.useWatch<ExamFilterFormType>([], form);

	const handleFilterClick = useCallback(() => {
		const filterValues: ExamFilter = {
			...formValues,
			name: formValues?.examName,
			examDate:
				formValues.examDate && convertDateToUTCString(formValues?.examDate),
		};
		const examFilters = { ...value, ...filterValues };
		onFilterChange(examFilters);
	}, [onFilterChange, value, formValues]);

	const handleResetForm = useCallback(() => {
		form.resetFields();
		form.setFieldsValue({
			examName: undefined,
			examDate: undefined,
		});
		const filterValues: ExamFilter = {};
		onFilterChange(filterValues);
	}, [form, onFilterChange]);

	const handleKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
		if (event.key === 'Enter') {
			handleFilterClick();
		}
	};

	return (
		<div className="flex flex-col">
			<Form
				name="examsFilterForm"
				layout="horizontal"
				size="middle"
				labelCol={{ span: 24 }}
				wrapperCol={{ span: 24 }}
				autoComplete="off"
				initialValues={value}
				form={form}
			>
				<div className="flex flex-col md:mb-0">
					<div className="flex flex-col gap-4 md:flex-row">
						<div className="flex flex-col md:mb-0 w-full min-w-[170px]">
							<Text className="pl-1">{t('examName')}</Text>
							<Form.Item className="!mb-2" name={'examName'}>
								<Input placeholder="" onKeyDown={handleKeyDown} />
							</Form.Item>
						</div>
						<div className="flex flex-col md:mb-0 w-full min-w-[170px]">
							<Text className="pl-1">{t('examDate')}</Text>
							<Form.Item className="!mb-2" name={'examDate'}>
								<DatePicker
									className="w-full"
									placeholder=""
									format={DATE_DD_MMM_YYYY}
									onKeyDown={handleKeyDown}
								/>
							</Form.Item>
						</div>
					</div>
				</div>
			</Form>
			<div className="flex flex-row gap-2 md:mb-0">
				<div className="self-start">
					<Button
						className="bg-nav-blue hover:!bg-hover-nav-blue text-white"
						type="primary"
						onClick={handleFilterClick}
					>
						{t('filter')}
					</Button>
				</div>
				<div className="self-start">
					<Button type="default" onClick={handleResetForm}>
						{t('clear')}
					</Button>
				</div>
			</div>
		</div>
	);
};
