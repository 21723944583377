import { CategoryStep } from './CategoryStep';
import { useNewApplication } from './useNewApplication';
import useCategoryFormHandling from './useCategoryFormHandling';
import { DietaryHabitsAndHealthUIConfig } from '../../../shared/CategoryStepUIConfig';

const UiConfig = DietaryHabitsAndHealthUIConfig;

export const DietaryHabitsAndHealthStep = () => {
	const {
		dietaryHabitsAndHealthCategoryAnswers,
		setDietaryHabitsAndHealthCategoryAnswers,
	} = useNewApplication();

	const { form, updateFieldValue } = useCategoryFormHandling(
		dietaryHabitsAndHealthCategoryAnswers,
		setDietaryHabitsAndHealthCategoryAnswers
	);

	return (
		<CategoryStep
			uiConfig={UiConfig}
			form={form}
			updateFieldValue={updateFieldValue}
		/>
	);
};
