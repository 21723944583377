import { CategoryAnswerType } from '../pages/applicant/NewApplicationWizard/CategoryAnswerType';
import { Status } from './ExamModuleTypes';

export interface GetUserRoleRequest {
	batchID: number;
	firebaseId: string;
	srcmId: string;
}

export enum UserRole {
	NOT_SET = 'NOT_SET',
	APPLICANT = 'APPLICANT',
	FUNCTIONARY = 'FUNCTIONARY',
	APPROVER = 'APPROVER',
	CARE_TEAM = 'CARE_TEAM',
	ZC = 'ZC',
	BATCH_ADMIN = 'BATCH_ADMIN',
	ADMIN = 'ADMIN',
}

export type Module =
	| 'NOT_APPLICABLE_MODULE'
	| 'SNP_MODULE'
	| 'NPHH_MODULE'
	| 'EXAM_MODULE';

export interface GetUserRoleResponse {
	role: UserRole;
}

export interface SearchCenterRequest {
	batchId: number;
	searchKey: string;
	pageIndex: number;
	pageSize: number;
}
export interface SearchCountryRequest {
	pageSize: number;
	name__icontains: string;
}
export interface ApplicationsFilter {
	status?: Array<string>;
	searchByApplicantName?: string;
	gender?: string;
	zoneNames?: Array<string>;
	applicantSrcmId?: string;
	assignedToLevel1FunctionarySrcmId?: string;
	preceptorReview?: Array<string>;
	proposerReview?: Array<string>;
	searchByProposerSrcmId?: string;
}
export interface GetApplicationsRequest {
	firebaseId: string;
	pageIndex: number;
	pageSize: number;
	batchId: number;
	filter: ApplicationsFilter;
	functionarySrcmId: string;
	predefinedReasonLanguage: string;
}

export interface GetFunctionaryListRequest {
	pageIndex: number;
	pageSize: number;
	batchId: number;
	filter: ApplicationsFilter;
	functionarySrcmId: string;
}

export interface Center {
	id: number;
	name: string;
	externalId?: string;
	zone: string;
	centerType: string;
}

export interface SelectedDropdownValue {
	value: number | string;
	label: string;
}
export interface Country {
	value: number | string;
	label: string;
}
export interface State {
	id?: number;
	name?: string;
}

export interface ZoneNames {
	labe: string;
	key: string;
	value: string;
}

export interface PredefinedReason {
	id: number;
	reasonText: string;
	category: string;
	daysToEnableReApply?: number;
	reasonTextForCandidate?: string;
}

export interface PredefinedReasonListRequest {
	pageIndex: number;
	pageSize: number;
	language?: string;
}

export interface PredefinedReasonListResponse {
	predefinedReasons: Array<PredefinedReason>;
	pagination: Pagination;
}

export interface CentersPage {
	totalNoOfPages: number;
	centers?: Array<Center>;
}

export interface ApplicantInfo {
	firstName?: string;
	lastName?: string;
	middleName?: string;
	email?: string;
	mobile?: string;
	gender?: string;
	srcmid: string;
	firebaseId: string;
	dateOfBirth?: string;
	age?: number;
	dateOfJoining?: string;
	educationQualification?: string;
	profession?: string;
	professionDetails?: string;
	addressLine1?: string;
	addressLine2?: string;
	addressLine3?: string;
	city?: string;
	pincode?: string;
	photoUrl?: string;
}

export interface ApplicationSummary {
	applicationId: number;
	applicant: ApplicantInfo;
	center: Center;
	applicationStatus: ApplicationStatusType;
	proposerName?: string;
	proposerSrcmId?: string;
	applicantAnswersReviewed?: boolean;
	applicantInterviewDone?: boolean;
	preceptorInterviewDone?: boolean;
	familyMemberInterviewDone?: boolean;
	otherReferenceInterviewDone?: boolean;
	isApprover?: boolean;
	proposerRecommendation?: Feedback;
	preceptorRecommendation?: Feedback;
	careTeamRecommendation?: Feedback;
	zcRecommendation?: Feedback;
	approverRecommendation?: Feedback;
}

export interface ApplicationSummaryList {
	applicationSummary?: Array<ApplicationSummary>;
	pagination: Pagination;
}

export interface FunctionaryInfo {
	id?: number;
	printName?: string;
	email?: string;
	mobile?: string;
	gender?: string;
	srcmId: string;
	firebaseId?: string;
	isApprover?: boolean;
	isCareTeam?: boolean;
	isZC?: boolean;
	adminSrcmId?: string;
	zone?: Array<{ value: string }>;
	functionaryType?: string;
	homeZone?: string;
	homeZoneToDisplay?: { value: string | undefined };
}

export interface FunctionaryList {
	functionary?: Array<FunctionaryInfo>;
	pagination: Pagination;
}

export interface FunctionaryOfZone {
	functionary: FunctionaryInfo;
	zone?: Array<string>;
	approverZone?: Array<string>;
	zcZone?: Array<string>;
}

export interface SaveFunctionaryOfZoneRequest {
	printName?: string;
	email?: string;
	mobile?: string;
	gender?: string;
	srcmId: string;
	proposerOfZones?: Array<string>;
	approverOfZones?: Array<string>;
	zcOfZones?: Array<string>;
	isCareTeam?: boolean;
	homeZone?: string;
}

export interface BulkSaveFunctionariesOfZoneRequest {
	functionaryOfZones: Array<SaveFunctionaryOfZoneRequest>;
	functionarySrcmId?: string;
}

export interface BooleanValue {
	value: boolean;
}

export interface IntegerValue {
	value: number;
}

export interface ApplicantReference {
	category?: string;
	name?: string;
	mobile?: string;
	relationship?: string;
	isAbhyasi?: boolean;
	isPreceptor?: boolean;
	id?: number;
	email?: string;
	srcmid?: string;
}

export interface Answer {
	categoryId: number;
	questionId: number;
	answerOptionId?: number;
	additionalComment?: string;
}

export interface Answers {
	language?: string;
	questionsAndAnswers: Array<Answer>;
	finalComments?: string;
}

export type ReviewAction = 'NOT_PROVIDED' | 'APPROVE' | 'REJECT' | 'ON_HOLD';

export interface Feedback {
	review?: string;
	comments?: string;
	predefinedReasons?: Array<PredefinedReason>;
	mandatoryQuestionsWithAnswers?: CategoryAnswerType;
	shouldCareTeamReview?: boolean;
}

export interface ApproverFeedback extends Feedback {
	reapplyCondition?: string;
}

export interface CategoryReviewFeedback {
	inspirationAndWillingness?: Feedback;
	practice?: Feedback;
	characterAndHabits?: Feedback;
	availabilityAfterProgram?: Feedback;
	support?: Feedback;
	understandingOfHeartfulness?: Feedback;
	eligibilityCriteria?: Feedback;
	dietaryHabitsAndHealth?: Feedback;
	answersToMandatoryQuestions?: Answers;
}

export interface Functionary {
	id: number;
	printName: string;
	email: string;
	mobile: string;
	srcmId: string;
	firebaseId: string;
	isAdmin: boolean;
}

export type ApplicationStatusType =
	| 'NOT_YET_APPLIED'
	| 'USER_SUBMITTED_APPLICATION'
	| 'FUNCTIONARY_ASSIGNED'
	| 'FUNCTIONARY_SUBMITTED'
	| 'APPROVED'
	| 'REJECTED'
	| 'SYS_ANOTHER_FUNCTIONARY_ASSIGNED'
	| 'ON_HOLD_AFTER_LEVEL1_REVIEW'
	| 'ON_HOLD_AFTER_LEVEL2_REVIEW';

export interface Application {
	applicationId: number;
	applicant: ApplicantInfo;
	preceptorReference: ApplicantReference;
	familyMemberReference: ApplicantReference;
	otherReference: ApplicantReference;
	applicationStatus: ApplicationStatusType;
	userAnswers: Answers;
	applicantAnswersReviewed: boolean;
	applicantInterviewDone: boolean;
	preceptorInterviewDone: boolean;
	familyMemberInterviewDone: boolean;
	otherReferenceInterviewDone: boolean;
	answersReviewFeedback?: CategoryReviewFeedback;
	applicantInterviewFeedback?: CategoryReviewFeedback;
	preceptorInterviewFeedback?: CategoryReviewFeedback;
	familyMemberInterviewFeedback?: CategoryReviewFeedback;
	otherReferenceInterviewFeedback?: CategoryReviewFeedback;
	preceptorRecommendation: Feedback;
	proposerRecommendation: Feedback;
	careTeamRecommendation: Feedback;
	zcRecommendation: Feedback;
	approverRecommendation: Feedback;
	applicationSubmissionDateDDMMYYYY: string;
	batchId: number;
	proposer: Functionary;
	referencesCollected: boolean;
	center: Center;
	preferredLanguageOfTraining?: string;
	availabilityForTrainingCourse?: string;
	otherReferenceProvided?: boolean;
	isApprover?: boolean;
	shouldCareTeamReview?: boolean;
	reapplyCondition?: string;
	daysToEnableReApply?: number;
}

export interface PagingConfig {
	pageNo?: number;
	pageSize?: number;
}

export interface Pagination extends PagingConfig {
	totalNoOfRecords: number;
	totalNoOfPages?: number;
}

export type Gender = 'NOT_PROVIDED' | 'MALE' | 'FEMALE';

export interface User {
	mySrcmPartnerId?: number;
	photoUrl?: string;
	firstName?: string;
	middleName?: string;
	lastName?: string;
	addressLine1?: string;
	addressLine2?: string;
	addressLine3?: string;
	addressLine4?: string;
	city?: string;
	mobile?: string;
	email?: string;
	pincode?: string;
	firebaseId?: string | null;
	srcmId?: string;
	gender?: Gender;
	age?: number;
	monthOfJoining?: string;
	educationQualification?: string;
	profession?: string;
	professionDetails?: string;
}

export interface ApplicantAdditionalDetails {
	center?: number;
	monthOfJoiningInMMMYYYY?: string;
	educationQualification?: string;
	profession?: string;
	professionDetails?: string;
	dateOfBirth?: string;
	preceptorReference?: ApplicantReference;
	familyMemberReference?: ApplicantReference;
	otherReference?: ApplicantReference;
	otherReferenceProvided: boolean;
	preferredLanguageOfTraining?: string;
	availabilityForTrainingCourse?: string;
	addressLine1?: string;
	addressLine2?: string;
	addressLine3?: string;
	city?: string;
	pincode?: string;
}

export interface NewApplicationRequest {
	userDetails: User;
	additionalDetails: ApplicantAdditionalDetails;
	userAnswers: Answers;
	batchId: number;
}

export interface UserID {
	firebaseId: string;
	srcmId: string;
}

export interface CanSubmitNewApplicationRequest extends UserID {
	predefinedReasonLanguage?: string;
}

export interface FunctionaryPredefinedComment {
	id: number;
	predefinedReason: PredefinedReason;
	providedBy: string;
	functionarySrcmId: number;
	createdDate: string;
	modifiedDate: string;
}

export interface PastApplicationRejectedReason {
	functionaryPredefinedComment: FunctionaryPredefinedComment;
	rectifyOption: number;
}

export interface RectifyPastApplicationRejectedReasonsRequest {
	applicantSrcmId?: string;
	pastApplicationRejectedReasons?: Array<PastApplicationRejectedReason>;
}

export interface CanSubmitNewApplicationResponse {
	allowed?: boolean;
	notAllowedReason?: string;
	pastApplicationStatus?: ApplicationStatusType;
	reapplyDate?: string;
	pastApplicationRejectedReasons?: Array<FunctionaryPredefinedComment>;
	pastApplicationBatch?: Batch;
	pastApplicationRejectedReasonsRectified?: boolean;
	pastApplicationId?: number;
	preworkCompletionStatus?: PreworkCompletionStatusType;
}

export interface Batch {
	id: number;
	name: string;
	description: string;
	startDate?: string;
	endDate?: string;
	snpResultDateForApprover?: string;
	snpResultDateForProposer?: string;
	snpResultDateForZC?: string;
	snpResultDateForCandidate?: string;
}

export interface FunctionaryFeedbackRequest {
	applicationId: number;
	feedbackCategory: string;
	categoryReviewFeedback: CategoryReviewFeedback;
	functionarySrcmId: string;
}

export interface PreceptorRecommendationRequest {
	feedback: FunctionaryFeedbackRequest;
	recommendation: Feedback;
	preceptorReference: ApplicantReference;
}

export interface ApplicantAdditionalDetailsRequest {
	additionalDetails: ApplicantAdditionalDetails;
	applicationId: number;
	functionarySrcmId: string;
}

export interface ProposerRecommendationRequest {
	recommendation: Feedback;
	applicationId: number;
	functionarySrcmId: string;
}

export interface ZCRecommendationRequest extends ProposerRecommendationRequest {
	shouldCareTeamReview?: boolean;
}

export interface ApproverRecommendationRequest {
	recommendation: Feedback;
	applicationId: number;
	functionarySrcmId: string;
	reapplyCondition?: string;
}

export interface SearchZonesRequest {
	functionarySrcmId: string;
	searchKey?: string;
	pageIndex: number;
	pageSize: number;
	includeOnlyZonesOfApprover?: boolean;
	displayAllZones?: boolean;
}

export interface ZonesPage {
	totalNoOfPages: number;
	zones?: Array<string>;
}

export interface GetApplicationRequest {
	applicationId: number;
	functionarySrcmId?: string;
}

export interface GetFunctionaryRequest {
	functionarySrcmId?: string;
}

export interface GetRegionsRequest {
	functionarySrcmId?: string;
	preceptorBatchId: number;
}

export interface OtpVerificationRequest {
	otp: string;
}

export type PreworkCompletionStatusType =
	| 'NOT_STARTED'
	| 'INCOMPLETE'
	| 'PARTIALLY_COMPLETED'
	| 'COMPLETED';

export interface PreworkAnswersRequest {
	batchId: number;
	applicationId: number;
	preworkAnswers: Answers;
	completionStatus: PreworkCompletionStatusType;
}

export interface PreworkResponseData {
	applicationId: number;
	batch: Batch;
	userAnswers: Answers;
	completionStatus: PreworkCompletionStatusType;
}

export interface PreceptorLanguage {
	id: number;
	name: string;
}

export type SortingOrder =
	| 'SORTING_ORDER_UNSPECIFIED'
	| 'ASCENDING'
	| 'DESCENDING';

export type NPHHSortingFieldName =
	| 'SORTING_FIELD_NAME_UNSPECIFIED'
	| 'PRECEPTOR_ID'
	| 'NAME'
	| 'SRCM_ID'
	| 'BATCH'
	| 'ADDRESS'
	| 'POSTAL_CODE'
	| 'COUNTRY'
	| 'CITY'
	| 'STATE'
	| 'ZONE'
	| 'MOBILE'
	| 'EMAIL'
	| 'GENDER'
	| 'GROUP_ID'
	| 'GROUP_NUMBER';

export interface NPHHSortingRequest {
	sortingFieldName: NPHHSortingFieldName;
	sortingOrder: SortingOrder;
}

export interface Preceptor {
	id: number;
	key?: number;
	firstName: string;
	middleName: string;
	lastName: string;
	srcmId: string;
	email: string;
	email2: string;
	mobile: string;
	mobile2: string;
	phone: string;
	phone2: string;
	age: number;
	gender: Gender;
	dateOfBirth: string;
	dateOfJoining: string;
	addressLine1: string;
	addressLine2: string;
	addressLine3: string;
	addressLine4: string;
	cityId: number;
	city: string;
	stateId: number;
	state: string;
	countryId: number;
	country: string;
	postalCode: string;
	centerId: number;
	center: string;
	mySrcmPartnerId: number;
	prefectId: number;
	prefectStatus: string;
	prefectStartDate: string;
	respPrefectId: number;
	respPrefectName: string;
	batch: string;
	zone: string;
	locality: string;
	languages: Array<PreceptorLanguage>;
	preceptorMappedToGroup: boolean;
	preceptorAssignedToMentor: boolean;
}

export interface PreceptorDashboardCardFilter {
	preceptorMappedToGroup: boolean;
	preceptorAssignedToMentor: boolean;
	preceptorNotMappedToGroup: boolean;
	preceptorNotAssignedToMentor: boolean;
}

export interface PreceptorsFilter {
	srcmId?: string;
	name?: string;
	email?: string;
	mobile?: string;
	countryNames?: Array<string>;
	stateNames?: Array<string>;
	cityNames?: Array<string>;
	// eslint-disable-next-line
	zoneNames?: string | Country;
	centerNames?: Array<string>;
	postalCode?: string;
	zones?: Array<string>;
	batch?: string;
	gender?: Gender;
	prefectStatus?: Array<string>;
	prefectStartDate?: string;
	language?: string;
	respPrefectName?: string;
	showNewPreceptorsForGrouping?: boolean;
	showMentors?: boolean;
	showAllPreceptors?: boolean;
	showPreceptorDashboardCardDetail?: boolean;
	countryIds?: Array<number>;
	stateIds?: Array<number>;
	cityIds?: Array<number>;
	centerIds?: Array<number>;
	sortingRequests?: Array<NPHHSortingRequest>;
	dashboardCardFilterEnabled?: boolean;
	preceptorDashboardCardFilter?: PreceptorDashboardCardFilter;
	excludeIds?: Array<number>;
	regionIds?: Array<number>;
	preceptorBatchId?: number;
}

export interface GetPreceptorsRequest {
	functionarySrcmId: string;
	pageIndex?: number;
	pageSize?: number;
	preceptorsFilter: PreceptorsFilter;
}

export interface GetPreceptorsResponse {
	preceptors: Array<Preceptor>;
	pagination: Pagination;
}

export interface PreceptorGroup {
	id: number;
	groupNumber: string;
	groupName: string;
	noOfMembers: number;
	mentor: Preceptor;
	functionarySrcmId?: string;
	preceptorGroupMembers: Array<Preceptor>;
}

export interface PreceptorGroupRequest {
	functionarySrcmId: string;
	id: number;
}

export interface CreatePreceptorGroupRequest {
	functionarySrcmId: string;
	preceptorIds: Array<number>;
	preceptorBatchId?: number;
}

export interface CreatePreceptorGroupResponse {
	preceptorGroup: PreceptorGroup;
}

export interface UpdatePreceptorGroupRequest
	extends CreatePreceptorGroupRequest {
	preceptorGroupId: number;
}

export interface UpdatePreceptorGroupResponse {
	preceptorGroup: PreceptorGroup;
}

export interface PreceptorsGroupFilter {
	groupNumber?: string;
	noOfMembers?: number;
	mentorId?: number;
	sortingRequests?: Array<NPHHSortingRequest>;
	preceptorBatchId?: number;
}

export interface GetPreceptorGroupRequest {
	groupId: number;
	functionarySrcmId?: string;
}

export interface GetPreceptorsGroupRequest {
	functionarySrcmId: string;
	pageIndex: number;
	pageSize: number;
	preceptorsGroupFilter: PreceptorsGroupFilter;
}

export interface GetPreceptorsGroupResponse {
	preceptorGroups: Array<PreceptorGroup>;
	pagination: Pagination;
}

export interface AssignMentorRequest {
	functionarySrcmId: string;
	preceptorGroupId: number;
	mentorId: number;
}

export interface GetPreceptorDashboardDetailsRequest {
	functionarySrcmId: string;
}

export interface GetPreceptorDashboardDetailsResponse {
	totalPreceptors: number;
	totalPreceptorsNotMappedWithGroup: number;
	totalPreceptorsMappedWithGroup: number;
	totalPreceptorsNotAssignedWithMentor: number;
	totalPreceptorsAssignedWithMentor: number;
}

export interface PreceptorDashboardCardDetail {
	totalPreceptors: number;
	totalPreceptorsNotMappedWithGroup: number;
	totalPreceptorsMappedWithGroup: number;
	totalPreceptorsNotAssignedWithMentor: number;
	totalPreceptorsAssignedWithMentor: number;
}

export interface Region {
	regionId: number;
	regionName: string;
}

export interface GetRegionsResponse {
	regions: Array<Region>;
}

export interface RegionWiseGraphicalView {
	regionId: number;
	region: string;
	preceptorMappedWithGroupCount: number;
	preceptorNotMappedWithGroupCount: number;
	preceptorAssignedWithMentorCount: number;
	preceptorNotAssignedWithMentorCount: number;
}

export interface ZoneWiseGraphicalView {
	zoneId: number;
	zone: string;
	preceptorMappedWithGroupCount: number;
	preceptorNotMappedWithGroupCount: number;
	preceptorAssignedWithMentorCount: number;
	preceptorNotAssignedWithMentorCount: number;
}

export interface GraphicalViewResponse {
	preceptorDashboardCardDetail: PreceptorDashboardCardDetail;
	regionWiseGraphicalViews?: Array<RegionWiseGraphicalView>;
	zoneWiseGraphicalViews?: Array<ZoneWiseGraphicalView>;
}

export interface PreceptorBatchFilter {
	preceptorBatchId?: number;
	batchName?: string;
	batchCode?: string;
	batchDescription?: string;
	startDate?: string;
	endDate?: string;
	statusArray?: Array<Status>;
}

export interface GetPreceptorBatchesRequest {
	functionarySrcmId: string;
	pageIndex: number;
	pageSize: number;
	preceptorBatchFilter: PreceptorBatchFilter;
}

export interface PreceptorBatch {
	id: number;
	key?: number;
	batchName: string;
	batchCode: string;
	description: string;
	startDate: string;
	endDate: string;
	status: Status;
	arePreceptorsLoadedFromMySRCM: boolean;
	totalNoOfPreceptorsToBeLoaded: number;
	totalNoOfPreceptorsLoaded: number;
}

export interface GetPreceptorBatchesResponse {
	preceptorBatches: Array<PreceptorBatch>;
	pagination: Pagination;
}

export interface PreceptorBatchForCreation {
	id?: number;
	batchName: string;
	description: string;
	startDate: string;
	endDate: string;
	status?: Status;
	arePreceptorsLoadedFromMySRCM?: boolean;
	totalNoOfPreceptorsToBeLoaded?: number;
	totalNoOfPreceptorsLoaded?: number;
}

export interface CreatePreceptorBatchRequest {
	functionarySrcmId: string;
	preceptorBatch: PreceptorBatchForCreation;
}

export interface LoadPreceptorsFromMySRCMRequest {
	adminSrcmId: string;
	page?: number;
	pageSize?: number;
	preceptorBatchId?: number;
}

export interface GetZonesRequest {
	functionarySrcmId: string;
	preceptorBatchId?: number;
	regionIds?: Array<number>;
}

export interface GetPreceptorByPartnerIdRequest {
	firebaseId: string;
	id: number;
}
