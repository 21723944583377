import React, { useEffect } from 'react';
import { Checkbox, Form, FormInstance, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { AboutTheProcessType } from './AboutTheProcessType';
import { useWizard } from '../../../components/wizard/useWizard';
import { useNewApplication } from './useNewApplication';

const { Text } = Typography;

const SectionHeader = () => {
	const { t } = useTranslation();
	return (
		<div className="flex flex-col mb-[8px]">
			<Text className="font-sans font-bold text-xl text-primary-blue my-4">
				{t('aboutTheProcess')}
			</Text>
			<Text className="mb-[8px] font-sans font-medium text-sm">
				{t('dearSisterBrother')}
			</Text>
			<Text className="mb-[8px] text-justify font-sans font-medium text-sm">
				{t('welcomeNote')}
			</Text>
			<Text className="mb-[8px] text-justify font-sans font-medium text-sm">
				{t('selfConsciousnessNote')}
			</Text>
			<Text className="mb-[8px] text-justify font-sans font-medium text-sm">
				{t('servingAsPreceptor')}
			</Text>
			<Text className="mb-[8px] text-justify font-sans font-medium text-sm">
				{t('pleaseNoteThat')}
			</Text>
			<Text className="mb-[8px] text-justify font-sans font-medium text-sm">
				<b>{t('stepOneHeading')}</b>
				{t('stepOneNote')}
			</Text>
			<Text className="mb-[8px] text-justify font-sans font-medium text-sm">
				<b>{t('stepTwoHeading')}</b>
				{t('stepTwoNote')}
			</Text>
			<Text className="mb-[8px] text-justify font-sans font-medium text-sm">
				<b>{t('stepTwoBHeading')}</b>
				{t('stepTwoBNote')}
			</Text>
			<Text className="mb-[8px] text-justify font-sans font-medium text-sm">
				<b>{t('stepThreeHeading')}</b>
				{t('stepThreeNote')}
			</Text>
			<Text className="mb-[8px] text-justify font-sans font-medium text-sm">
				<b>{t('stepFourHeading')}</b>
				{t('stepFourNote')}
			</Text>
			<Text className="mb-[8px] text-justify font-sans font-medium text-sm">
				<b>{t('stepFiveHeading')}</b>
				{t('stepFiveNote')}
			</Text>
			<Text className="mb-[8px] text-justify font-sans font-medium text-sm">
				{t('weRequestYourHeartfulUnderstanding')}
			</Text>
		</div>
	);
};

export const AboutTheProcessStep: React.FC<{
	form: FormInstance;
}> = ({ form }: { form: FormInstance }) => {
	const { t } = useTranslation();

	return (
		<div className="flex flex-col mt-4">
			<Form
				name="basic"
				layout="vertical"
				size="middle"
				labelCol={{ span: 16 }}
				style={{ maxWidth: 600 }}
				initialValues={{ remember: true }}
				autoComplete="off"
				form={form}
			>
				<SectionHeader />
				<Form.Item name="iAcceptTermsAndWantToProceed" valuePropName="checked">
					<Checkbox className="font-sans font-medium text-sm">
						{t('iAcceptTermsAndWantToProceed')}
					</Checkbox>
				</Form.Item>
				<Form.Item name="iConsentSelfConsciousness" valuePropName="checked">
					<Checkbox className="font-sans font-medium text-sm">
						{t('iConsentSelfConsciousness')}
					</Checkbox>
				</Form.Item>
				<Form.Item name="iAmWillingAndPrepared" valuePropName="checked">
					<Checkbox className="font-sans font-medium text-sm">
						{t('iAmWillingAndPrepared')}
					</Checkbox>
				</Form.Item>
			</Form>
		</div>
	);
};

export const AboutTheProcessContainer = () => {
	const { t } = useTranslation();
	const [form] = Form.useForm<AboutTheProcessType>();
	const {
		setSubmitButtonDisabled,
		setCancelButtonDisabled,
		setSubmitButtonText,
	} = useWizard();
	const values = Form.useWatch<AboutTheProcessType>([], form);
	const { welcomeAndSelfConsciousness, setAboutTheProcess } =
		useNewApplication();

	useEffect(() => {
		if (values) {
			const areAllTermsAndConditionsAgreed =
				(values.iAcceptTermsAndWantToProceed &&
					values.iConsentSelfConsciousness &&
					values.iAmWillingAndPrepared) as boolean;
			setSubmitButtonDisabled(!areAllTermsAndConditionsAgreed);
			setAboutTheProcess(values);
		} else {
			setSubmitButtonDisabled(true);
		}
		setSubmitButtonText(t('ok'));
		setCancelButtonDisabled(false);
	}, [
		setSubmitButtonText,
		setSubmitButtonDisabled,
		setCancelButtonDisabled,
		setAboutTheProcess,
		values,
		t,
	]);

	useEffect(() => {
		form.setFieldsValue(welcomeAndSelfConsciousness);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [form]);

	return <AboutTheProcessStep form={form} />;
};
