const mySrcmConfig = JSON.parse(
	process.env.REACT_APP_MYSRCM_CONFIG as string
) as {
	xClientId: string;
	profileServer: string;
	profileUpdatePage: string;
	staticProfileUpdatePage: string;
};

export const xClientId = mySrcmConfig.xClientId;
export const mySRCMBaseURL = mySrcmConfig.profileServer;
export const myStaticProfileUpdateURL = mySrcmConfig.staticProfileUpdatePage;
export const myHFNProfileURL = mySrcmConfig.profileUpdatePage;

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const firebaseConfig = JSON.parse(
	process.env.REACT_APP_FIREBASE_AUTH_CONFIG as string
);

const volunteerApplicationServiceServerConfig = JSON.parse(
	process.env.REACT_APP_VOLUNTEER_APPLICATION_SERVICE_CONFIG as string
) as { server: string };
export const backendServerURL = volunteerApplicationServiceServerConfig.server;
