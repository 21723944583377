import { Form } from 'antd';
import { ApplicantAdditionalDetailsType } from '../../../components/AdditionalDetailsType';
import { useNewApplication } from './useNewApplication';
import { useWizard } from '../../../components/wizard/useWizard';
import { useCallback, useEffect, useState } from 'react';
import { ApplicantAdditionalDetailsForm } from '../../../components/AdditionalDetailsForm';
import { useAuth } from '../../../hooks/useAuth';
import moment from 'moment';

export const ApplicantAdditionalDetailsStep = () => {
	const { mySrcmProfile } = useAuth();
	const { additionalApplicantDetails, setAdditionalApplicantDetails } =
		useNewApplication();
	const [form] = Form.useForm<ApplicantAdditionalDetailsType>();
	const { setNextButtonDisabled, setCancelButtonDisabled } = useWizard();
	const values = Form.useWatch<ApplicantAdditionalDetailsType>([], form);
	const [isFormValid, setIsFormValid] = useState(false);

	const handleFormValuesChange = useCallback(async () => {
		try {
			const fieldValues = await form.validateFields({ validateOnly: true });
			setIsFormValid(true);
			setNextButtonDisabled(false);
			setAdditionalApplicantDetails(fieldValues);
		} catch (e) {
			const fields = e as { values: ApplicantAdditionalDetailsType };
			setIsFormValid(false);
			setNextButtonDisabled(true);
			setAdditionalApplicantDetails(fields.values);
		}
		setCancelButtonDisabled(false);
	}, [
		form,
		setAdditionalApplicantDetails,
		setCancelButtonDisabled,
		setNextButtonDisabled,
	]);

	useEffect(() => {
		void handleFormValuesChange();
	}, [handleFormValuesChange, values]);

	useEffect(() => {
		form.setFieldsValue(additionalApplicantDetails);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [form]);

	useEffect(() => {
		const someOfMandatoryFieldsAreNotProvided = [
			mySrcmProfile?.srcm_group,
			mySrcmProfile?.date_of_joining,
			mySrcmProfile?.date_of_birth,
			mySrcmProfile?.street,
			mySrcmProfile?.street2,
			mySrcmProfile?.street3,
			mySrcmProfile?.city,
			mySrcmProfile?.postal_code,
		].some((value) => value === null || value === undefined || !value);
		if (!isFormValid) {
			setNextButtonDisabled(someOfMandatoryFieldsAreNotProvided);
		}
		const mandatoryAdditionalDetails: Partial<ApplicantAdditionalDetailsType> =
			{
				center: mySrcmProfile?.srcm_group?.name,
				doj: mySrcmProfile?.date_of_joining
					? moment(mySrcmProfile.date_of_joining, 'YYYY-MM-DD')
					: undefined,
				dateOfBirth: mySrcmProfile?.date_of_birth
					? moment(mySrcmProfile.date_of_birth, 'YYYY-MM-DD')
					: undefined,
				addressLine1: mySrcmProfile?.street,
				addressLine2: mySrcmProfile?.street2,
				addressLine3: mySrcmProfile?.street3,
				city: mySrcmProfile?.city,
				pincode: mySrcmProfile?.postal_code,
			};
		setAdditionalApplicantDetails({
			...additionalApplicantDetails,
			...mandatoryAdditionalDetails,
		} as ApplicantAdditionalDetailsType);
		// eslint-disable-next-line
	}, [
		mySrcmProfile,
		setAdditionalApplicantDetails,
		isFormValid,
		setNextButtonDisabled,
	]);

	return (
		<ApplicantAdditionalDetailsForm form={form} mySrcmProfile={mySrcmProfile} />
	);
};
