import { useCallback } from 'react';
import { Button, Avatar } from 'antd';
import LanguageDropdown from './LanguageDropdown';
import LogoutImage from '../assets/images/logout-red-icon.svg';
import { useTranslation } from 'react-i18next';

interface WebAppHeaderProps {
	logout?: () => void | Promise<void>;
	//mySrcmProfile?: MeProfile | null;
	username?: string;
	profileImage?: string;
}

export const ApplicantWebHeader = ({
	logout,
	username,
	profileImage,
}: WebAppHeaderProps) => {
	const { t } = useTranslation();
	const handleLogoutClick = useCallback(() => {
		if (logout) {
			void logout();
		}
	}, [logout]);
	const logoutText = t('logout');
	const LogoutIcon = () => (
		<>
			<img src={LogoutImage} alt="Logout Icon" />
		</>
	);

	return (
		<div className="hidden md:flex items-center justify-between px-4 space-x-6">
			{/* Profile Section */}
			<div className="flex items-center gap-x-3">
				<Avatar
					src={profileImage}
					size={36}
					className="border border-gray-300"
				/>
				<span className="font-sans font-medium text-gray-700 text-sm">
					{username}
				</span>
			</div>

			{/* Language Dropdown */}
			<div>
				<LanguageDropdown className="w-24" />
			</div>

			{/* Logout Section */}
			<div className="flex items-center w-24">
				<LogoutIcon />
				<Button
					type="link"
					shape="round"
					onClick={handleLogoutClick}
					size="small"
					className="text-red  hover:!text-red font-sans font-medium"
					style={{
						justifyContent: 'flex-start',
						padding: '0.24rem',
					}}
				>
					{logoutText}
				</Button>
			</div>
		</div>
	);
};
