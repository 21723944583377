import { CategoryStep } from './CategoryStep';
import { useNewApplication } from './useNewApplication';
import useCategoryFormHandling from './useCategoryFormHandling';
import { PracticeUIConfig } from '../../../shared/CategoryStepUIConfig';

const UiConfig = PracticeUIConfig;

export const PracticeStep = () => {
	const { practiceCategoryAnswers, setPracticeCategoryAnswers } =
		useNewApplication();

	const { form, updateFieldValue } = useCategoryFormHandling(
		practiceCategoryAnswers,
		setPracticeCategoryAnswers
	);

	return (
		<CategoryStep
			uiConfig={UiConfig}
			form={form}
			updateFieldValue={updateFieldValue}
		/>
	);
};
