import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from 'antd';
import Icon from '../components/ui-components/Icon';
import ConfirmTick from '../assets/images/confirm-tick.svg';

export const useConfirmPopup = () => {
	const { t } = useTranslation();

	const ConfirmContent = useCallback(
		(content: string) => {
			return (
				<div className="flex flex-col font-sans font-normal text-sm">
					<div className="flex flex-col items-center">
						<div className="w-24 h-20 my-4">
							<Icon src={ConfirmTick} className="w-full h-full" />
						</div>
						<div className="text-center my-2">{t(content)}</div>
					</div>
				</div>
			);
		},
		[t]
	);

	const ConfimFooter = useCallback(
		<T,>(action: () => Promise<T> | void) => {
			return (
				<div className="flex w-full gap-4 mt-4">
					<Button
						type="default"
						className="flex-1 h-9 px-4 py-2 font-sans font-bold text-sm border border-primary-blue rounded-lg text-primary-blue"
						onClick={() => Modal.destroyAll()}
					>
						{t('cancel')}
					</Button>
					<Button
						type="default"
						className="flex-1 h-9 px-4 py-2 font-sans font-bold text-sm bg-primary-blue text-white rounded-lg"
						onClick={() => {
							Modal.destroyAll();
							void action();
						}}
					>
						{t('submit')}
					</Button>
				</div>
			);
		},
		[t]
	);

	const confirmPopUpAction = useCallback(
		<T extends void>(
			title: string,
			content: string,
			action: () => Promise<T> | void
		) => {
			return Modal.confirm({
				className: 'max-w-md',
				centered: true,
				okText: t('ok'),
				cancelText: t('cancel'),
				icon: null,
				title: (
					<div className="text-center font-sans font-bold text-lg">
						{t(title)}
					</div>
				),
				content: ConfirmContent(content),
				footer: ConfimFooter(action),
			});
		},
		[ConfirmContent, ConfimFooter, t]
	);

	return { confirmPopUpAction };
};
